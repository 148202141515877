import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import queryString from 'query-string';
import PropTypes from 'prop-types';



function SiteAdminManagementTest() {

    
    const [data, setData] = useState([]);




    const getData = async () => {
        
        const options = {
            uri:'https://dmctrips.net/login/sbp_confirm.php', 
            method: 'POST',
            data: '{"token":"7262a6ca1769dac5e18a89f545571dc1"}',
            json:true,
            headers: { 
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'User-Agent': 'axios/0.21.4',
                'Content-Lenth': 44
            },
        }
        //console.log(options);


        /*
        axios.post(options).then(function(res){            
            console.log(res);
        });
        */

        var config = {
            headers: {'Access-Control-Allow-Origin': '*','Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'User-Agent': 'axios/0.21.4',}
        };

        /*
        axios.post('https://renthow.net/dmc.php', {
            params: '{"token":"7262a6ca1769dac5e18a89f545571dc1"}',
            headers: { 
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'User-Agent': 'axios/0.21.4',
                'Content-Lenth': 44
            },
          }).then(function(res){            
            console.log(res);
        });
        */

    
        axios.post('https://renthow.net/dmc.php', config)
            .then(function (response) {
                console.log(response.data);
        });


        
    };


    useEffect(() => {
        getData();
    }, []);




}
export default SiteAdminManagementTest