import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';

import Header from "../../../include/StoreHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';



function StoreManagementGoodsList() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    


    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('goods_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });

    const [corp_class, set_corp_class] = useState('');



    

    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;


    const Fn_Send_Hp =(idx, customer_hp)=>{

        let body = {
            receivers: customer_hp,
            message: '[유월페이] 상품결제 링크 : https://uwalpay.net/Store/_management/payFormStep1?idx=' + idx
        }

        axios.post('/api/sms/send', body)
        .then(function(res){
            
            console.log('response : ', res.data);
            alert("발송하였습니다.");      

        }).catch((err) => {
            //console.log('err : ', err);
        })

    }

    const Fn_Send_Email =(idx, customer_email)=>{

        let body = {
            receivers: customer_email,
            subject:'[유월페이] 상품결제 링크를 보내드립니다.',
            content:'[유월페이] 상품결제 링크 : <a href="https://uwalpay.net/Store/_management/payFormStep1?idx=' + idx + '" target="_blank">새창열기</a>'
        }
        
        axios.post('/api/email/sendMail', body)
        .then(function(res){
            alert("발송하였습니다.");
        }).catch((err) => {
            //console.log('err : ', err);
        })

    }




    const Board = ({
        idx,
        nums,
        regdate,
        reg_id,
        goods_name,
        customer_name,
        customer_hp,
        customer_email,
        price,
        memo,
        payEnd,
        paytype
    }) => {
        let contract_status_txt = '';
        let login_able_txt = '';
        let corp_class_txt = '';
        let payEnd_txt = '';


        // 사업자 구분
        if(corp_class === "corp"){
            corp_class_txt = '법인사업자';

        }else if(corp_class === "solo"){
            corp_class_txt = '개인사업자';

        }else if(corp_class === "non"){
            corp_class_txt = '비사업자';

        }else{
            corp_class_txt = '';
        }

        if(payEnd === "Y"){
            payEnd_txt = "결제완료";

        }else{
            payEnd_txt = "결제전";

        }
       

        regdate = regdate.substr(0,10);


        function show_button(type, idx, customer_hp, customer_email){

            let showBtns = null;

            if(type === "s"){
                showBtns = (
                    <>
                        <a href="#!" className="btn_st_02 blue_line" onClick={()=>{Fn_Send_Hp(idx, customer_hp)}}>SMS</a>
                    </>
                )
    
            }else if(type === "e"){
                showBtns = (
                    <>
                        <a href="#!" className="btn_st_02 blue_line" onClick={()=>{Fn_Send_Email(idx, customer_email)}}>EMAIL</a>
                    </>
                )
            }

            return <>{showBtns}</>;
        }

        


        return (
            <tr>
                <td>{nums}</td>
                <td>{goods_name}</td>
                <td>{corp_class_txt}</td>
                <td>{payEnd_txt}</td>
                <td>
                    <Link to={`/Store/_management/payFormStep1?idx=`+idx} target="_blank" >https://uwalpay.net/Store/_management/payFormStep1?idx={idx}</Link>
                </td>
                <td>
                    <div className="btn_wrap">
                        {show_button(paytype, idx, customer_hp, customer_email)}
                        
                        
                    </div>
                </td>
                <td>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td><strong>{customer_name}</strong> (<span>{customer_hp}</span>)</td>
                <td>{regdate}</td>
                <td>
                    <div className="btn_wrap">
                        <Link to={`/Store/_management/goodsMod?idx=`+idx} className="btn_st_02 blue_bg">수정</Link>
                    </div>
                </td>
            </tr>
        );
    };




    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates);
        navigate('/Store/_management/goodsList?searchWord='+searchWord+'&searchCates='+searchCates);
        
    }


    // 전체 수량 구하는 
    function getTotalCnt(){
        
        const loginId = sessionStorage.getItem("loginId");
        
        if(loginId === null){
            navigate('/Store/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }



        axios.post('/api/store/userInfo', bodyPage).then(function(response){

                        
            if(response.status === 401 || response.data.length === 0){
                alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
                navigate('/Store/');
                return;
            }
            

            set_corp_class(response.data[0].corp_class);

            let bodyQuery = {
                searchWord: searchWord,
                searchCates: searchCates,
                user_id: response.data[0].user_id
            }

            axios.post('/api/store/management/goods_total_list', bodyQuery).then(function(res){            
                setTotalCnt(res.data.tcnt);
                
                setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
            })


        });
        
        

    }


    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

        getTotalCnt();
        

        

    },[sWord, sCate, sPg, page]);




    useEffect(()=>{
     
        const loginId = sessionStorage.getItem("loginId");
        
 
        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            user_id: loginId
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/store/management/goods_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)
        }) 

        
    },[sWord, sCate, sPg]);



    return(
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="goods">
                <h3 className="page_ttl">상품관리</h3>


                <form onSubmit={onSubmitSearch} >
                <div className="top_filter">

                    <div className="sel_box">
                        <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                            <option value="goods_name">상품명</option>
                            <option value="price">금액</option>
                            <option value="customer_name">고객명</option>
                        </select>
                    </div>

                    <div className="in_box search_box">
                        <input type="text" name="searchWord" id="searchWord" value={searchWord} onChange={onSearchWord} placeholder="검색해주세요"/>
                        <button  type="submit" className="btn_st_02 blue search">검색</button>
                    </div>

                    <div className="btn_box">
                        <Link to="/Store/_management/goodsForm" className="btn_st_02 blue">상품 등록</Link>
                    </div>

                </div>
                </form>



                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>
                    </div>
                    <div className="table_box">

                        <table>

                            <thead>
                            
                                <tr>
                                    <th>No</th>
                                    <th>상품명</th>
                                    <th>구분</th>
                                    <th>상태</th>
                                    <th>URL</th>
                                    <th>-</th>
                                    <th>금액</th>
                                    <th>고객명</th>
                                    <th>등록일</th>
                                    <th>수정</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                regdate={v.regdate}
                                                reg_id={v.reg_id}
                                                goods_name={v.goods_name}
                                                customer_name={v.customer_name}
                                                customer_hp={v.customer_hp}
                                                customer_email={v.customer_email}
                                                price={v.price}
                                                memo={v.memo}
                                                payEnd={v.payEnd}
                                                paytype={v.paytype}
                                                
                                            />
                                        );
                                    })
                                }
                                {/*
                                <tr>
                                    <td>1</td>
                                    <td>test</td>
                                    <td>개인결제</td>  
                                    <td>-</td>
                                    <td>http://uwalpay.uwaldev.com/</td>
                                    <td>
                                        <div className="btn_wrap">
                                            <a href="#!" className="btn_st_02 green">주소복사</a>
                                        </div>
                                    </td>
                                    <td>200,124,012</td>
                                    <td><strong>임유월</strong> (<span>01012345678</span>)</td>
                                    <td> 2022-08-08 </td>
                                    <td>
                                        <div className="btn_wrap">
                                            <a href="./goods_edit.php" className="btn_st_02 red">수정</a>
                                        </div>
                                    </td>
                                </tr>
                            */}
                            </tbody>
                        </table>
                    </div>
                    {
                        viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>
            </div>
        </div>

        <Footer/>
        </>

    )
}
export default StoreManagementGoodsList