import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Editor from '../../../../quill/EditorComponent';
import queryString from 'query-string';
import ReactQuill from 'react-quill';

import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/AgencyHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";




function AgencyManageQnaView() {

    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search);  

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    const [myip, set_myip] = useState(""); 
    const [page_state, set_page_state] = useState(true);


    const [idx, set_idx] = useState(sIdx);
    const on_idx_handler = (event) => {
        set_idx(event.currentTarget.value)
    }
    
    
    //제목
    const [subject, set_subject] = useState('');
    const on_subject_handler = (event) => {
        set_subject(event.currentTarget.value)
    }

    //내용
    const [contents, set_contents] = useState('');
    function onEditorChange(value) {
        set_contents(value)
    }

    //내용
    const [reply, set_reply] = useState('');
    function onEditorChange2(value) {
        set_reply(value)
    }

    //작성자
    const [user_id, set_user_id] = useState('');
   

    //작성일
    const [regdate, set_regdate] = useState('');
   



    //등록
    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: idx,
            code: (query.code === undefined || query.code === '' ? 'faq':query.code),
            subject: subject,
            contents: contents,  
            ip_address: myip,
            user_level: '1',
            user_id: sessionStorage.getItem("loginId"),
        }

        axios.post('/api/manage/FaqFormAdd', body).then(function(response){
            
            if(response.data === "AddOk"){
                navigate('/Agency/_manage/FaqList');
            
            }else{
                alert("시스템 에러");   
            }
        },[])
    }


    //상세정보
    const Fn_View=(sIdx)=>{

        let bodyPage = {
            idx: sIdx
        }
        
        axios.post('/api/manage/NoticeInfo', bodyPage).then(function(res){                        
            res.data.map((v, index) => {
                set_subject(v.subject);
                set_contents(v.contents);
                set_reply(v.reply);
                set_user_id(v.user_id);
                set_regdate(v.regdate.substr(0,10));
                return ''
            }); 
            
            if(res.data.length === 0){                
                set_page_state(false)                
            }
        })         
    }

    const FnMyip =async()=>{
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();        
        set_myip(locationIp.IPv4)
    }
    
    React.useEffect(() => {
        FnMyip();
        if(sIdx !== ''){
            Fn_View(sIdx);
        }
    }, [sIdx])

    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="distributor_list">
            
                 
                    <input type="hidden" name="idx" id="idx" value={idx} onChange={on_idx_handler} />
                    
                    <h3 className="page_ttl">1대1 문의</h3>

                    <div className="board_view">
                        <div className="bd_top">
                            <h3 className="ttl">{subject}</h3>
                        </div>
                        <div className="bd_info">
                            <div className="fl_sb">
                                <div className="left">
                                    <p>작성일 : <span>{regdate}</span></p>
                                    <p>작성자 : <span>{user_id}</span></p>
                                </div>
                            </div>
                        </div>

                        <div className="bd_body">
                            <ReactQuill value={contents} readOnly={true} theme={"bubble"}/>
                        </div>

                        <div className="bd_answer">
                            <span>관리자 답변</span>
                            <div>
                                <ReactQuill value={reply} readOnly={true} theme={"bubble"}/>
                            </div>
                        </div>
                    </div>


                    <div className="top_table_btn fl_sb">
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>                       
                    </div>             

            </div> 
            
        </div>

        <Footer/>
        </>
    )
}

export default AgencyManageQnaView