import React  from 'react'
import Header from "../../../include/AgencyHeader";
import Footer from "../../../include/SiteAdminFooter";
import UserEditForm from "../../../../page/setting/UserEditForm";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';


function AgencyAdminSetting() {

    return (
        <>
        <div id="wrap">
            <Header/>                  
            <UserEditForm user_level={4} user_site={'Agency'} sIdx={sessionStorage.getItem('loginIdx')} />            
        </div>
        <Footer/>
        </>
    )
}

export default AgencyAdminSetting