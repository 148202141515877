import * as axios from 'axios';
import React, {useEffect, useState} from 'react'


function PgCardName( pgType, cardCode ) {

    let payjoaCardCode = Array();
    
    if(pgType === "payjoa"){

        // 페이조아 카드사 코드표
        payjoaCardCode['CCSU'] = "수협";
        payjoaCardCode['CCSS'] = "삼성카드";
        payjoaCardCode['CCNH'] = "NH카드";
        payjoaCardCode['CCLO'] = "롯데카드";
        payjoaCardCode['CCLG'] = "신한카드";
        payjoaCardCode['CCKM'] = "국민카드";
        payjoaCardCode['CCKJ'] = "광주은행";
        payjoaCardCode['CCKE'] = "외환은행";
        payjoaCardCode['CCJB'] = "전북은행";
        payjoaCardCode['CCHN'] = "하나SK카드";
        payjoaCardCode['CCDI'] = "현대카드";
        payjoaCardCode['CCCT'] = "씨티은행";
        payjoaCardCode['CCCJ'] = "제주은행";
        payjoaCardCode['CCBC'] = "비씨카드";
    }
    return (
        <>
            {payjoaCardCode[cardCode]}
        </>
    );
}





function PgName( pgType ) {

    let pgOut = "";
    
    if(pgType === "payjoa"){
        pgOut = "페이조아";   
    }
    return (
        <>
            {pgOut}
        </>
    );
}

function GetPayStatus(status){
    let payStatus = Array();
    
    if(status === undefined || status === ''){

        return (
            <>
                실패
            </>
        );

    }else{

        payStatus['B'] = "정산전";
        payStatus['BC'] = "정산전취소";
        payStatus['D'] = "정산보류";
        payStatus['E'] = "정산완료";
        payStatus['EC'] = "정산완료후취소";
            
        
        return (
            <>
                {payStatus[status]}
            </>
        );

    }

}

function GetPayGubun(status){
    let payStatus = Array();
    
    if(status === undefined || status === ''){

        return (
            <>
                실패
            </>
        );

    }else{

        payStatus['B'] = "승인";
        payStatus['BC'] = "취소";
        payStatus['D'] = "정산보류";
        payStatus['E'] = "승인";
        payStatus['EC'] = "취소";
            
        
        return (
            <>
                {payStatus[status]}
            </>
        );

    }

}

// 가맹점 정산
function Calculate_store(orderno, userid){

    
    const [commission, set_commission] = useState("");
    const [price, set_price] = useState("");

    let bodyPage = {
        loginId: userid
    }

    let out = "";

    axios.post('/api/store/storeInfo', bodyPage).then(function(response){
        
        //console.log(response.data.user_id)
        set_commission(response.data[0].commission);

        let bodyPage2 = {
            orderno: orderno
        }

        axios.post('/api/pay/payInfo', bodyPage2).then(function(res2){
            set_price(res2.data[0].amount);

        });
      

    });

    
    return (
        <>
            {commission} / {price}
        </>
    );
    

}




// 보류가 가능한지 확인하기 
/*
같은 승인번호이면서 결제 금액 x -1 값을 가지고 있으면 취소된 내역이 있기 때문에 보류가 안되어야 함.
*/
function ChkDelayAble(authno, amount){

    
    const [chkAble, set_chkAble] = useState("");

    let bodyPage = {
        authno: authno,
        amount: amount
    }

    let out = "";

    axios.post('/api/management/pay/delay_able', bodyPage).then(function(response){
        set_chkAble(response.data[0].cnts);
    });

    
    return chkAble;
}

// 보류설정
function ChgDelay(idx){

    let bodyPage = {
        idx: idx
    }


    axios.post('/api/management/pay/chg_delay', bodyPage).then(function(response){
        
    });
}



// 보류해제
function ChgDelayOff(idx){

    let bodyPage = {
        idx: idx
    }


    axios.post('/api/management/pay/chg_delay_off', bodyPage).then(function(response){
        
    });
}


// 보류설정 가맹점
function SettlementStChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementStChk', bodyPage).then(function(response){
    });
}


// 보류설정 지사
function SettlementBrChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementBrChk', bodyPage).then(function(response){
    });
}

// 보류설정 총판
function SettlementDiChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementDiChk', bodyPage).then(function(response){
    });
}

// 보류설정 대리점
function SettlementAgChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementAgChk', bodyPage).then(function(response){
    });
}


// 은행이름 가져오기
function GetBankName(code){

    let bodyPage = {
        code: code
    }

    const [bankName, set_bankName] = useState("");

    axios.post('/api/management/bankName', bodyPage).then(function(response){
        set_bankName(response.data[0].bank_name)
    });

    return bankName;
}


function Calculate_set(idx){

    let bodyPage = {
        idx: idx
    }

    axios.post('/api/management/pay/chg_cal', bodyPage).then(function(response){
        
    });
}

function TodayCnt(regdate, card_no, authno, order_no, reg_id, idx){
    /*
    regdate : 결제일자
    card_no : 카드번호
    authno : 결제 번호 (취소된 내역들은 중복으로 체크하지 않기 위해서 확인 용도)
    order_no : 같은 주문 번호의 합으로 최종적으로 결제 내역이 있는지 확인 용도
    reg_id : 가맹점 아이디 (가맹점이 다르면 중복이 아니기 때문)
    idx : 화면에서 보여주는 행이 중복으로 표기해줄지 여부를 판단하기 위한 키값
    */


    let bodyPage = {
        regdate: regdate,
        card_no: card_no,
        reg_id: reg_id
      };
    
    // axios.post 요청의 결과값을 처리하기 위해 Promise를 반환합니다.
    return axios.post('/api/management/pay/doublePriceChk', bodyPage)
    .then(function(response) {
        if (response.data.length > 0 && response.data[0].cnts > 1) {
            return "T";
        } else {
            return "F";
        }
    })
    .catch(function(error) {
        console.error(error);
    });

}


function DoublePayChk(regdate, cardno, regid){
    /*
    regdate : 결제일자
    card_no : 카드번호
    reg_id : 가맹점 아이디 (가맹점이 다르면 중복이 아니기 때문)
    */

    let body = {
        user_id: regid
    };

    let bodyPage = {
        regdate: regdate,
        card_no: cardno,
        user_id: regid
    };


    // 가맹점이 중복결제 막혀있는지 우선 확인
    return axios.post('/api/store/storeDoubleAble', body).then(function(res){
        
        console.log(res.data[0].double_able);

        if(res.data[0].double_able == "Y"){
            //return "F";
            return Promise.resolve("F");

        }else{
            return axios.post('/api/management/pay/doublePayChk', bodyPage)
            .then(function(response) {

                //alert( response.data[0].cnts );
                //console.log(bodyPage);

                if (response.data.length > 0 && response.data[0].cnts > 1) {
                    //return "T";
                    return Promise.resolve("T");
                } else {
                    //return "F";
                    return Promise.resolve("F");
                }
            })
            .catch(function(error) {
                console.error(error);
            });

        }

    });


}


export  {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay
    , ChgDelayOff, SettlementStChk, SettlementBrChk, SettlementDiChk, SettlementAgChk, GetBankName
    , Calculate_set, TodayCnt, DoublePayChk

}