import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';


import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";




function SiteAdminManageSetupForm() {

    const navigate = useNavigate();

    const [id_check, set_id_check] = useState("");
    const [user_id, set_user_id] = useState("");
    const [user_pw, set_user_pw] = useState("");
    const [user_name, set_user_name] = useState("");    
    const [user_phone, set_user_phone] = useState("");
    const [contract_status, set_contract_status] = useState("");

    const [G01, set_G01] = useState("");
    const [G02, set_G02] = useState("");
    const [G03, set_G03] = useState("");
    const [G04, set_G04] = useState("");
    const [G05, set_G05] = useState("");
    const [G06, set_G06] = useState("");
    const [G07, set_G07] = useState("");
    const [G08, set_G08] = useState("");
    const [G09, set_G09] = useState("");
    const [G10, set_G10] = useState("");
    const [G11, set_G11] = useState("");
    const [G12, set_G12] = useState("");
    const [G13, set_G13] = useState("");
    const [G14, set_G14] = useState("");
    const [G15, set_G15] = useState("");
    const [G16, set_G16] = useState("");
    const [G17, set_G17] = useState("");
    const [G18, set_G18] = useState("");
    const [G19, set_G19] = useState("");
    const [G20, set_G20] = useState("");
    const [G21, set_G21] = useState("");
    const [G22, set_G22] = useState("");
    const [G23, set_G23] = useState("");
    const [G24, set_G24] = useState("");
    const [G25, set_G25] = useState("");
    const [G26, set_G26] = useState("");
    const [G27, set_G27] = useState("");
    const [G28, set_G28] = useState("");
    const [G29, set_G29] = useState("");
    
    const [P01, set_P01] = useState("");
    const [P02, set_P02] = useState("");
    const [P03, set_P03] = useState("");
    const [P04, set_P04] = useState("");
    const [P05, set_P05] = useState("");
    const [P06, set_P06] = useState("");
    const [P07, set_P07] = useState("");
    const [P08, set_P08] = useState("");
    const [P09, set_P09] = useState("");

    useEffect(() => {
        // 계약상태 기본값 대입
        set_contract_status('Y');
        
        
    }, []);
  

    const on_user_id_handler = (event) => {
        set_user_id(event.currentTarget.value)
        set_id_check('');
    }

    const on_user_pw_handler = (event) => {
        set_user_pw(event.currentTarget.value)
    }

    const on_user_name_handler = (event) => {
        set_user_name(event.currentTarget.value)
    }

    const on_user_phone_handler = (event) => {
        set_user_phone(event.currentTarget.value)
    }

    const on_contract_status_handler = (event) => {
        set_contract_status(event.currentTarget.value)
    }


    const on_G01_handler = (event) => {	set_G01(event.currentTarget.value) }
    const on_G02_handler = (event) => {	set_G02(event.currentTarget.value) }
    const on_G03_handler = (event) => {	set_G03(event.currentTarget.value) }
    const on_G04_handler = (event) => {	set_G04(event.currentTarget.value) }
    const on_G05_handler = (event) => {	set_G05(event.currentTarget.value) }
    const on_G06_handler = (event) => {	set_G06(event.currentTarget.value) }
    const on_G07_handler = (event) => {	set_G07(event.currentTarget.value) }
    const on_G08_handler = (event) => {	set_G08(event.currentTarget.value) }
    const on_G09_handler = (event) => {	set_G09(event.currentTarget.value) }
    const on_G10_handler = (event) => {	set_G10(event.currentTarget.value) }
    const on_G11_handler = (event) => {	set_G11(event.currentTarget.value) }
    const on_G12_handler = (event) => {	set_G12(event.currentTarget.value) }
    const on_G13_handler = (event) => {	set_G13(event.currentTarget.value) }
    const on_G14_handler = (event) => {	set_G14(event.currentTarget.value) }
    const on_G15_handler = (event) => {	set_G15(event.currentTarget.value) }
    const on_G16_handler = (event) => {	set_G16(event.currentTarget.value) }
    const on_G17_handler = (event) => {	set_G17(event.currentTarget.value) }
    const on_G18_handler = (event) => {	set_G18(event.currentTarget.value) }
    const on_G19_handler = (event) => {	set_G19(event.currentTarget.value) }
    const on_G20_handler = (event) => {	set_G20(event.currentTarget.value) }
    const on_G21_handler = (event) => {	set_G21(event.currentTarget.value) }
    const on_G22_handler = (event) => {	set_G22(event.currentTarget.value) }
    const on_G23_handler = (event) => {	set_G23(event.currentTarget.value) }
    const on_G24_handler = (event) => {	set_G24(event.currentTarget.value) }
    const on_G25_handler = (event) => {	set_G25(event.currentTarget.value) }
    const on_G26_handler = (event) => {	set_G26(event.currentTarget.value) }
    const on_G27_handler = (event) => {	set_G27(event.currentTarget.value) }
    const on_G28_handler = (event) => {	set_G28(event.currentTarget.value) }
    const on_G29_handler = (event) => {	set_G29(event.currentTarget.value) }

    const on_P01_handler = (event) => {	set_P01((P01 === "Y" ? "":"Y")) }
    const on_P02_handler = (event) => {	set_P02((P02 === "Y" ? "":"Y")) }
    const on_P03_handler = (event) => {	set_P03((P03 === "Y" ? "":"Y")) }
    const on_P04_handler = (event) => {	set_P04((P04 === "Y" ? "":"Y")) }
    const on_P05_handler = (event) => {	set_P05((P05 === "Y" ? "":"Y")) }
    const on_P06_handler = (event) => {	set_P06((P06 === "Y" ? "":"Y")) }
    const on_P07_handler = (event) => {	set_P07((P07 === "Y" ? "":"Y")) }
    const on_P08_handler = (event) => {	set_P08((P08 === "Y" ? "":"Y")) }
    const on_P09_handler = (event) => {	set_P09((P09 === "Y" ? "":"Y")) }

    const on_id_check_handler = (event) => { set_id_check(event.currentTarget.value)}





    const on_id_check = (event) => {
        if(user_id === "")
        {
            alert("아이디를 입력해주세요.");
            return false;
        }
        let body = {
            user_id: user_id,
        }

        axios.post('/api/user/login_chk', body)
        .then(function(response){
            if(response.data === "NO"){
                
                alert("이미 존재하는 아이디입니다.");
                set_id_check('');
                return false;

            }else{
                alert("사용 가능한 아이디입니다.");
                set_id_check('Y');
                return false;
                
            }
            
        },[])
        //set_id_check('Y');

    }
    const onSubmitHandler = (event) => {
        event.preventDefault();

        if( id_check === "" ){
            alert("아이디 중복검사를 해주세요.");
            return false;
        }
        

        let body = {
            user_id: user_id,
            user_pw: user_pw,
            user_name: user_name,
            user_phone: user_phone,
            contract_status: contract_status,
            G01: G01,
            G02: G02,
            G03: G03,
            G04: G04,
            G05: G05,
            G06: G06,
            G07: G07,
            G08: G08,
            G09: G09,
            G10: G10,
            G11: G11,
            G12: G12,
            G13: G13,
            G14: G14,
            G15: G15,
            G16: G16,
            G17: G17,
            G18: G18,
            G19: G19,
            G20: G20,
            G21: G21,
            G22: G22,
            G23: G23,
            G24: G24,
            G25: G25,
            G26: G26,
            G27: G27,
            G28: G28,
            G29: G29,
            
            P01: P01,
            P02: P02,
            P03: P03,
            P04: P04,
            P05: P05,
            P06: P06,
            P07: P07,
            P08: P08,
            P09: P09
            
        }

        axios.post('/api/manage/SetupAdminAdd', body)
        .then(function(response){
            

            
            if(response.data === "AddOk"){    
                navigate('/SiteAdmin/_manage/SetupAdminList');
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


    }


    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                    <input type="hidden" name="id_check" id="id_check" value={id_check} onChange={on_id_check_handler} />

                    <h3 className="page_ttl">운영자 계정추가</h3>

                    <div className="form_item">
                        <h3 className="label">* 기본정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>로그인ID <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box" style={{width:'400px'}}>
                                                    <input type="text" name="user_id" id="user_id" value={user_id} onChange={on_user_id_handler} required />
                                                </div>
                                                <button className="gray_btn" onClick={on_id_check} >중복검사</button>
                                            </div>
                                        </td>
                                        <th>비밀번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="password" name="user_pw" id="user_pw" value={user_pw} onChange={on_user_pw_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이름 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="user_name" id="user_name" value={user_name} onChange={on_user_name_handler} required />
                                            </div>
                                        </td>

                                        <th>휴대폰</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="user_phone" id="user_phone" value={user_phone} onChange={on_user_phone_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>현황  <span className="red">＊</span></th>
                                        <td colSpan='3'>
                                            <div className="sel_box">
                                                <select name="contract_status" id="contract_status" required onChange={on_contract_status_handler}>
                                                    <option value="">선택</option>
                                                    <option value="Y">이용</option>
                                                    <option value="N">정지</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">* 권한</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>거래조회</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="check_box">
                                                    <input type="checkbox" name="G01" id="G01" value="Y" onChange={on_G01_handler} />
                                                    <label htmlFor="G01"><span></span>거래내역</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G02" id="G02" value="Y" onChange={on_G02_handler} />
                                                    <label htmlFor="G02"><span></span>거래거절(실패)내역</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G03" id="G03" value="Y" onChange={on_G03_handler} />
                                                    <label htmlFor="G03"><span></span>차감내역</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G04" id="G04" value="Y" onChange={on_G04_handler} />
                                                    <label htmlFor="G04"><span></span>정산보류내역</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G05" id="G05" value="Y" onChange={on_G05_handler} />
                                                    <label htmlFor="G05"><span></span>매출집계</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>정산관리</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="check_box">
                                                    <input type="checkbox" name="P01" id="P01" value="Y" onChange={on_P01_handler} />
                                                    <label htmlFor="P01"><span></span>가맹점 정산</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="P02" id="P02" value="Y" onChange={on_P02_handler} />
                                                    <label htmlFor="P02"><span></span>가맹점 정산 결과</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="P03" id="P03" value="Y" onChange={on_P03_handler} />
                                                    <label htmlFor="P03"><span></span>지사 정산</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="P04" id="P04" value="Y" onChange={on_P04_handler} />
                                                    <label htmlFor="P04"><span></span>지사 정산 결과</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="P05" id="P05" value="Y" onChange={on_P05_handler} />
                                                    <label htmlFor="P05"><span></span>총판 정산</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="P06" id="P06" value="Y" onChange={on_P06_handler} />
                                                    <label htmlFor="P06"><span></span>총판 정산 결과</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="P07" id="P07" value="Y" onChange={on_P07_handler} />
                                                    <label htmlFor="P07"><span></span>대리점 정산</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="P08" id="P08" value="Y" onChange={on_P08_handler} />
                                                    <label htmlFor="P08"><span></span>대리점 정산 결과</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="P09" id="P09" value="Y" onChange={on_P09_handler} />
                                                    <label htmlFor="P09"><span></span>매출데이터업로드</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>업체관리</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="check_box">
                                                    <input type="checkbox" name="G11" id="G11" value="Y" onChange={on_G11_handler} />
                                                    <label htmlFor="G11"><span></span>지사관리</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G12" id="G12" value="Y" onChange={on_G12_handler} />
                                                    <label htmlFor="G12"><span></span>총판관리</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G13" id="G13" value="Y" onChange={on_G13_handler} />
                                                    <label htmlFor="G13"><span></span>대리점관리</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G14" id="G14" value="Y" onChange={on_G14_handler} />
                                                    <label htmlFor="G14"><span></span>가맹점관리</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>터미널관리</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="check_box">
                                                    <input type="checkbox" name="G15" id="G15" value="Y" onChange={on_G15_handler} />
                                                    <label htmlFor="G15"><span></span>터미널관리</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G16" id="G16" value="Y" onChange={on_G16_handler} />
                                                    <label htmlFor="G16"><span></span>수기결제관리</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>실적조회</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="check_box">
                                                    <input type="checkbox" name="G17" id="G17" value="Y" onChange={on_G17_handler} />
                                                    <label htmlFor="G17"><span></span>매출계산서(사업자)</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G18" id="G18" value="Y" onChange={on_G18_handler} />
                                                    <label htmlFor="G18"><span></span>매출계산서(비사업자)</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G19" id="G19" value="Y" onChange={on_G19_handler} />
                                                    <label htmlFor="G19"><span></span>에이전트계산서</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G20" id="G20" value="Y" onChange={on_G20_handler} />
                                                    <label htmlFor="G20"><span></span>세금계산서마감</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>운영관리</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="check_box">
                                                    <input type="checkbox" name="G21" id="G21" value="Y" onChange={on_G21_handler} />
                                                    <label htmlFor="G21"><span></span>접속로그관리</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G22" id="G22" value="Y" onChange={on_G22_handler} />
                                                    <label htmlFor="G22"><span></span>운영로그관리</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G23" id="G23" value="Y" onChange={on_G23_handler} />
                                                    <label htmlFor="G23"><span></span>공지사항</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G24" id="G24" value="Y" onChange={on_G24_handler} />
                                                    <label htmlFor="G24"><span></span>로그인 관리</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G25" id="G25" value="Y" onChange={on_G25_handler} />
                                                    <label htmlFor="G25"><span></span>접속 IP</label>
                                                </div>
                                                <div className="check_box">
                                                    <input type="checkbox" name="G26" id="G26" value="Y" onChange={on_G26_handler} />
                                                    <label htmlFor="G26"><span></span>은행관리</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G28" id="G28" value="Y" onChange={on_G28_handler} />
                                                    <label htmlFor="G28"><span></span>PG관리</label>
                                                </div>

                                                <div className="check_box">
                                                    <input type="checkbox" name="G29" id="G29" value="Y" onChange={on_G29_handler} />
                                                    <label htmlFor="G29"><span></span>PG관리(터미널)</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>정보변경</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="check_box">
                                                    <input type="checkbox" name="G27" id="G27" value="Y" onChange={on_G27_handler} />
                                                    <label htmlFor="G27"><span></span>정보변경</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="top_table_btn">
                        <button className=" black_btn btn_st_01">등록하기</button>
                        <button type="button" className="line_btn btn_st_01" onClick={  () => navigate(-1) }>목록으로</button>
                    </div>

                </form>

            </div>
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManageSetupForm