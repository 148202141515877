import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/MbranchHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff} from "../../../include/ConfigSet_Terminal";
import jquery from 'jquery';
import $ from 'jquery';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import re_closebtn_w from '../../../../../assest/img/common/re_closebtn_w.png';

function pop_close (){
    $('.pop_common').removeClass('show');
}



function income_submit(){
    let cancel_group = $("#cancel_group").val();
    let in_date = $("#in_date").val();
    let in_price = $("#in_price").val();
    let orderno = $("#orderno").val();
    let total_amount = $("#total_amount").val();

    if(in_date === ""){
        alert("상환일을 입력해주세요.");
        return false;
    }

    if(in_price === ""){
        alert("금액을 입력해주세요.");
        return false;
    }

    if(orderno === ""){
        alert("주문번호 누락");
        return false;
    }


    let bodyQuery = {
        orderno: orderno,
        cancel_group: cancel_group,
        in_date: in_date,
        in_price: in_price,
        total_amount: total_amount
    }

    axios.post('/api/terminal/pay/sugi_income', bodyQuery).then(function(response){    
        
        if(response.data === "AddOk"){    
            alert("등록되었습니다.");
            window.location.reload();
        
        }else{
            alert("시스템 에러");
            
        }
        
    })

}

function MbranchPayPayDeductionListTerminal() {

    function pop_btn(e, cancel_group, orderno, total_amount){
        
        setViewSugiList([]);

        let bodyPage = {
            cancel_group: cancel_group
        }
    
        axios.post('/api/terminal/pay/sugi_income_list', bodyPage).then(function(res){
            setViewSugiList(res.data);
            
        }) 

        setTimeout(() => {
            $("#cancel_group").val(cancel_group);
            $("#orderno").val(orderno);
            $("#total_amount").val(total_amount);
        }, 1000) 
        
        $('.pay_de').addClass('show');
        
        
    
    }




    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    

    // 수기입금 내역
    const [viewSugiList , setViewSugiList] = useState([]);
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(15);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    
    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('branch_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });



    
    

    // PG 리스트
    const [sugiList, setSugiList] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
    }

    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value);
    }
    
    
    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'authno';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';
    
    const status = qs.status ? qs.status : '';
    $("#status").val(status);
    
    
    
    const page = qs.pg? qs.pg : 1;

    let corp_class_txt = '';
    let contract_status_txt = '';
    let login_able_txt = '';
    let calculate_txt = '';
    

   


    /**
     * 
     * @param {{nums:number,regdate:string}} nums 게시판 키값
     * @param {*} regdate 등록일자
     * @param {*} branch_name 지사명
     * @param {string} representative 대표자명
     * @param {string} user_id ID
     * @param {string} corp_class 구분
     * @param {string} calculate 정산Type
     * @param {string} commission 제공수수료
     * @param {string} contract_status 상태
     * @param {string} login_able 로그인 허용여부
     * @returns 
     */

    // 거래금액 합산
    let total_amount_h =  0;

    // 가맹점 합산
    let ga_amount_h = 0;

    // 차감금액 합산
    let cha_total_amount_h = 0;

    // 완료금액
    let return_sum_h = 0;

    // 정산차감
    let cha_total_h = 0;

    const Sugi = ({
        return_price,
        regdate
    }) => {
        return(
            <>
                <tr>
                    <td>{regdate}</td>
                    <td>{return_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>수기입금</td>
                </tr>
            </>
        )
    };



    const Board = ({
        idx,
        nums,
        created_datetime,
        resultcode,
        quota,
        cardno,
        cpid,
        authno,
        
        productname,
        username,
        userphone,
        status,
        status_pay,
        pg_group,
        cardcode,
        orderno,
        regid,
        calculate_date_re,
        feeStore,
        St_fee,
        Br_branch_name,
        Di_branch_name,
        Ag_branch_name,
        St_branch_name,
        income,
        pg_fee,
        fee,
        feeAdmin,
        feeMbramch,
        feeDistributor,
        feeAgency,
        cancel_date,
        st_type,
        amount,
        cancel_regdate,
        total_amount,
        return_sum,
        r_idx,
        cancel_group
    }) => {

        
        
        let created_datetime1 = created_datetime.substr(0,10);
        let created_datetime2 = created_datetime.substr(11,8);

        let cancel_date1 = cancel_date.substr(0,10);
        let cancel_date2 = cancel_date.substr(11,8);

        let cancel_regdate1 = cancel_regdate.substr(0,10);
        let cancel_regdate2 = cancel_regdate.substr(11,8);

        if(cancel_date !== ""){
            if(cancel_date1 === "0000-00-00"){
                cancel_date1 = "";
            }
        }




        
        let result_color = "";

        if(status_pay === "Y"){
            
            result_color = "blue";

        }else if(status_pay === "N"){
            
            result_color = "red";
        }


        let cancelStatus = 1;
        let cancelColor = "";
        // 취소 상태값
        if(status === "C" || status === "BC" || status === "EC"){
            
            cancelColor = "red";
        }else{
            cancelColor = "";
        }

        let quota_txt = "";

        if(quota === "00"){
            quota_txt = "일시불";
        }else{
            quota_txt = quota;
        }


     

        // PG 입금
        let pgImcome = parseInt(amount) - parseInt(pg_fee);
        

        // 정산금액 (결제금액 - PG - 가맹점)
        let cal_price = parseInt(amount) - parseInt(pg_fee) - parseInt(St_fee);

        // 관리자 정산금액 = 결제금액 / 100 * (지사 - 본사 수수료)
        let calculateAdmin = parseInt(amount) / 100 * (feeMbramch - feeAdmin);
        
        // 지사 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateMbramch = parseInt(amount) / 100 * (feeDistributor - feeMbramch);

        // 총판 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateDistributor = parseInt(amount) / 100 * (feeAgency - feeDistributor);

        // 대리점 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateAgency = parseInt(amount) / 100 * (feeStore - feeAgency);

        // 보류 가능한지 여부
        let delay_chk = ChkDelayAble(authno, amount * -1);

        let card_out_no = cardno.substr(0, cardno.length-7) + "****" + cardno.substr(cardno.length-3, 3);

        

        return (
        <>
            <tr>
                <td>{nums}</td>
                <td>{cancel_regdate1} <br />{cancel_regdate2}</td>
                <td>{created_datetime1}</td>
                <td>{Di_branch_name}</td>
                <td>{St_branch_name}</td>
                <td>Key-in</td>
                <td>취소차감전</td>
                <td>{PgCardName(pg_group, cardcode)}</td>
                <td>{(quota==="00"?'일시불':quota)}</td>
                <td>{card_out_no}</td>
                <td>{authno}</td>
                <td>{calculate_date_re}</td>
                <td>{calculate_date_re}</td>
                <td>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{St_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({feeStore})</td>
                <td>{total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{return_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{return_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{(total_amount-return_sum).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>
                    <button type="button" className='btn_st_02 green pop_btn' onClick={ (e)=>{pop_btn(e, cancel_group, orderno, total_amount) } } >차감내역</button>
                </td>
                <td>{PgName(pg_group)}</td>
                <td>{Br_branch_name}</td>
                <td>{Di_branch_name}</td>
            </tr>
        </>
        );
    };


    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates);
        navigate('/SiteAdmin/_management/list?searchWord='+searchWord+'&searchCates='+searchCates);
        
    }

    // 전체 수량 구하는 
    function getTotalCnt(){

        const loginId = sessionStorage.getItem("loginId");
        
        if(loginId === null){
            navigate('/Store/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }

      
        
        let bodyQuery = {
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: parent_idx2,
            status: status
        }

        axios.post('/api/terminal/pay/deduction_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })


    }

    
    

    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
   

        getTotalCnt();
        
        if(sdate !== ""){
            setStartDate( new Date(sdate) );
        }

        if(edate !== ""){
            setEndDate( new Date(edate) );
        }

        if(q_parent_idx1 !== ""){
            set_parent_idx1(q_parent_idx1);
        }
        /*
        if(q_parent_idx2 !== ""){
            set_parent_idx2(q_parent_idx2)
        }
        */
        

    },[sWord, sCate]);
    /*
    pg, sWord
    */

  
    useEffect(()=>{

        const loginId = sessionStorage.getItem("loginId");
        
        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: parent_idx2,
            status: status

        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/pay/deduction_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)
        }) 

          

        
    },[sWord, sCate, page]);
    // nums
    
    
    // 수기상점코드 리스트 가져오기
    function getSugiList(){
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/terminal/terminal/sugi_sel_list',bodyPage).then(function(res){            
            setSugiList(res.data);
            
        })

    }
   
    function rendSugiList(){
        return (
                sugiList.map((v, index) => {
                    return (
                        <option value={v.pg} key={v.idx} >{v.sname}</option>
                    )
                })
            

        )
    }


    function rendManagerList(){
        return (
            <select name="parent_idx1" id="parent_idx1" onChange={on_set_parent_idx1_handler} value={parent_idx1}  >
                <option value=''>=지사=</option>
            {
                managerList1.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    function rendManagerList2(){
        return (
            <select name="parent_idx2" id="parent_idx2" onChange={on_set_parent_idx2_handler} value={parent_idx2} >
                <option value=''>=총판=</option>
            {
                managerList2.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    
    useEffect(() => {
        getManagerList1()
        getSugiList()
    },[])

    // 총판 내역 가져오기
    useEffect(() => {
        getManagerList2(q_parent_idx1);

        
    },[managerList1])
  


    function getManagerList1(){
        
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list',bodyPage).then(function(res){            
            setManagerList1(res.data);
            
        })
    }

    function getManagerList2(temp_idx){

        
        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list',bodyPage).then(function(res){            
            setManagerList2(res.data);

            
            
        })
        if(q_parent_idx2 !== ""){
            set_parent_idx2(q_parent_idx2)
        }
    }



    const fn_submit = (e) => {
        $("#pg").val("1");
        //e.preventDefault();
    }


    return (
        <>
        <div id="wrap">
            <Header/>

            
            <div id="container" data-menu-page="pay_deduction">
                <h3 className="page_ttl">치감내역</h3>

                <div className="top_filter">

                    <div className="sel_box">
                        <select name="" id="" >
                            <option value="" >취소일</option>
                        </select>
                    </div>


                    <div className="date_box datepick_box">
                        <div className="datepick">
                            <input type="text" name="fr_date" id="fr_date" onFocus="this.blur()" />
                        </div>
                        <span>-</span>
                        <div className="datepick">
                            <input type="text" name="to_date" id="to_date" onFocus="this.blur()" />
                        </div>
                    </div>

                    <div className="sel_box">
                        <select name="" id="" required>
                            <option value="" disabled selected>=총판선택=</option>
                            <option value="">페이</option>
                        </select>
                    </div>

                    
                    <div className="sel_box">
                        <select name="" id="" >
                            <option value=""  >=가맹점=</option>
                        </select>
                    </div>

                    <div className="sel_box">
                        <select name="" id="" required>
                            <option value="" disabled selected>=에이전트=</option>
                            <option value="">유월페이먼트지사</option>
                        </select>
                    </div>


                    <div className="sel_box">
                        <select name="" id="">
                            <option value="">*가맹점명</option>
                            <option value="">*주문자명</option>
                            <option value="">*주문자휴대폰</option>
                            <option value="">*총판명</option>
                            <option value="">*에이전트명</option>
                            <option value="">승인번호</option>
                            <option value="">카드번호(뒤 4자리)</option>
                            <option value="">결제금액</option>
                            <option value="">주문번호</option>
                            <option value="">거래번호</option>
                            <option value="">상점코드</option>
                        </select>
                    </div>

                    <div className="in_box">
                        <input type="text" />
                    </div>
                    
                    <div className="btn_box">
                        <button className="btn_st_02 blue search">검색</button>
                    </div>
                    <div className="btn_box">
                        <button className="els_btn"><i></i>엑셀</button>
                    </div>



                </div>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                    </div>

                    <div className="table_box">
                        <table>
                            <thead>
                                <tr>
                                    <th>NO</th>
                                    <th>취소일자</th>
                                    <th>원거래</th>
                                    <th>총판</th>
                                    <th>가맹점</th>
                                    <th>결제방법</th>
                                    <th>정산상태</th>
                                    <th>매입사</th>
                                    <th>할부</th>
                                    <th>카드</th>
                                    <th>승인번호</th>
                                    <th>PG예정</th>
                                    <th>정산예정</th>
                                    <th>거래금액</th>
                                    <th>가맹점</th>
                                    <th>차감할금액</th>
                                    <th>완료금액</th>
                                    <th>수기입금</th>
                                    <th>정산차감</th>
                                    <th>수기처리</th>
                                    <th>PG</th>
                                    <th>지사</th>
                                    <th>총판</th>
                                </tr>
                            </thead>

                            <tbody>

                            {
                                viewContent.map((v, index) => {
                                    
                                    // 거래금액 합산
                                    total_amount_h = parseInt(total_amount_h) + parseInt(v.amount);

                                    // 가맹점 합산
                                    ga_amount_h = parseInt(ga_amount_h) + parseInt(v.St_fee);

                                    // 차감할금액 합산
                                    cha_total_amount_h = parseInt(cha_total_amount_h) + parseInt(v.total_amount);

                                    // 완료금액 / 수기입금 합산
                                    return_sum_h = parseInt(return_sum_h) + parseInt(v.return_sum);

                                    // 정산차감 합산
                                    cha_total_h = parseInt(cha_total_h) + parseInt(v.total_amount) - parseInt(v.return_sum);

                                    

                                    return (
                                        <Board
                                            key={v.idx}
                                            idx={v.idx}
                                            nums={nums-index}
                                            created_datetime={v.created_datetime}
                                            resultcode={v.resultcode}
                                            quota={v.quota}
                                            cardno={v.cardno}
                                            cpid={v.cpid}
                                            authno={v.authno}
                                            amount={v.amount}
                                            productname={v.productname}
                                            username={v.username}
                                            userphone={v.userphone}
                                            status={v.status}
                                            status_pay={v.status_pay}
                                            pg_group={v.pg_group}
                                            cardcode={v.cardcode}
                                            orderno={v.orderno}
                                            regid={v.regid}
                                            calculate_date_re={v.calculate_date_re}
                                            feeStore={v.feeStore}
                                            St_fee={v.St_fee}
                                            Br_branch_name={v.Br_branch_name}
                                            Di_branch_name={v.Di_branch_name}
                                            Ag_branch_name={v.Ag_branch_name}
                                            St_branch_name={v.St_branch_name}
                                            income={v.income}
                                            pg_fee={v.pg_fee}
                                            fee={v.fee}
                                            feeAdmin={v.feeAdmin}
                                            feeMbramch={v.feeMbramch}
                                            feeDistributor={v.feeDistributor}
                                            feeAgency={v.feeAgency}
                                            cancel_date={v.cancel_date}
                                            st_type={v.st_type}
                                            cancel_regdate={v.cancel_regdate}
                                            total_amount={v.total_amount}
                                            return_sum={v.return_sum}
                                            r_idx={v.r_idx}
                                            cancel_group={v.cancel_group}
                                            
                                        />
                                    );
                                })   
                            }
                                <tr className="total">
                                    <td colSpan="13">TOTAL</td>
                                    <td>{total_amount_h.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{ga_amount_h.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{cha_total_amount_h.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{return_sum_h.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{return_sum_h.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{cha_total_h.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td colSpan="4"></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>









                    {
                        viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }

                </div>


                <div className="pop_common pay_de">
                    <div className="pop_cont">
                        <div className="pop_top">
                            <h3 className="pop_ttl">취소차감 실행내역</h3>
                            <button type="button" onClick={pop_close} >
                                <img src={re_closebtn_w} alt="닫기버튼" />
                            </button>
                        </div>
                        <div className="pop_item">
                            <div className="pop_table">
                                <input type="hidden" id="cancel_group" value="" />
                                <input type="hidden" id="orderno" value="" />
                                <input type="hidden" id="total_amount" value="" />
                                <table>
                                    <thead>
                                        <tr>
                                            <th>상환일</th>
                                            <th>금액</th>
                                            <th>비고</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        viewSugiList.map((v, index) => {
                                            
                                            return (
                                                <Sugi
                                                    key={v.idx}
                                                    return_price={v.return_price}
                                                    regdate={v.regdate}
                                                />
                                            );
                                        })   
                                    }

                                        <tr>
                                            <td><input type="text" id="in_date" /></td>
                                            <td><input type="text" id="in_price" /></td>
                                            <td>
                                                
                                                <button type="button" className="btn_st_02 green pop_btn" onClick={income_submit}>등록</button>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>

                    </div>
                


                </div>





            </div>
        </div>

        <Footer/>
        </>
    )

    
}
export default MbranchPayPayDeductionListTerminal