import { useLocation, useNavigate } from "react-router-dom";
import React, {useEffect, useState} from 'react'


import '../../../assest/css/reset.css';
import '../../../assest/css/layout.css';
import logo_02 from '../../../assest/img/common/logo_02.png';
import jquery from 'jquery';
import $ from 'jquery';


function StoreHeaderM() {

    const [menu_on, set_menu_on] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const sampleLocation = useLocation();
    //console.log(sampleLocation);


    $('html').addClass('mo')


    
    return (
        <>
            <header id="header">
                <h1 class="logo"><img src={logo_02} alt="" /></h1>
            </header>
        </>
    )
}

export default StoreHeaderM
