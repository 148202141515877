import React from 'react'
import {
    Link, useLocation
  } from "react-router-dom";



/**
 * 
 * @param {number} curPage 현재 페이지 번호
 * @param {number} totalPage 전체 페이지 수
 * @param {number} pageBtnNum 보여줄 페이징 최대 수
 * @param {number} url 링크주소
 * @returns 
 */
function PagingNew( {curPage, totalPage, pageBtnNum, url} ) {
    //console.log(curPage+" , "+totalPage+" , "+pageBtnNum+" , "+url)

    // url 에서 page가 있다면 제거해야함
    let pairs = url.split("&");
    let re_url = '';
    for(let i = 0; i<pairs.length; i++) {
        // '이름=값' 패턴을 찾는다.

        let pos = pairs[i].indexOf('=');

        // 찾을 수 없다면 스킵한다.
        if(pos === -1) {
            continue;
        }

        // 이름을 추출한다.
        let name = pairs[i].substring(0,pos);

        if(name === "pg"){
            continue;
        }

        // 값을 추출한다.
        let value = pairs[i].substring(pos+1);

        // 값을 해석한다.
        value = decodeURIComponent(value);

        // 프로퍼티로 저장한다.
        if(re_url !== ""){
            re_url += "&";
        }
        re_url += name + "=" + value;
    }
    url = re_url;

       
    //console.log("[3]curPage : " + curPage);
    //console.log("[3]totalPage : " + totalPage);
    //console.log("[3]pageBtnNum : " + pageBtnNum);
    

    let prvOneStepUrl, firstUrl, nextOneStepUrl, endUrl;
    let startPage, endPage;
    let pageListObj = {};
    const pageListArr = [];


    // 이전페이지 아이콘 페이지 및 링크 주소 구하기
    if (curPage > 1) {
        firstUrl = `?${url}&pg=1`;
        prvOneStepUrl = `?${url}&pg=${curPage - 1}`;
    } else {
        firstUrl = `?${url}&pg=1`;
        prvOneStepUrl = firstUrl;
    }
    
    startPage = ((Math.floor((curPage - 1) / pageBtnNum)) * pageBtnNum) + 1;
    endPage = startPage + (pageBtnNum - 1);
    //console.log("startPage : " + startPage);
    //console.log("totalPage : " + totalPage);
    //console.log("endPage : " + endPage);

    if (endPage >= totalPage) {
        endPage = totalPage;
    }

    //console.log("re_endPage : " + endPage);


    if (totalPage == 0) {
        pageListObj = {
            id: curPage,
            page: curPage,
            url: `?${url}&pg=${curPage}`,
        }
        pageListArr.push(pageListObj);
    } else {
        for (let i = startPage; i <= endPage; i++) {
            pageListObj = {
                id: i,
                page: i,
                url: `?${url}&pg=${i}`,
            }
            pageListArr.push(pageListObj);
        }
    }

    // 다음페이지 아이콘 페이지 및 링크 주소 구하기
    //console.log("비교 : " + curPage + ' < ' +totalPage);

    if (curPage < totalPage) {
        nextOneStepUrl = `?${url}&pg=${ parseInt(curPage) + 1}`;
        endUrl = `?${url}&pg=${totalPage}`;
    } else {
        nextOneStepUrl = `?${url}&pg=${totalPage}`;
        endUrl = nextOneStepUrl;
    }
    

    return (
        <>
            <ul className="pager">
                <li className='prev_btn'>
                    <a href={prvOneStepUrl}>&#xE000;</a>
                </li>

                {pageListArr.map(obj => {
                    return (
                        <li key={obj.id} className={obj.page == curPage ? "on" : ""}>
                            <a href={`${obj.url}`}>{obj.page}</a>
                        </li>
                    )
                })}
                <li className='next_btn'>
                    <a href={nextOneStepUrl}>&#xE001;</a>
                </li>
            </ul>
        </>

    
    );
}

export default PagingNew