
import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';

import NoticeList from "../../../../page/notice/List";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";




const SiteAdminManageNoticeList =()=>{



    return(
        <>
        
        <div id="wrap">
            <Header/>            
                <NoticeList user_level={'1'} user_site={'SiteAdmin'} />
        </div>
        <Footer/>         
        </>
    )
}

export default SiteAdminManageNoticeList