import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import NoticeForm from "../../../../page/notice/Form";
import Header from "../../../include/MbranchHeader";
import Footer from "../../../include/SiteAdminFooter";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';


function MbranchManageNoticeForm() {


    return (
        <>
        <div id="wrap">
            <Header/>        
            
            <NoticeForm user_level={'2'} user_site={'Mbranch'} />
            
        </div>

        <Footer/>
        </>
    )
}

export default MbranchManageNoticeForm