import React from 'react'
import ManageLogList from "../../../../page/manageLog/List";
import Header from "../../../include/AgencyHeader";
import Footer from "../../../include/SiteAdminFooter";

function AgencyManageManageLog() {
    

    return(
    <>
        <div id="wrap">
            <Header/>
            
            <ManageLogList user_level={4} />

        </div>

        <Footer/>        
    </>
    )
}

export default AgencyManageManageLog