import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import * as XLSX from 'xlsx'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';

import Header from "../../../include/StoreHeader";

import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff, Set_User_HP_Cert, Set_User_Name_Cert} from "../../../include/ConfigSet_Terminal";
import jquery from 'jquery';
import $ from 'jquery';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";


function StorePayTransactionHistoryListTerminal() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);
    const [viewExcel , setViewExcel] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(15);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    
    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('branch_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });



    
    

    // PG 리스트
    const [sugiList, setSugiList] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
    }

    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value);
    }
    
    
    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'authno';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';
    
    const status = qs.status ? qs.status : '';
    $("#status").val(status);
    
    
    
    const page = qs.pg? qs.pg : 1;

    let corp_class_txt = '';
    let contract_status_txt = '';
    let login_able_txt = '';
    let calculate_txt = '';
    

   


    /**
     * 
     * @param {{nums:number,regdate:string}} nums 게시판 키값
     * @param {*} regdate 등록일자
     * @param {*} branch_name 지사명
     * @param {string} representative 대표자명
     * @param {string} user_id ID
     * @param {string} corp_class 구분
     * @param {string} calculate 정산Type
     * @param {string} commission 제공수수료
     * @param {string} contract_status 상태
     * @param {string} login_able 로그인 허용여부
     * @returns 
     */

    // 거래금액 합산
    let total_amount =  0;

    

    // PG금액 합산
    let total_pg_fee = 0;

    // PG 입금 합산 금액
    let total_pgImcome = 0;

    // 가맹점 합산 금액
    let total_St_fee = 0;

    // 정산금액 합산
    let total_cal_price = 0;

    // 본사금액 합산
    let total_calculateAdmin = 0;

    // 지사금액 합산
    let total_calculateMbramch = 0;

    // 총판금액 합산
    let total_calculateDistributor = 0;

    //대리점금액 합산
    let total_calculateAgency = 0;


    const junpyo = (daoutrx) => {
        window.open('https://agenttest.payjoa.co.kr/util/selectCmmnTradePrintCard.do?DAOUTRX='+daoutrx+'&STATUS=A&VAT=null', '_blank');
    };


    const Board = ({
        idx,
        nums,
        created_datetime,
        resultcode,
        quota,
        cardno,
        cpid,
        authno,
        
        productname,
        username,
        userphone,
        status,
        status_pay,
        pg_group,
        cardcode,
        orderno,
        regid,
        calculate_date_re,
        feeStore,
        St_fee,
        Br_branch_name,
        Di_branch_name,
        Ag_branch_name,
        St_branch_name,
        income,
        pg_fee,
        fee,
        feeAdmin,
        feeMbramch,
        feeDistributor,
        feeAgency,
        cancel_date,
        st_type,
        amount,
        paymenttype,
        doubleChk,
        daoutrx
    }) => {

        
        
        let created_datetime1 = created_datetime.substr(0,10);
        let created_datetime2 = created_datetime.substr(11,8);

        let cancel_date1 = cancel_date.substr(0,10);
        if(cancel_date !== ""){
            if(cancel_date1 === "0000-00-00"){
                cancel_date1 = "";
            }
        }




        
        let result_color = "";

        if(status_pay === "Y"){
            
            result_color = "blue";

        }else if(status_pay === "N"){
            
            result_color = "red";
        }


        let cancelStatus = 1;
        let cancelColor = "";
        let tdBack = "";

        // 취소 상태값
        if(status === "C" || status === "BC" || status === "EC"){
            
            cancelColor = "red";
            tdBack = "red_bg";
        }else{
            cancelColor = "";
            tdBack = "";
        }

        let quota_txt = "";

        if(quota === "00"){
            quota_txt = "일시불";
        }else{
            quota_txt = quota;
        }


     

        // PG 입금
        let pgImcome = parseInt(amount) - parseInt(pg_fee);
        

        // 정산금액 (결제금액 - PG - 가맹점)
        let cal_price = parseInt(amount) - parseInt(pg_fee) - parseInt(St_fee);

        // 관리자 정산금액 = 결제금액 / 100 * (지사 - 본사 수수료)
        let calculateAdmin = parseInt(amount) / 100 * (feeMbramch - feeAdmin);
        
        // 지사 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateMbramch = parseInt(amount) / 100 * (feeDistributor - feeMbramch);

        // 총판 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateDistributor = parseInt(amount) / 100 * (feeAgency - feeDistributor);

        // 대리점 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateAgency = parseInt(amount) / 100 * (feeStore - feeAgency);

        // 보류 가능한지 여부
        let delay_chk = ChkDelayAble(authno, amount * -1);

        let card_out_no = cardno.substr(0, cardno.length-4) + "****";

        // 주문자 이름 처리
        let username_new = Set_User_Name_Cert(username);

        // 전화번호 처리
        let userphone_new = Set_User_HP_Cert(userphone);

        let outs = "";
        if (daoutrx !== '') {
            outs = (
            <>
            {/* <button type="button" className="btn_st_02 blue_bg" onClick={() =>  junpyo(daoutrx)}>매출전표</button> */}
            <Link to={`/SiteAdmin/_pay_terminal/transactionHistoryPop?idx=`+idx} className="btn_st_02 blue_bg" target='_blank'>매출전표</Link>
            </>
            );
        }


        let paymentTypeString = "";

        if( paymenttype === 1){
            paymentTypeString  = "Terminal";
        }else if( paymenttype === 2){
            paymentTypeString  = "SMS";
        }else if( paymenttype === 3){
            paymentTypeString  = "EMAIL";
        }

        return (
            <tr className={tdBack}>
                <td>
                    <label htmlFor={"num_"+nums}><span></span><Link to={`/Store/_pay_terminal/transactionHistoryView?idx=`+idx} style={{'color':'blue'}}>{nums}</Link></label>
                </td>
                <td style={{'color':cancelColor}}>{created_datetime1} {created_datetime2}</td>
                <td><span style={{'color':cancelColor}}>{GetPayGubun(status)}</span></td>
                <td>{St_branch_name}</td>
                <td>{paymentTypeString}</td>
                <td>{cpid}</td>
                <td>신용</td>
                <td style={{'color':cancelColor}}>{GetPayStatus(status)}</td>
                <td>{PgCardName(pg_group, cardcode)}</td>
                <td>{PgCardName(pg_group, cardcode)}</td>
                <td style={{'color':cancelColor}}>{card_out_no}</td>
                <td>{authno}</td>
                <td style={{'color':cancelColor}}>{cancel_date1}</td>
                <td>{PgName(pg_group)}</td>
                <td>{calculate_date_re}</td>
                <td>{calculate_date_re} <span className="red">(D+{income})</span></td>
                <td style={{'color':cancelColor}}>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{doubleChk>1?"중복":""}</td>
                <td>{outs}</td>
                <td style={{'color':cancelColor}}>{pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <span className="red">({fee}%)</span></td>
                <td style={{'color':cancelColor}}>{pgImcome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td style={{'color':cancelColor}}>{cal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td style={{'color':cancelColor}}>{calculateDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<span className="red">({feeDistributor}%)</span></td>
                
                <td>{username_new}</td>
                <td>{userphone_new}</td>
                <td>{productname}</td>
            </tr>
        );
    };



    const BoardExcel = ({
        idx,
        nums,
        created_datetime,
        resultcode,
        quota,
        cardno,
        cpid,
        authno,
        
        productname,
        username,
        userphone,
        status,
        status_pay,
        pg_group,
        cardcode,
        orderno,
        regid,
        calculate_date_re,
        feeStore,
        St_fee,
        Br_branch_name,
        Di_branch_name,
        Ag_branch_name,
        St_branch_name,
        income,
        pg_fee,
        fee,
        feeAdmin,
        feeMbramch,
        feeDistributor,
        feeAgency,
        cancel_date,
        st_type,
        amount,
        paymenttype
    }) => {

        
        
        let created_datetime1 = created_datetime.substr(0,10);
        let created_datetime2 = created_datetime.substr(11,8);

        let cancel_date1 = cancel_date.substr(0,10);
        if(cancel_date !== ""){
            if(cancel_date1 === "0000-00-00"){
                cancel_date1 = "";
            }
        }




        
        let result_color = "";

        if(status_pay === "Y"){
            
            result_color = "blue";

        }else if(status_pay === "N"){
            
            result_color = "red";
        }


        let cancelStatus = 1;
        let cancelColor = "";
        let tdBack = "";

        // 취소 상태값
        if(status === "C" || status === "BC" || status === "EC"){
            
            cancelColor = "red";
            tdBack = "red_bg";
        }else{
            cancelColor = "";
            tdBack = "";
        }

        let quota_txt = "";

        if(quota === "00"){
            quota_txt = "일시불";
        }else{
            quota_txt = quota;
        }


     

        // PG 입금
        let pgImcome = parseInt(amount) - parseInt(pg_fee);
        

        // 정산금액 (결제금액 - PG - 가맹점)
        let cal_price = parseInt(amount) - parseInt(pg_fee) - parseInt(St_fee);

        // 관리자 정산금액 = 결제금액 / 100 * (지사 - 본사 수수료)
        let calculateAdmin = parseInt(amount) / 100 * (feeMbramch - feeAdmin);
        
        // 지사 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateMbramch = parseInt(amount) / 100 * (feeDistributor - feeMbramch);

        // 총판 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateDistributor = parseInt(amount) / 100 * (feeAgency - feeDistributor);

        // 대리점 정산금액 = 결제금액 / 100 * (총판 - 지사)
        let calculateAgency = parseInt(amount) / 100 * (feeStore - feeAgency);

        // 보류 가능한지 여부
        let delay_chk = ChkDelayAble(authno, amount * -1);

        let card_out_no = cardno.substr(0, cardno.length-4) + "****";

        // 주문자 이름 처리
        let username_new = username.substr(0, (username.length)-2 ) + "*" + username.substr(-1,1);

        // 전화번호 처리
        let userphone_new = "";
        for (let i= 0; i<userphone.length - 4; i++){
            userphone_new += "*";
        }
        userphone_new += userphone.substr(-4,4);

        let paymentTypeString = "";

        if( paymenttype === 1){
            paymentTypeString  = "Terminal";
        }else if( paymenttype === 2){
            paymentTypeString  = "SMS";
        }else if( paymenttype === 3){
            paymentTypeString  = "EMAIL";
        }

        return (
            <tr className={tdBack}>
                <td>{created_datetime1} {created_datetime2}</td>
                <td>{GetPayGubun(status)}</td>
                
                <td>{St_branch_name}</td>
                <td>{paymentTypeString}</td>
                <td>{cpid}</td>
                <td>신용</td>
                <td>{GetPayStatus(status)}</td>
                <td>{PgCardName(pg_group, cardcode)}</td>
                <td>{PgCardName(pg_group, cardcode)}</td>
                <td>{card_out_no}</td>
                <td>{authno}</td>
                <td>{cancel_date1}</td>
                <td>{PgName(pg_group)}</td>
                <td>{calculate_date_re}</td>
                <td>{calculate_date_re} (D+{income})</td>
                <td>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({fee}%)</td>
                <td>{pgImcome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                
                <td>{cal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{calculateDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({feeDistributor}%)</td>
                
                <td>{username_new}</td>
                <td>{userphone_new}</td>
                <td>{productname}</td>
            </tr>
        );
    };


    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates);
        navigate('/Distributor/_pay_terminal/transactionHistoryList?searchWord='+searchWord+'&searchCates='+searchCates);
        
    }

    // 전체 수량 구하는 
    function getTotalCnt(){

        const loginId = sessionStorage.getItem("loginId");
        
        if(loginId === null){
            navigate('/Store/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }

      
        
        let bodyQuery = {
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: parent_idx2,
            status: status
        }

        //console.log(bodyQuery);
        axios.post('/api/terminal/store/pay/transaction_history_total_list', bodyQuery).then(function(res){     
            //console.log(res.data.tcnt);
            setTotalCnt(res.data.tcnt);
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })


    }

    
    

    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
   

        getTotalCnt();
        
        if(sdate !== ""){
            setStartDate( new Date(sdate) );
        }

        if(edate !== ""){
            setEndDate( new Date(edate) );
        }

        if(q_parent_idx1 !== ""){
            set_parent_idx1(q_parent_idx1);
        }
        /*
        if(q_parent_idx2 !== ""){
            set_parent_idx2(q_parent_idx2)
        }
        */
        

    },[sWord, sCate]);
    /*
    pg, sWord
    */

  
    useEffect(()=>{

        const loginId = sessionStorage.getItem("loginId");
        
        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: parent_idx2,
            status: status

        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/store/pay/transaction_history_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)

            axios.post('/api/terminal/store/pay/transaction_history_list_excel', bodyPage).then(function(res){
                setViewExcel(res.data); 
            }) 
        }) 

          

        
    },[sWord, sCate, page]);
    // nums
    
    
    // 수기상점코드 리스트 가져오기
    function getSugiList(){
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/terminal/terminal/sugi_sel_list',bodyPage).then(function(res){            
            setSugiList(res.data);
            
        })

    }
   
    function rendSugiList(){
        return (
                sugiList.map((v, index) => {
                    return (
                        <option value={v.pg} key={v.idx} >{v.sname}</option>
                    )
                })
            

        )
    }


    function rendManagerList(){
        return (
            <select name="parent_idx1" id="parent_idx1" onChange={on_set_parent_idx1_handler} value={parent_idx1}  >
                <option value=''>지사</option>
            {
                managerList1.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    function rendManagerList2(){
        return (
            <select name="parent_idx2" id="parent_idx2" onChange={on_set_parent_idx2_handler} value={parent_idx2} >
                <option value=''>총판</option>
            {
                managerList2.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    
    useEffect(() => {
        getManagerList1()
        getSugiList()
    },[])

    // 총판 내역 가져오기
    useEffect(() => {
        getManagerList2(q_parent_idx1);

        
    },[managerList1])
  


    function getManagerList1(){
        
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list',bodyPage).then(function(res){            
            setManagerList1(res.data);
            
        })
    }

    function getManagerList2(temp_idx){

        
        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list',bodyPage).then(function(res){            
            setManagerList2(res.data);

            
            
        })
        if(q_parent_idx2 !== ""){
            set_parent_idx2(q_parent_idx2)
        }
    }



    const fn_submit = (e) => {
        $("#pg").val("1");
        //e.preventDefault();
    }

    const tableRef = useRef(null);


    const handleDownload = () => {
        const table = tableRef.current;
        let data = [];
        for (let i = 0; i < table.rows.length; i++) {
            let rowData = [];
            for (let j = 0; j < table.rows[i].cells.length; j++) {
                rowData.push(table.rows[i].cells[j].innerHTML);

                
            }
            data.push(rowData);
        }
        const ws = XLSX.utils.aoa_to_sheet(data);

        // 테두리 스타일 적용
        const range = XLSX.utils.decode_range(ws['!ref']);
        for (let i = range.s.r; i <= range.e.r; i++) {
            for (let j = range.s.c; j <= range.e.c; j++) {
                const cellAddress = XLSX.utils.encode_cell({ r: i, c: j });
                const cell = ws[cellAddress];
                if (!cell) continue;
                
                cell.s = {
                    border: {
                    top: { style: 'thin', color: { auto: 1 } },
                    bottom: { style: 'thin', color: { auto: 1 } },
                    left: { style: 'thin', color: { auto: 1 } },
                    right: { style: 'thin', color: { auto: 1 } },
                    },
                };
            }
        }
                

        if (data.length > 0) {
        ws["!merges"] = [{ s: { r: data.length - 1, c: 0 }, e: { r: data.length - 1, c: 14 } }];

        ws["A1"].s = 
        { 
            font: 
            {
            color: { rgb: "FFFFAA00" }
            } 
        }
        
        }
        
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "data");
        XLSX.writeFile(wb, "결제내역.xlsx");

    };


    return (
        <>
        <div id="wrap">
            <Header/>

            
            <div id="container" data-menu-page="pay_history">
                <h3 className="page_ttl">결제내역</h3>
                <form name="" id="" action="./transactionHistoryList" method="get" onSubmit={ fn_submit } >
                    <input type="hidden" name="pg" id="pg" value={page} />

                    <div className="top_filter">
                        <div className="sel_box">
                            <select name="pg_group" id="pg_group" >
                                <option value="">PG</option>
                                {
                                    rendSugiList()
                                }
                            </select>
                        </div>

                       
                        <div className="sel_box">
                            <select name="ordertype" id="ordertype" >
                                <option value="" >거래구분</option>
                                <option value="Y">승인</option>
                                <option value="N">취소</option>
                                <option value="D">보류</option>
                            </select>
                        </div>

                        <div className="sel_box">
                            <select name="chkdate" id="chkdate" >
                                <option value="authdate">거래일자</option>
                                <option value="calculatedate">정산일</option>
                                <option value="canceldate">취소일</option>
                            </select>
                        </div>

                        <div className="date_box datepick_box">
                            <input type="hidden" name="sdate" id="sdate" value={ (startDate === null || startDate === "") ? '' : new Date( startDate.toString().split('GMT')[0]+' UTC') .toISOString().slice(0,10) } />
                            <input type="hidden" name="edate" id="edate" value={ (endDate === null || endDate === "") ? '' : new Date( endDate.toString().split('GMT')[0]+' UTC') .toISOString().slice(0,10) } />
                            
                            <div className="datepick">
                                <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)}  />
                            </div>
                            <span>-</span>
                            <div className="datepick">
                                <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                        </div>

                    

                        <div className="sel_box">
                            <select name="status" id="status" >
                                <option value="">정산상태</option>
                                <option value="B">정산전</option>
                                <option value="E">정산완료</option>
                                <option value="BC">정산전 취소</option>
                                <option value="EC">정산완료후취소</option>
                            </select>
                        </div>

                        <div className="sel_box">
                            <select name="searchCates" id="searchCates">
                                <option value="authno">승인번호</option>
                                <option value="amount">금액</option>
                                <option value="cardno">카드번호(뒤4자리)</option>
                                <option value="cpid">터미널코드</option>
                                <option value="St_branch_name">가맹점명</option>
                            </select>
                        </div>

                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" placeholder='검색해주세요' />
                            <button type="submit" className="btn_st_02 blue search">검색</button>
                        </div>
                        
                    </div>
                </form>

                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                        <div className="right">
                            <button className="els_btn" onClick={handleDownload}><i></i>엑셀 다운로드</button>
                        </div>
                    </div>
                    <div className="table_box">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <label htmlFor="num_all"><span></span> NO</label>
                                    </th>
                                    <th>거래일자</th>
                                    <th>구분</th>
                                    <th>가맹점</th>
                                    <th>매체</th>
                                    <th>TID</th>
                                    <th>구분</th>
                                    <th>정산</th>
                                    <th>발행</th>
                                    <th>매입</th>
                                    <th>카드번호</th>
                                    <th>승인번호</th>
                                    <th>취소</th>
                                    <th>PG</th>
                                    <th>PG정산</th>
                                    <th>정산예정</th>
                                    <th>거래금액</th>
                                    <th>중복구분</th>
                                    <th>매출전표</th>
                                    <th>PG</th>
                                    <th>PG입금</th>
                                    <th>가맹점</th>
                                    <th>정산금액</th>
                                    
                                    
                                    
                                    <th>주문자</th>
                                    <th>연락처</th>
                                    <th>상품명</th>
                                </tr>
                            </thead>

                            <tbody>
                            {
                                viewContent.map((v, index) => {
                                    
                                    // 거래금액 합산
                                    total_amount = parseInt(total_amount) + parseInt(v.amount);

                                    // PG금액 합산
                                    total_pg_fee = parseInt(total_pg_fee) + parseInt(v.pg_fee);


                                    // PG 입금 합산 금액
                                    total_pgImcome = parseInt(total_pgImcome) + ( parseInt(v.amount) - parseInt(v.pg_fee) );

                                    // 가맹점 합산 금액
                                    total_St_fee = parseInt(total_St_fee) + parseInt(v.St_fee);

                                    // 정산금액 합산
                                    total_cal_price = parseInt(total_cal_price) + ( parseInt(v.amount) - parseInt(v.pg_fee) - parseInt(v.St_fee) );

                                    // 본사금액 합산
                                    total_calculateAdmin = parseInt(total_calculateAdmin) + ( parseInt(v.amount) /100 * ( v.feeMbramch - v.feeAdmin ) );

                                    // 지사금액 합산
                                    total_calculateMbramch = parseInt(total_calculateMbramch) + ( parseInt(v.amount) /100 * ( v.feeDistributor - v.feeMbramch ) );

                                    // 총판금액 합산
                                    total_calculateDistributor = parseInt(total_calculateDistributor) + ( parseInt(v.amount) /100 * ( v.feeAgency - v.feeDistributor ) );

                                    // 대리점금액 합산
                                    total_calculateAgency = parseInt(total_calculateAgency) + ( parseInt(v.amount) /100 * ( v.feeStore - v.feeAgency ) );


                                    return (
                                        <Board
                                            key={v.idx}
                                            idx={v.idx}
                                            nums={nums-index}
                                            created_datetime={v.created_datetime}
                                            resultcode={v.resultcode}
                                            quota={v.quota}
                                            cardno={v.cardno}
                                            cpid={v.cpid}
                                            authno={v.authno}
                                            amount={v.amount}
                                            productname={v.productname}
                                            username={v.username}
                                            userphone={v.userphone}
                                            status={v.status}
                                            status_pay={v.status_pay}
                                            pg_group={v.pg_group}
                                            cardcode={v.cardcode}
                                            orderno={v.orderno}
                                            regid={v.regid}
                                            calculate_date_re={v.calculate_date_re}
                                            feeStore={v.feeStore}
                                            St_fee={v.St_fee}
                                            Br_branch_name={v.Br_branch_name}
                                            Di_branch_name={v.Di_branch_name}
                                            Ag_branch_name={v.Ag_branch_name}
                                            St_branch_name={v.St_branch_name}
                                            income={v.income}
                                            pg_fee={v.pg_fee}
                                            fee={v.fee}
                                            feeAdmin={v.feeAdmin}
                                            feeMbramch={v.feeMbramch}
                                            feeDistributor={v.feeDistributor}
                                            feeAgency={v.feeAgency}
                                            cancel_date={v.cancel_date}
                                            st_type={v.st_type}
                                            paymenttype={v.paymenttype}
                                            doubleChk={v.doubleChk}
                                            daoutrx={v.daoutrx}
                                        />
                                    );
                                })

                                
                            }
                                

                                <tr className="total">
                                    <td colSpan="18">TOTAL</td>
                                    <td>{total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_pgImcome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    
                                    <td>{total_cal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_calculateDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    
                                    <td colSpan="3"></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    
                    {                        
                        viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    } 

                </div>
            </div>
        </div>


        <table ref={tableRef} style={{"display":"none"}}>
            <thead>
                <tr>
                    <th>거래일자</th>
                    <th>구분</th>
                    <th>가맹점</th>
                    <th>매체</th>
                    <th>TID</th>
                    <th>구분</th>
                    <th>정산</th>
                    <th>발행</th>
                    <th>매입</th>
                    <th>카드번호</th>
                    <th>승인번호</th>
                    <th>취소</th>
                    <th>PG</th>
                    <th>PG정산</th>
                    <th>정산예정</th>
                    <th>거래금액</th>
                    <th>PG</th>
                    <th>PG입금</th>
                    <th>가맹점</th>
                    <th>정산금액</th>
                    
                    
                    <th>주문자</th>
                    <th>연락처</th>
                    <th>상품명</th>
                </tr>
            </thead>
            <tbody>
            {
                viewExcel.map((v, index) => {
                    
                    // 거래금액 합산
                    total_amount = parseInt(total_amount) + parseInt(v.amount);

                    // PG금액 합산
                    total_pg_fee = parseInt(total_pg_fee) + parseInt(v.pg_fee);


                    // PG 입금 합산 금액
                    total_pgImcome = parseInt(total_pgImcome) + ( parseInt(v.amount) - parseInt(v.pg_fee) );

                    // 가맹점 합산 금액
                    total_St_fee = parseInt(total_St_fee) + parseInt(v.St_fee);

                    // 정산금액 합산
                    total_cal_price = parseInt(total_cal_price) + ( parseInt(v.amount) - parseInt(v.pg_fee) - parseInt(v.St_fee) );

                    // 본사금액 합산
                    total_calculateAdmin = parseInt(total_calculateAdmin) + ( parseInt(v.amount) /100 * ( v.feeMbramch - v.feeAdmin ) );

                    // 지사금액 합산
                    total_calculateMbramch = parseInt(total_calculateMbramch) + ( parseInt(v.amount) /100 * ( v.feeDistributor - v.feeMbramch ) );

                    // 총판금액 합산
                    total_calculateDistributor = parseInt(total_calculateDistributor) + ( parseInt(v.amount) /100 * ( v.feeAgency - v.feeDistributor ) );

                    // 대리점금액 합산
                    total_calculateAgency = parseInt(total_calculateAgency) + ( parseInt(v.amount) /100 * ( v.feeStore - v.feeAgency ) );


                    return (
                        <BoardExcel
                            key={v.idx}
                            idx={v.idx}
                            created_datetime={v.created_datetime}
                            resultcode={v.resultcode}
                            quota={v.quota}
                            cardno={v.cardno}
                            cpid={v.cpid}
                            authno={v.authno}
                            amount={v.amount}
                            productname={v.productname}
                            username={v.username}
                            userphone={v.userphone}
                            status={v.status}
                            status_pay={v.status_pay}
                            pg_group={v.pg_group}
                            cardcode={v.cardcode}
                            orderno={v.orderno}
                            regid={v.regid}
                            calculate_date_re={v.calculate_date_re}
                            feeStore={v.feeStore}
                            St_fee={v.St_fee}
                            Br_branch_name={v.Br_branch_name}
                            Di_branch_name={v.Di_branch_name}
                            Ag_branch_name={v.Ag_branch_name}
                            St_branch_name={v.St_branch_name}
                            income={v.income}
                            pg_fee={v.pg_fee}
                            fee={v.fee}
                            feeAdmin={v.feeAdmin}
                            feeMbramch={v.feeMbramch}
                            feeDistributor={v.feeDistributor}
                            feeAgency={v.feeAgency}
                            cancel_date={v.cancel_date}
                            st_type={v.st_type}
                            paymenttype={v.paymenttype}
                        />
                        
                    );
                })
                
            }
                
                <tr className="total">
                    <td>TOTAL</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    
                    
                    <td>{total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    
                    <td>{total_cal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{total_calculateAdmin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{total_calculateMbramch.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{total_calculateDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    
                    <td colSpan="3"></td>
                </tr>
            </tbody>
        </table>

        <Footer/>
        </>
    )

    
}
export default StorePayTransactionHistoryListTerminal