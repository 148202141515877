import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';
import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import closebtn from '../../../../../assest/img/common/re_closebtn_w.png';




function SiteAdminManageBankList() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('sname');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');


    const [bank_code, set_bank_code] = useState('');

    const on_bank_code_handler = (event) => {
        set_bank_code(event.currentTarget.value);
    }

    const [bank_name, set_bank_name] = useState('');

    const on_bank_name_handler = (event) => {
        set_bank_name(event.currentTarget.value);
    }



    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    // PG idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    const on_set_parent_idx_handler = (event) => {
        set_parent_idx(event.currentTarget.value)
    }

    
    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;




    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            bank_code: bank_code,
            bank_name: bank_name
        }

        axios.post('/api/manage/BankAdd', body)
        .then(function(response){
            

            
            if(response.data === "AddOk"){    
                //pop_close();
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


    }




    const Board = ({
        idx,
        nums,
        bank_code,
        bank_name
    }) => {
   
        return (
            <tr>
                <td>{bank_code}</td>
                <td>{bank_name}</td>
                <td>
                    <div className="btn_wrap"> <button type="button" code={bank_code} onClick={on_dels} className="btn_st_02 blue_line">삭제</button></div>  
                </td>
            </tr>
        );
    };



    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        navigate('/SiteAdmin/_terminal/DirectPaymentList?searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        
    }


    // 닫기 버튼을 눌렀을 때
    function pop_close(){
        const bank_pop = document.getElementsByClassName("bank_pop").item(0);
        bank_pop.classList.remove('show');
        set_bank_code("");
        set_bank_name("");
    }

    // 등록 버튼을 눌렀을 때
    function pop_show(){
        const bank_pop = document.getElementsByClassName("bank_pop").item(0);
        bank_pop.classList.add('show');
    }


    // 전체 수량 구하는 
    function getTotalCnt(){
        //console.log("getTotalCnt Start");
        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
        console.log("bodyQuery : " + parent_idx);

        axios.post('/api/manage/bank_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }

    // 은행 삭제
    function on_dels(event){
        const codes = event.target.getAttribute('code');


        if( ! window.confirm("정말 삭제하시겠습니까?")){
            return false;

        }

        
        let body = {
            codes: codes
        }

        axios.post('/api/manage/BankDel', body)
        .then(function(response){
            

            
            if(response.data === "DelOk"){    
                //pop_close();
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])
    }


    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

       

        getTotalCnt();
        
    },[sWord, sCate, sPg, sParent_idx,  page]);


    useEffect(()=>{
        /*
        console.log("num 재확인 시작");
        console.log("nums : " + nums);
        console.log("num 재확인 끝");
        */

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
        console.log("bodyPage : " + bodyPage);

        axios.post('/api/manage/bank_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            console.log(res.data)
        }) 

        
    },[sWord, sCate, sParent_idx, sPg]);

    return (
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="bank_list">
                <h3 className="page_ttl">은행관리</h3>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>
                        <div className="right">
                            <button className="btn_st_02 blue_bg bank_add" onClick={pop_show}> 등록</button>
                        </div>
                    </div>
                    <div className="table_box">

                        <table>

                            <thead>
                                <tr>
                                    <th>코드명</th>
                                    <th>은행명</th>
                                    <th>관리</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                bank_code={v.bank_code}
                                                bank_name={v.bank_name}
                                            />
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="pop_common bank_pop" >
                    <div className="pop_cont" style={{"maxWidth":"400px"}}>
                        <div className="pop_top">
                            <h3 className="pop_ttl">은행정보 추가</h3>
                            <button type="button" onClick={pop_close} >
                                <img src={closebtn} alt="닫기버튼" />
                            </button>
                        </div>
                        <div className="pop_item">
                            <div className="pop_table">
                                <form onSubmit={onSubmitHandler}>
                                <table className="table_layout01">
                                    <colgroup>
                                        <col style={{"width":"120px"}} />
                                        <col style={{"width":"auto"}} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>코드명</th>
                                            <td>
                                                <input type="text" name="bank_code" id="bank_code" value={bank_code} required onChange={on_bank_code_handler} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>은행명</th>
                                            <td>
                                                <input type="text" name="bank_name" id="bank_name" value={bank_name} required onChange={on_bank_name_handler} />
                                            </td>
                                        </tr>
                                    

                                    </tbody>
                                </table>
                                <div className="btn_box" style={{"textAlign":"center"}}>
                                    <button type="submit" className="blue btn_st_02">등록</button>
                                    
                                </div>	
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminManageBankList