
import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';

import NoticeList from "../../../../page/notice/List";
import Header from "../../../include/MbranchHeader";
import Footer from "../../../include/SiteAdminFooter";




const MbranchManageNoticeList =()=>{

    return(
        <>
        
        <div id="wrap">
            <Header/>            
                <NoticeList user_level={'2'} user_site={'Mbranch'} />
        </div>
        <Footer/>         
        </>
    )
}

export default MbranchManageNoticeList