import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';


import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';

import Header from "../../../include/StoreHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import { useCookies } from 'react-cookie';



function StoreManagementGoodsMod() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }

    const [cookies, setCookie, removeCookie] = useCookies(['x_auth']);

    const [reg_id, set_reg_id] = useState("");
    
    const [goods_name, set_goods_name] = useState("");

    const on_goods_name_handler = (event) => {
        set_goods_name(event.currentTarget.value)
    }


    const [customer_name, set_customer_name] = useState("");

    const on_customer_name_handler = (event) => {
        set_customer_name(event.currentTarget.value)
    }


    const [customer_email, set_customer_email] = useState("");

    const on_customer_email_handler = (event) => {
        set_customer_email(event.currentTarget.value)
    }


    const [customer_hp, set_customer_hp] = useState("");

    const on_customer_hp_handler = (event) => {
        set_customer_hp(event.currentTarget.value)
    }

    const [paytype, set_paytype] = useState("");

    const on_paytype_handler = (event) => {
        set_paytype(event.currentTarget.value)
    }


    const [price, set_price] = useState("");
    
    const on_price_handler = (event) => {
        set_price(event.currentTarget.value)
    }


    const [memo, set_memo] = useState("");

    const on_memo_handler = (evnet) => {
        set_memo(evnet.currentTarget.value)
    }


    /*
    판매자 정보 관련
    */

    const [sellerName, set_sellerName] = useState("");
    const [sellerHp, set_sellerHp] = useState("");
    const [sellerAddr, set_sellerAddr] = useState("");



    useEffect(() => {

        console.log(sIdx);

        const loginId = sessionStorage.getItem("loginId");


        let bodyPage2 = {
            loginId: loginId
        }

        axios.post('/api/store/userInfo', bodyPage2).then(function(response){
          
            if(response.status === 401 || response.data.length === 0){
                alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
                navigate('/Store/');
                return;
            }
            
            // 등록자 ID
            set_reg_id(response.data[0].user_id);
            set_sellerName(response.data[0].branch_name);
            set_sellerHp(response.data[0].user_phone);
            set_sellerAddr(response.data[0].corp_addr);
        });


   
        let bodyPage = {
            idx: sIdx,
            user_id: loginId
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/store/management/goodsMod', bodyPage).then(function(res){
            
            res.data.map((v, index) => {
                set_goods_name(v.goods_name);
                set_customer_name(v.customer_name);
                set_customer_hp(v.customer_hp);
                set_paytype(v.paytype);
                set_customer_email(v.customer_email);
                set_price(v.price);
                set_memo(v.memo);
            
            })
            
        }) 
                
           
    }, []);



    
    const onSubmitHandler = (event) => {
        event.preventDefault();

       

        let body = {
            idx: qs.idx,
            goods_name: goods_name,
            customer_name: customer_name,
            customer_hp: customer_hp,
            paytype: paytype,
            customer_email: customer_email,
            price: price,
            memo: memo
        }
        console.log(body);

        axios.post('/api/store/management/goodsUpdate', body)
        .then(function(response){
           
            if(response.data === "ModOk"){    
                //navigate('/SiteAdmin/_management/DistributorList');
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


    }

    
    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="goods" className="write_form">  
                <h3 className="page_ttl">상품관리</h3>
                <form onSubmit={onSubmitHandler}>
                <div className="form_item">
                    <input type="hidden" name="reg_id" id="reg_id" value={reg_id} />

                    <h3 className="label">* 상품정보를 등록하면 고객 결제 단축 URL이 생성 됩니다.</h3>

                    <div className="top_table">
                        <table>
                            <colgroup>
                                <col width="150px" />
                                <col width="*" />
                                <col width="150px" />
                                <col width="*" />
                            </colgroup>

                            <tbody>
                                <tr>
                                    <th>판매자 정보</th>
                                    <td colSpan="3">
                                        <p>- 판매자명 : {sellerName}   /  휴대폰 : {sellerHp} </p>
                                        <p>- 주소 : {sellerAddr} </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>결제방법</th>
                                    <td>
                                        <div className="flex">
                                            <div className="sel_box">
                                            <select name="paytype" id="paytype" className="wd100" onChange={on_paytype_handler} required>  
                                                <option value="">선택</option>
                                                <option value="s" selected={(paytype === 's' ? "checked":"")} >문자</option>
                                                <option value="e" selected={(paytype === 'e' ? "checked":"")} >이메일</option>
                                            </select>
                                            </div>
                                        </div>
                                    </td>

                                    <th>고객명</th>
                                    <td>
                                        <div className="flex">
                                            <div className="in_box">
                                                <input type="text" maxLength="25" name="customer_name" id="customer_name" value={customer_name} onChange={on_customer_name_handler} required />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td>
                                        <div className="flex">
                                            <div className="in_box">
                                                <input type="text" maxLength="20" name="customer_email" id="customer_email" value={customer_email} onChange={on_customer_email_handler} required />
                                            </div>
                                        </div>
                                    </td>
                                    <th>휴대폰</th>
                                    <td>
                                        <div className="flex">
                                            <div className="in_box">
                                                <input type="text" maxLength="20" name="customer_hp" id="customer_hp" value={customer_hp} onChange={on_customer_hp_handler} required />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>상품명</th>
                                    <td>
                                        <div className="in_box">
                                            <input type="text" name="goods_name" id="goods_name" value={goods_name} onChange={on_goods_name_handler} required  />
                                        </div>
                                    </td>
                                    <th>판매가격</th>
                                    <td>
                                        <div className="sel_box">
                                        <input type="text" className="yellow" name="price" id="price" value={price} onChange={on_price_handler} required />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>상품정보</th>
                                    <td colSpan="3">
                                        <div className="in_box">
                                        <textarea name="memo" id="memo" value={memo} onChange={on_memo_handler} cols="30" rows="10"></textarea>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                
                </div>

                <div className="top_table_btn">
                 
                    <button className=" btn_st_01" onClick={  () => navigate(-1) } >목록</button>
                    <button className=" blue btn_st_01">등록</button>
                </div>

                </form>
            </div>
            
        </div>

        <Footer/>
        </>
    )
}

export default StoreManagementGoodsMod