import React, {useEffect, useState, useRef, useReducer, useCallback } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../../include/Paging";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import closebtn from '../../../../../assest/img/common/re_closebtn_w.png';

//react-datepicker
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
//react-datepicker//

function SiteAdminManageSetupLoginList() {
    

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    const query = queryString.parse(location.search);    
        

    const [list , setList] = useState({
        viewContent:'',     // 게시판 내역
        totalCnt:0,         // 게시판 총 수
        nums:0,             // 게시판 넘버
        pageMax:0,          // 페이징 페이징에 한번에 보일 수
        gListRows:10,       // 페이징 한번에 나오는 리스트 수
        page:1,             // 페이징 현재 페이지
        searchCates:'',     // 검색 카테고리
        searchWord:'',      // 검색 키워드
        url:queryParam,     // 페이징 url 정보
        parent_idx:''       // PG idx 처리
        
    }); 
    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const sWord = query.searchWord ? query.searchWord : '';
    const sCate = query.searchCates ? query.searchCates : '';
    const sParent_idx = query.parent_idx ? query.parent_idx : '';
    const sPg = query.pg? query.pg : 1;
    
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setList({...list, searchCates:event.currentTarget.value});
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{        
        setList({...list, searchWord:event.currentTarget.value});
    });

    function onSubmitSearch(event){
        event.preventDefault();        
        const purl = 'searchWord='+list.searchWord+'&searchCates='+list.searchCates+'&parent_idx='+list.parent_idx;
        setList({...list, url:purl});        
        navigate('/SiteAdmin/_manage/SetupList?searchWord='+list.searchWord+'&searchCates='+list.searchCates+'&parent_idx='+list.parent_idx);        
    }


    
    const Board = ({
        idx,
        nums,        
    }) => {

            return (
                <tr>
                    <td>{nums}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            
            );
    };



    useEffect(() => {
               
     
    },[sWord, sCate, sParent_idx, sPg]);
    


    return(
    <>
        <div id="wrap">
            <Header/>
            <div id="container" data-menu-page="setup_admin">
                <h3 className="page_ttl">운영로그관리</h3>


                    <form onSubmit={onSubmitSearch} >
                        <div className="top_filter">
                            <div className="date_box datepick_box">
                                <div className='datepick'>
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <span>-</span>
                                <div className='datepick'>
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                                </div>
                         
                            </div>
                           
                            <div className="sel_box">
                                <select  name="searchCates" id="searchCates" required onChange={onSearchCates} value={list.searchCates}>
                                    <option value="">상점ID</option>
                                    <option value="">IP</option>
                                    <option value="">기능</option>
                                    <option value="">URL</option>
                                    <option value="">로그인</option>
                                </select>
                            </div>

                            <div className="in_box search_box">
                                <input type="text" name="searchWord" id="searchWord" value={list.searchWord} onChange={onSearchWord} placeholder='검색해주세요' />
                                <button type="submit" className="btn_st_02 blue search">검색</button>
                            </div>
                        
                           
                        </div>
                    </form>


                    <div className="bot_item">
                        <div className="bot_info">
                            <div className="left">총 {list.totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                        </div>
                        <div className="table_box">


                            <table>

                                <thead>

                                    <tr>
                                        <th>No</th>
                                        <th>상점ID</th>
                                        <th>IP</th>
                                        <th>기능</th>
                                        <th>URL</th>
                                        <th>로그인</th>
                                        <th>수정일자</th>
                                        <th>접속일자</th>
                                    </tr>

                                </thead>

                                <tbody>
                                    {
                                        (list.viewContent !=='') && (                                            
                                            list.viewContent.map((v, index) => {

                                                return (
                                                    <Board
                                                        key={v.idx}
                                                        idx={v.idx}
                                                        nums={list.nums-index}                                                  
                                                    />
                                                );
                                            })
                                        )

                                    }                                    
                                </tbody>
                            </table>
                        </div>

                        {
                            list.viewContent.length > 0 ? <Paging curPage={list.page} totalPage={Math.ceil(list.totalCnt/list.gListRows)} pageBtnNum={list.pageMax} url={list.url} /> : null
                        }
                    </div>

                    

            </div>
        </div>

        <Footer/>        
    </>
    )
}

export default SiteAdminManageSetupLoginList