import React, {useEffect, useState, useRef, useReducer, useCallback } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import {useCookies} from 'react-cookie'
import moment from 'moment';
import closebtn from '../../../../../assest/img/common/re_closebtn_w.png';



const SetupLoginPopup =()=>{
    
    
    const [CookieCertNum, setCookieCertNum, removeCookieCertNum] = useCookies(['cert_num']); // 쿠키 훅 
    const [certNumber, setCertNumber] = useState(""); //인증확인 번호
    const [authCheck, setAuthCheck] = useState(false); 
    const [form , setForm] = useState({
        loginType:'HP',     // 인증방법 hp,email
        phone_num:'',
        email1:'',
        domain:'',
        domain_input:'',      
    }); 

    const onCertNumberHandler = (event) => {
        setCertNumber(event.currentTarget.value)
    }    
        
    const on_loginType = (event) => {                      
        setAuthCheck(false);
        setCertNumber('');
        setForm({...form, loginType:event.currentTarget.value, phone_num:'', email1:'', domain:'', domain_input:''});      
    }   

    const on_email1_handler = (event) => {
        removeCookieCertNum(['cert_num']);               
        setForm({...form, email1:event.currentTarget.value});
    }

    const on_domain_handler = (event) => {   
        removeCookieCertNum(['cert_num']);            
        setForm({...form, domain:event.currentTarget.value});
    }

    const on_phone_num_handler = (event) => {          
        removeCookieCertNum(['cert_num']);
        setForm({...form, phone_num:event.currentTarget.value});
    }
    
    const on_domain_input_handler = (event) => {  
        removeCookieCertNum(['cert_num']);             
        setForm({...form, domain_input:event.currentTarget.value});
    }


    const onSubmitHandler = (event) => {
        event.preventDefault();
    }

    // 등록 팝업 FORM ############## //

    // 닫기 버튼을 눌렀을 때
    function pop_close(){
        const bank_pop = document.getElementsByClassName("bank_pop").item(0);
        bank_pop.classList.remove('show');        
    }

    const FN_SNS_SEND =()=>{

        if(form.loginType === 'HP'){
            FN_HP_SEND_PROC();            
        }else if(form.loginType === 'EMAIL'){
            FN_EMAIL_SEND_PROC();                        
        }
        
    }
    


    //최종 인증번호 확인
    const Fn_Sns_Number_Check =()=>{
    
        if(certNumber !== CookieCertNum.cert_num){
            alert('인증번호가 일치하지 않습니다.');
            setAuthCheck(false);
            return;
        }

        alert('정상적으로 인증되었습니다.');    
        setAuthCheck(true);
    }

    const Fn_Sns_Proc =()=>{
        if(authCheck === false){
            alert('인증이 필요합니다.');
            return;
        }

        if(certNumber !== CookieCertNum.cert_num){
            alert('인증번호가 일치하지 않습니다.');
            setAuthCheck(false);
            return;
        }

        onLoginAuth();   
    }



    const onLoginAuth =()=> {
        
        let body = {
            LoginType: form.loginType,
            hp: form.phone_num,
            email: form.email1+'@'+form.domain_input,
        }

        axios.post('/api/manage/Login_Cert_Insert', body).then(function(res){
            
            if(res.data === 'OK'){
                alert('정상적으로 등록되었습니다.');
                window.location.reload();
            }else{
                alert(res.data);
            }
        },[])
    }


    //이메일 인증번호 전송
    const FN_EMAIL_SEND_PROC =()=>{
        
        if(form.email1 === '' || form.domain_input === ''){
            alert('이메일 계정을 입력해주세요.');
            return;
        }

        const cert_num = Math.floor(Math.random() * 999999);

        let body = {
            receivers: form.email1+'@' + form.domain_input,
            subject:'[유월] 인증번호를 보내드립니다.',
            content:'인증번호 : ' + cert_num
        }
                
        axios.post('/api/email/sendMail', body)
        .then(function(res){
            
            if(res.data.code === 'ERR'){
                alert(res.data.message);
                return;
            }
            
            alert("인증번호를 발송하였습니다.");
            const expires =  moment().add('3','m').toDate()
            setCookieCertNum('cert_num', cert_num, {expires})
        }).catch((err) => {
            console.log('err : ', err);
        })

    }

    //휴대폰 인증번호 발송
    const FN_HP_SEND_PROC =()=>{

        if(form.phone_num === ''){
            alert('휴대폰 번호를 입력해주세요.');
            return;
        }

        const cert_num = Math.floor(Math.random() * 999999);     
        let body = {
            receivers: form.phone_num,
            message: '인증번호 : ' + cert_num
        }

        axios.post('/api/sms/send', body)
        .then(function(res){  
            if(res.data.result_code.toString() ==='-101'){
                alert(res.data.message);
                return;
            }                 
            alert("인증번호를 발송하였습니다.");
            const expires =  moment().add('3','m').toDate()
            setCookieCertNum('cert_num', cert_num, {expires})
        }).catch((err) => {
            //console.log('err : ', err);
        })
    }    
    return (
        <div className="pop_common bank_pop" >
            <div className="pop_cont" style={{"maxWidth":"700px"}}>
                <div className="pop_top">
                    <h3 className="pop_ttl">로그인정보 추가</h3>
                    <button type="button" onClick={pop_close} >
                        <img src={closebtn} alt="닫기버튼" />
                    </button>
                </div>
                <div className="pop_item">
                    <div className="pop_table">
                        <form onSubmit={onSubmitHandler}>
                        <table className="table_layout01">
                            <colgroup>
                                <col style={{"width":"120px"}} />
                                <col style={{"width":"auto"}} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>인증방법</th>
                                    <td>
                                        <div className="flex">
                                        <div className="radio_box">
                                            <input type="radio" name="loginType" id="logintype_hp" value="HP" checked={form.loginType ==='HP' || form.loginType===''} 
                                            onChange={on_loginType} />
                                            <label htmlFor="logintype_hp">휴대폰</label>
                                        </div>
                                        <div className="radio_box">
                                            <input type="radio" name="loginType" id="logintype_email" value="EMAIL" checked={form.loginType ==='EMAIL'} 
                                            onChange={on_loginType} />
                                            <label htmlFor="logintype_email">이메일</label>
                                        </div>
                                        
                                    </div>
                                    </td>
                                </tr>
                                
                                {
                                    (form.loginType === 'HP') && (
                                        <tr>
                                            <th>휴대폰</th>
                                            <td>
                                                <div className="button_row flex_box">
                                                    <input type="text" name="phone_num" id="phone_num" placeholder="010-0000-0000" value={form.phone_num} onChange={on_phone_num_handler} />                                                       
                                                    <button type="button" className="list_btn" onClick={FN_SNS_SEND}>인증번호 전송</button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }

                                {
                                    (form.loginType === 'EMAIL') && (
                                        <tr>
                                            <th>이메일</th>
                                            <td>
                                                <div className="flex_box button_row">
                                                    <div className="email">
                                                        <input className="email1" type="text" placeholder="이메일" value={form.email1} onChange={on_email1_handler} />
                                                        <span>@</span>
                                                        <select name="domain" id="domain" onChange={on_domain_handler} >
                                                            <option value="">선택</option>
                                                            <option value="dir_input">직접입력</option>
                                                            <option value="daum.net">daum.net</option>
                                                            <option value="naver.com">naver.com</option>
                                                            <option value="gmail.com">gmail.com</option>
                                                            <option value="nate.com">nate.com</option>
                                                        </select>
                                                        <input type="text" className="domain_input" id="domain_input" 
                                                            value={(form.domain === 'dir_input' ? form.domain_input : form.domain)}  
                                                            onChange={on_domain_input_handler} 
                                                            disabled={(form.domain === 'dir_input' ? '' : 'disabled')} 
                                                        />
                                                    </div>
                                                
                                                    <button type="button" className="list_btn" onClick={FN_SNS_SEND}>인증번호 전송</button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }


                                <tr>
                                    <th>인증번호</th>
                                    <td>
                                        <div className="button_row flex_box">
                                            <input className="w110" type="text" placeholder="인증번호" name="certNumber" id="certNumber" 
                                            value={certNumber}  
                                            onChange={onCertNumberHandler} 
                                            />
                                            <button type="button" className="list_btn" onClick={Fn_Sns_Number_Check}>확인</button>
                                        </div>
                                    </td>
                                </tr>
                            

                            </tbody>
                        </table>
                        <div className="btn_box" style={{"textAlign":"center"}}>
                            <button type="submit" className="blue btn_st_02" onClick={Fn_Sns_Proc}>등록</button>
                            
                        </div>	
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetupLoginPopup