
import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../views/include/Paging";


/*
회원등급(1:본사:SiteAdmin, 2:지사, 3:총판, 4:대리점:Agency, 가맹점: 5)
*/
const NoticeList =({user_level, user_site})=>{


    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('subject');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');

    // PG idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    const qs = queryString.parse(window.location.search);
    
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;

    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
                
        navigate('/'+user_site+'/'+(user_level == 5 ? '_management':'_manage')+'/NoticeList?searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        
    }
    
    // 전체 수량 구하는 
    function getTotalCnt(){

        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates,
            user_level: user_level,            
            code: (query.code === undefined || query.code === '' ? 'notice':query.code)
        }

        axios.post('/api/manage/NoticeTotalList', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }

    const Fn_Del =(idx)=>{

        if( ! window.confirm("정말 삭제하시겠습니까?")){
            return false;
        }

        let body = {
            idx: idx
        }

        axios.post('/api/manage/NoticeDel', body)
        .then(function(response){
            if(response.data === "DelOk"){    
                window.location.reload();
            }else{
                alert("시스템 에러");    
            }
        },[])

    }

    const Board = ({
        idx,
        nums,
        code,
        subject,
        user_id,
        regdate
    }) => {
        regdate = regdate.substr(0,10);
        return (

            <tr>
                <td>{nums}</td>
                <td>{regdate}</td>
                <td style={{textAlign:'left'}}><Link to={`/${user_site}/${(user_level == 5 ? '_management':'_manage')}/NoticeView?idx=`+idx} >{subject}</Link></td>
                <td>{user_id}</td>

                {(user_level == 1) && (
                    <td>
                        <div className="btn_wrap">
                            <Link to={`/${user_site}/_manage/NoticeForm?idx=`+idx} className="btn_st_02 blue_line" >수정</Link>
                            <button className="btn_st_02 blue_bg" onClick={()=>{Fn_Del(idx)}} >삭제</button>
                        </div>
                    </td>
                )}

                {(user_level != 1) && (
                    <td></td>
                )}
            </tr>
            
        );
    };

    useEffect(() => {

        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

        getTotalCnt();
        
    },[sWord, sCate, sPg, sParent_idx,  page]);
    
    
    useEffect(()=>{

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx,
            user_level: user_level,            
            code: (query.code === undefined || query.code === '' ? 'notice':query.code)
        }
    
        axios.post('/api/manage/NoticeList', bodyPage).then(function(res){
            setViewContent(res.data);
        }) 

    },[sWord, sCate, sParent_idx, sPg]); 


    return(
        <>
            <div id="container" data-menu-page="setup_admin">
                <h3 className="page_ttl">
                    {(query.code === '' || query.code === undefined || query.code === 'notice') && ('공지사항')}
                </h3>
                <form onSubmit={onSubmitSearch} >
                    <div className="top_filter">

                        <div className="sel_box">
                            <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                                <option value="subject">제목</option>
                                <option value="contents">내용</option>
                            </select>
                        </div>

                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" value={searchWord} onChange={onSearchWord} placeholder='검색해주세요' />
                            <button type="submit" className="btn_st_02 blue search">검색</button>
                        </div>

                        {(user_level == 1) && (
                            <div className="btn_box">
                                <Link to={"/"+user_site+"/_manage/NoticeForm"} className="btn_st_02 blue">등록</Link>
                            </div>
                        )}
                        
                    </div>
                </form>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
        

                    </div>
                    <div className="table_box">


                        <table>

                            <thead>

                                <tr>
                                    <th style={{width:'70px'}}>No</th>
                                    <th style={{width:'200px'}}>등록일자</th>
                                    <th>제목</th>
                                    <th style={{width:'200px'}}>등록자</th>
                                   
                                    {(user_level != 5) && (
                                        <th>-</th>
                                    )}
                                    
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                regdate={v.regdate}
                                                subject={v.subject}
                                                user_id={v.user_id}                                              
                                            />
                                        );
                                    })
                                }
                                

                            </tbody>
                        </table>
                    </div>

                    {
                        viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>
            </div>
        </>
    )
}

export default NoticeList