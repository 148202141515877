import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate } from "react-router-dom";
import '../../../assest/css/adm_login.css';
import PagingNew from "../../views/include/PagingNew";
import queryString from 'query-string';
import jquery from 'jquery';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {maskingFunc} from "../../views/include/ConfigSet_masking";

const StoreList =({pay_type})=>{

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('branch_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    // 상위 지사리스트
    const [managerList, setManagerList] = useState([]);

    // 지사 idx 처리
    const [parent_idx1, set_parent_idx1] = useState("");

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
    }
    

    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'branch_name';
    $("#searchCates").val(sCate);


    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    

    const page = qs.pg? qs.pg : 1;

    let corp_class_txt = '';
    let contract_status_txt = '';
    let login_able_txt = '';
    let calculate_txt = '';
    
    function getManagerList(){
        
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list',bodyPage).then(function(res){            
            
            setManagerList(res.data);
            
        })
    }

    function rendManagerList(){
        return (
            <select name="parent_idx1" id="parent_idx1" onChange={on_set_parent_idx1_handler} value={parent_idx1}  >
                <option value=''>지사</option>
            {
                managerList.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }


    
    
    // 상위지사 내역 가져오기
    useEffect(() => {
        getManagerList()
    },[])

    


    /**
     * 
     * @param {{nums:number,regdate:string}} nums 게시판 키값
     * @param {*} regdate 등록일자
     * @param {*} branch_name 지사명
     * @param {string} representative 대표자명
     * @param {string} user_id ID
     * @param {string} corp_class 구분
     * @param {string} calculate 정산Type
     * @param {string} commission 제공수수료
     * @param {string} contract_status 상태
     * @param {string} login_able 로그인 허용여부
     * @returns 
     */
     const Board = ({
        idx,
        nums,
        branch_name,
        parent_branch_name,
        parent_distributor_name,
        parent_agency_name,
        representative,
        user_id,
        bank,
        account_num,
        account_holder,
        corp_class,
        corp_sector,
        k1,
        k2,
        k3,
        commission,
        key1,
        income,
        contract_status,
        regdate
    }) => {
        let corp_class_txt = '';

        if(corp_class === "corp" ){
            corp_class_txt= '법인사업자';
        }else if(corp_class === "solo" ){
            corp_class_txt= '개인사업자';
        }else if(corp_class === "non" ){
            corp_class_txt= '비사업자';
        }


        let k_txt = '';

        if( k1 !== "" && k1 !== undefined){
            k_txt += ' [' + k1+']';
        }

        if( k2 !== "" && k2 !== undefined){
            k_txt += ' [' + k2+']';
        }

        if( k3 !== "" && k3 !== undefined){
            k_txt += ' [' + k3+']';
        }


        let contract_status_txt = '';

        // 계약상태
        if(contract_status === "Y"){
            contract_status_txt = '정상';

        }else if(contract_status === "F"){
            contract_status_txt = '해지';

        }else if(contract_status === "A"){
            contract_status_txt = '신청';

        }else{
            contract_status_txt = '';
        }

        regdate = regdate.substr(0,10);
        
        return (
            <tr>
                <td>{nums}</td>
                <td>{idx}</td>
                <td>{regdate}</td>
                <td>{parent_branch_name}</td>
                <td>{parent_distributor_name}</td>
                <td><Link to={`/SiteAdmin/_management/Store${pay_type}Mod?idx=`+idx} className="blue view_edit">{branch_name}</Link></td>
                <td>{representative}</td>
                <td>{user_id}</td>
                <td>{bank}</td>
                <td>{maskingFunc.account(account_num)}</td>
                <td>{maskingFunc.name(account_holder)}</td>
                <td>{corp_class_txt}</td>
                <td>{corp_sector}</td>
                <td>{key1}</td>
                <td>{k_txt}</td>
                <td>{commission}%</td>
                <td>D+{income}</td>
                <td>{contract_status_txt} </td>
            </tr>
        );
    };



    // 전체 수량 구하는 
    function getTotalCnt(){
        //console.log("getTotalCnt Start");
        let bodyQuery = {
            searchWord: sWord,
            searchCates: sCate,
            parent_idx: q_parent_idx1,
            pay_type: pay_type
        }
        //console.log("bodyQuery : " + bodyQuery);

        axios.post('/api/management/store_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }

    

    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        getTotalCnt();

        if(q_parent_idx1 !== ""){
            set_parent_idx1(q_parent_idx1);
        }
    },[sWord, sCate]);
    /*
    pg, sWord
    */

  
    useEffect(()=>{
        /*
        console.log("num 재확인 시작");
        console.log("nums : " + nums);
        console.log("num 재확인 끝");
        */

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: sWord,
            searchCates: sCate,
            parent_idx: q_parent_idx1,
            pay_type: pay_type
        }
        //console.log("bodyPage : " + bodyPage);
        
        axios.post('/api/management/store_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)
        }) 
        
    },[sWord, sCate, page]);
    // nums
    

    const fn_submit = (e) => {
        $("#pg").val("1");
        //e.preventDefault();
    }

    return (
        <>

            <div id="container" data-menu-page="sub_store">
                <h3 className="page_ttl">가맹점 관리</h3>

                <form name="" id="" action={`./Store${pay_type}List`} method="get" onSubmit={ fn_submit } >
                    <input type="hidden" name="pg" id="pg" value={page} />

                    <div className="top_filter">

                        <div className="sel_box">
                            {
                                rendManagerList()
                            }
                        </div>

                        <div className="sel_box">
                            <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                                <option value="branch_name">가맹점명</option>
                                <option value="user_id">로그인ID</option>
                                <option value="representative">대표자명</option>
                                <option value="account_num">계좌번호</option>
                                <option value="parent_branch_name">지사명</option>
                                <option value="parent_distributor_name">총판명</option>
                                <option value="parent_agency_name">대리점명</option>
                            </select>
                        </div>

                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" placeholder='검색해주세요'/>
                            <button  type="submit" className="btn_st_02 search">검색</button>
                        </div>


                        <div className="btn_box">
                            <Link to={`/SiteAdmin/_management/Store${pay_type}Form`} className="btn_st_02 blue ">가맹점 등록</Link>
                        </div>

                    </div>
                </form>



                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>

                        <div className="right">
                            
                        </div>


                    </div>
                    <div className="table_box">

                        <table>

                            <thead>
                            
                                <tr>
                                    <th>No</th>
                                    <th>코드</th>
                                    <th>등록일</th>
                                    <th>지사</th>
                                    <th>총판</th>
                                    <th>가맹점명</th>
                                    <th>대표자</th>
                                    <th>아이디</th>
                                    <th>은행</th>
                                    <th>계좌번호</th>
                                    <th>예금주</th>
                                    <th>구분</th>
                                    <th>종목</th>
                                    <th>PG종류</th>
                                    <th>수기결제</th>
                                    <th colSpan="2">수기결제조건</th>
                                    <th>상태</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                parent_branch_name={v.parent_branch_name}
                                                parent_distributor_name={v.parent_distributor_name}
                                                parent_agency_name={v.parent_agency_name}
                                                branch_name={v.branch_name}
                                                representative={v.representative}
                                                user_id={v.user_id}
                                                bank={v.bank}
                                                account_num={v.account_num}
                                                account_holder={v.account_holder}
                                                corp_class={v.corp_class}
                                                corp_sector={v.corp_sector}
                                                k1={v.k1}
                                                k2={v.k2}
                                                k3={v.k3}
                                                commission={v.commission}
                                                key1={v.key1}
                                                income={v.income}
                                                contract_status={v.contract_status}
                                                regdate={v.regdate}
                                            />
                                        );
                                    })
                                }
                            </tbody>

                        </table>
                    </div>

                    {
                        viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>

            </div>
                            
        </>
    )


}
export default StoreList