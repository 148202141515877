import * as axios from "axios";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../../../assest/css/reset.css";
import "../../../assest/css/layout.css";
import logo_02 from "../../../assest/img/common/logo_02.png";
import TimerBox from "../../page/timerBox/Index";
import { fetchWithCallback } from "../../../util/functionHelper";

function ComonHeaderDistributor() {
  const cookie_maxage = process.env.REACT_APP_COOKIE_MAXAGE;
  const [loginTime, setLoginTime] = useState({
    hour: parseInt(cookie_maxage / 60),
    min: parseInt(cookie_maxage % 60),
    sec: 0,
  });

  const navigate = useNavigate();
  const [menu_on, set_menu_on] = useState("");
  const [menu_sub_on, set_menu_sub_on] = useState("");
  const sampleLocation = useLocation();

  const urls = sampleLocation.pathname;

  const [user_name, set_user_name] = useState("");
  const [last_login, set_last_login] = useState("");
  const [my_ip, set_my_ip] = useState("");

  const [DS_MODE, set_DS_MODE] = useState("N");
  const [main_type, set_main_type] = useState("");

  // 로그인 날짜
  function converToLocalTime(serverDate) {
    var date = new Date(Date.parse(serverDate));
    const TIME_ZONE = 3240 * 10000;
    return new Date(+date + TIME_ZONE)
      .toISOString()
      .replace("T", " ")
      .replace(/\..*/, "")
      .substring(0, 16);
  }

  // 내 아이피
  const Fn_My_Ip = async () => {
    fetchWithCallback("https://geolocation-db.com/json//", (locationIp) => {
      set_my_ip(locationIp.IPv4);
    });
  };

  useEffect(() => {
    Fn_My_Ip();

    const loginId = sessionStorage.getItem("loginId");

    if (loginId === null) {
      navigate("/Distributor/");
      return;
    }

    let bodyPage = {
      loginId: loginId,
    };

    axios
      .post("/api/distributor/userInfo", bodyPage)
      .then(function (res) {
        console.log("res : ", res);

        if (res.status === 401 || res.data.length === 0) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/Distributor/");
          return;
        }

        res.data.map((v, index) => {
          set_user_name(v.username);
          set_last_login(v.last_login);
        });
      })
      .catch((error) => {
        console.log("err : ", error);

        //if(error.response.status === 401){
        alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
        navigate("/Distributor/");
        return;
        //}
      });
  }, []);

  useEffect(() => {
    // 거래조회 #####
    if (urls.includes("/Distributor/_pay/") === true) {
      set_menu_on("Main1");
      sessionStorage.setItem("menu_on", "Main1");

      // 거래내역
      if (
        urls === "/Distributor/_pay/transactionHistoryList" ||
        urls === "/SiteAdmin/_pay/transactionHistoryView"
      ) {
        set_menu_sub_on("Sub11");
      }
      // 거래거절내역
      if (urls === "/Distributor/_pay/payErrorList") {
        set_menu_sub_on("Sub12");
      }
      // 업체관리 - 차감내역
      if (urls === "/Distributor/_pay/payDeduction") {
        set_menu_sub_on("Sub13");
      }
      // 업체관리 - 정산보류내역
      if (
        urls === "/Distributor/_pay/pay_reserve" ||
        urls === "/Distributor/_pay/pay_reserve_view"
      ) {
        set_menu_sub_on("Sub14");
      }
      // 매출집계
      if (urls === "/Distributor/_pay/pay_total") {
        set_menu_sub_on("Sub15");
      }
    }
    // 거래조회 #####//

    // 거래조회 터미널 #####
    if (urls.includes("/Distributor/_pay_terminal/") === true) {
      set_menu_on("Main1_1");
      sessionStorage.setItem("menu_on", "Main1_1");

      // 거래내역
      if (
        urls === "/Distributor/_pay_terminal/transactionHistoryList" ||
        urls === "/SiteAdmin/_pay_terminal/transactionHistoryView"
      ) {
        set_menu_sub_on("Sub11_1");
      }
      // 거래거절내역
      if (urls === "/Distributor/_pay_terminal/payErrorList") {
        set_menu_sub_on("Sub12_1");
      }
      // 업체관리 - 차감내역
      if (urls === "/Distributor/_pay_terminal/payDeduction") {
        set_menu_sub_on("Sub13_1");
      }
      // 업체관리 - 정산보류내역
      if (
        urls === "/Distributor/_pay_terminal/pay_reserve" ||
        urls === "/Distributor/_pay_terminal/pay_reserve_view"
      ) {
        set_menu_sub_on("Sub14_1");
      }
      // 매출집계
      if (urls === "/Distributor/_pay/pay_total") {
        set_menu_sub_on("Sub15_1");
      }
    }
    // 거래조회 #####//

    // 정산관리 #####
    if (urls.includes("/Distributor/_settlement/") === true) {
      set_menu_on("Main2");
      sessionStorage.setItem("menu_on", "Main2");
      // 가맹점 정산
      if (urls === "/Distributor/_settlement/calculate_calendar") {
        set_menu_sub_on("Sub21");
      }
      // 가맹점 정산
      if (urls === "/Distributor/_settlement/store") {
        set_menu_sub_on("Sub16");
      }
      // 가맹점 정산결과
      if (urls === "/Distributor/_settlement/store_result") {
        set_menu_sub_on("Sub17");
      }
      // 대리점 정산
      if (urls === "/Distributor/_settlement/ag") {
        set_menu_sub_on("Sub22");
      }
      // 대리점 정산결과
      if (urls === "/Distributor/_settlement/ag_result") {
        set_menu_sub_on("Sub23");
      }
    }
    // 정산관리 #####//

    // 정산관리 터미널 #####
    if (urls.includes("/Distributor/_settlement_terminal/") === true) {
      set_menu_on("Main2_1");
      sessionStorage.setItem("menu_on", "Main2_1");
      // 가맹점 정산
      if (urls === "/Distributor/_settlement_terminal/calculate_calendar") {
        set_menu_sub_on("Sub21_1");
      }
      // 가맹점 정산
      if (urls === "/Distributor/_settlement_terminal/store") {
        set_menu_sub_on("Sub16_1");
      }
      // 가맹점 정산결과
      if (urls === "/Distributor/_settlement_terminal/store_result") {
        set_menu_sub_on("Sub17_1");
      }
      // 대리점 정산
      if (urls === "/Distributor/_settlement_terminal/ag") {
        set_menu_sub_on("Sub22_1");
      }
      // 대리점 정산결과
      if (urls === "/Distributor/_settlement_terminal/ag_result") {
        set_menu_sub_on("Sub23_1");
      }
    }
    // 정산관리 #####//

    // 업체관리 #####
    if (urls.includes("Distributor/_management/") === true) {
      set_menu_on("Main3");
      sessionStorage.setItem("menu_on", "Main3");

      // 업체관리 - 대리점관리
      if (urls.includes(`/Distributor/_management/Agency`) === true) {
        set_menu_sub_on("Sub33");
      }

      // 업체관리 - 가맹점 관리
      if (urls.includes(`/Distributor/_management/Store`) === true) {
        set_menu_sub_on("Sub34");
      }
    }

    // 터미널관리 #####
    if (urls.includes(`/Distributor/_terminal/`) === true) {
      set_menu_on("Main4");
      sessionStorage.setItem("menu_on", "Main4");

      if (urls === `/Distributor/_terminal/DirectPaymentList`) {
        set_menu_sub_on("Sub42");
      } else if (urls === `/Distributor/_terminal/DirectPaymentForm`) {
        set_menu_sub_on("Sub42");
      } else if (urls === `/Distributor/_terminal/DirectPaymentMod`) {
        set_menu_sub_on("Sub42");
      }
    }

    // 실적조회 #####
    if (urls.includes(`/Distributor/_result/`) === true) {
      set_menu_on("Main5");
    }

    // 운영관리 #####
    if (urls.includes(`/Distributor/_manage/`) === true) {
      set_menu_on("Main6");

      sessionStorage.setItem("menu_on", "Main6");

      if (urls === `/Distributor/_manage/ManageLog`) {
        set_menu_sub_on("Sub61");
      }
      if (urls.includes(`/Distributor/_manage/Notice`) === true) {
        set_menu_sub_on("Sub62");
      }
      if (urls.includes("/Distributor/_manage/Notice") === true) {
        set_menu_sub_on("Sub63");
      }
      if (urls.includes("/Distributor/_manage/Faq") === true) {
        set_menu_sub_on("Sub70");
      }
      if (urls.includes("/Distributor/_manage/Qna") === true) {
        set_menu_sub_on("Sub71");
      }
    }

    // 정보변경 #####
    if (urls.includes(`/Distributor/_admin/`) === true) {
      set_menu_on("Main7");
      sessionStorage.setItem("menu_on", "Main7");
    }

    if (urls.includes("/_main/main_terminal") === true) {
      set_main_type("terminal");
    } else {
      set_main_type("");
    }

    if (urls.includes("/_main/main") === true) {
      set_DS_MODE("Y");
    }
  }, [urls]);

  //메뉴 활성화 =================================
  const session_menu_on = sessionStorage.getItem("menu_on");
  useEffect(() => {
    set_menu_on(session_menu_on);
  }, [session_menu_on]);
  //메뉴 활성화 =================================//

  // 로그아웃
  const onLogOut = () => {
    axios.post("/api/logout").then(function (res) {
      if (res.status === 200) {
        sessionStorage.removeItem("loginId");
        navigate("/Distributor/");
      }
    });
  };

  //연길 시간 종료
  const onEndTime = () => {
    axios.post("/api/logout").then(function (res) {
      if (res.status === 200) {
        sessionStorage.removeItem("loginId");
        navigate("/Distributor/");
      }
    });
  };

  //시간 연장
  const onReStartTime = () => {
    const loginId = sessionStorage.getItem("loginId");
    let bodyPage = {
      loginId: loginId,
    };
    axios
      .post("/api/Distributor/userInfo", bodyPage)
      .then(function (res) {
        if (res.status === 401 || res.data.length === 0) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/Distributor/");
          return;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/Distributor/");
          return;
        }
      });
  };

  const Fn_Main = (loc) => {
    navigate(loc);
  };

  return (
    <>
      <header id="header">
        <div className="left_wrap">
          <div className="left">
            <h1 className="logo">
              <Link to="/Distributor/_main/main">
                <img src={logo_02} alt="" />
              </Link>
            </h1>
          </div>

          {DS_MODE === "Y" && (
            <>
              <button
                type="button"
                onClick={() => {
                  Fn_Main("/Distributor/_main/main");
                }}
                className={main_type === "" ? "active" : ""}
              >
                수기정산
              </button>
              <button
                type="button"
                onClick={() => {
                  Fn_Main("/Distributor/_main/main_terminal");
                }}
                className={main_type === "terminal" ? "active" : ""}
              >
                터미널정산
              </button>
            </>
          )}
        </div>

        <div className="right">
          <div className="right_item">
            <div>
              <span>
                {user_name} ( {my_ip} )
              </span>
              <span>
                접속일시 : {last_login !== "" && converToLocalTime(last_login)}
              </span>
            </div>
            <TimerBox
              hour={loginTime.hour}
              min={loginTime.min}
              sec={loginTime.sec}
              onEndTimeEvent={onEndTime}
              onReStartTimeEvent={onReStartTime}
            />
            <a href="#" className="logout" onClick={onLogOut}>
              <i></i>로그아웃
            </a>
          </div>
        </div>
      </header>

      <nav id="nav">
        <div className="sub_menu_wrap">
          <div
            className={
              menu_on === "Main1" ? "sub_menu menu_1 on" : "sub_menu menu_1"
            }
            onClick={() => {
              set_menu_on("Main1");
            }}
          >
            <button className="label">
              거래조회<i></i>
            </button>
            <ul
              className={
                menu_on === "Main1" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="pay_history"
                className={menu_sub_on === "Sub11" ? "on" : null}
              >
                {" "}
                <Link
                  to="/Distributor/_pay/transactionHistoryList"
                  className="dep_2"
                >
                  거래내역
                </Link>
              </li>
              <li
                data-menu="pay_error"
                className={menu_sub_on === "Sub12" ? "on" : null}
              >
                <Link to="/Distributor/_pay/payErrorList" className="dep_2">
                  거래거절(실패)내역
                </Link>
              </li>
              <li
                data-menu="pay_reserve"
                className={menu_sub_on === "Sub14" ? "on" : null}
              >
                <Link to="/Distributor/_pay/pay_reserve" className="dep_2">
                  정산보류내역
                </Link>
              </li>
              <li
                data-menu="pay_total"
                className={menu_sub_on === "Sub15" ? "on" : null}
              >
                <Link to="/Distributor/_pay/pay_total" className="dep_2">
                  매출집계
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={
              menu_on === "Main2" ? "sub_menu menu_2 on" : "sub_menu menu_2"
            }
            onClick={() => {
              set_menu_on("Main2");
            }}
          >
            <button className="label">
              정산관리<i></i>
            </button>
            <ul
              className={
                menu_on === "Main2" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="set_store"
                className={menu_sub_on === "Sub16" ? "on" : null}
              >
                <Link to="/Distributor/_settlement/store" className="dep_2">
                  가맹점 정산
                </Link>
              </li>
              <li
                data-menu="set_result"
                className={menu_sub_on === "Sub17" ? "on" : null}
              >
                <Link
                  to="/Distributor/_settlement/store_result"
                  className="dep_2"
                >
                  가맹점 정산 결과
                </Link>
              </li>
              <li
                data-menu="set_ag"
                className={menu_sub_on === "Sub22" ? "on" : null}
              >
                <Link to="/Distributor/_settlement/ag" className="dep_2">
                  대리점 정산
                </Link>
              </li>
              <li
                data-menu="set_ag_result"
                className={menu_sub_on === "Sub23" ? "on" : null}
              >
                <Link to="/Distributor/_settlement/ag_result" className="dep_2">
                  대리점 정산 결과
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={
              menu_on === "Main1_1" ? "sub_menu menu_1 on" : "sub_menu menu_1"
            }
            onClick={() => {
              set_menu_on("Main1_1");
            }}
          >
            <button className="label">
              거래조회(터미널)<i></i>
            </button>
            <ul
              className={
                menu_on === "Main1_1" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="pay_history"
                className={menu_sub_on === "Sub11_1" ? "on" : null}
              >
                {" "}
                <Link
                  to="/Distributor/_pay_terminal/transactionHistoryList"
                  className="dep_2"
                >
                  거래내역
                </Link>
              </li>
              {/* <li  data-menu="pay_error" className={menu_sub_on==='Sub12_1'?'on':null}><Link to="/Distributor/_pay_terminal/payErrorList" className="dep_2">거래거절(실패)내역</Link></li> */}
              <li
                data-menu="pay_reserve"
                className={menu_sub_on === "Sub14_1" ? "on" : null}
              >
                <Link
                  to="/Distributor/_pay_terminal/pay_reserve"
                  className="dep_2"
                >
                  정산보류내역
                </Link>
              </li>
              <li
                data-menu="pay_total"
                className={menu_sub_on === "Sub15_1" ? "on" : null}
              >
                <Link
                  to="/Distributor/_pay_terminal/pay_total"
                  className="dep_2"
                >
                  매출집계
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={
              menu_on === "Main2_1" ? "sub_menu menu_2 on" : "sub_menu menu_2"
            }
            onClick={() => {
              set_menu_on("Main2_1");
            }}
          >
            <button className="label">
              정산관리(터미널)<i></i>
            </button>
            <ul
              className={
                menu_on === "Main2_1" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="set_store"
                className={menu_sub_on === "Sub16_1" ? "on" : null}
              >
                <Link
                  to="/Distributor/_settlement_terminal/store"
                  className="dep_2"
                >
                  가맹점 정산
                </Link>
              </li>
              <li
                data-menu="set_result"
                className={menu_sub_on === "Sub17_1" ? "on" : null}
              >
                <Link
                  to="/Distributor/_settlement_terminal/store_result"
                  className="dep_2"
                >
                  가맹점 정산 결과
                </Link>
              </li>
              <li
                data-menu="set_ag"
                className={menu_sub_on === "Sub22_1" ? "on" : null}
              >
                <Link
                  to="/Distributor/_settlement_terminal/ag"
                  className="dep_2"
                >
                  대리점 정산
                </Link>
              </li>
              <li
                data-menu="set_ag_result"
                className={menu_sub_on === "Sub23_1" ? "on" : null}
              >
                <Link
                  to="/Distributor/_settlement_terminal/ag_result"
                  className="dep_2"
                >
                  대리점 정산 결과
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={
              menu_on === "Main3" ? "sub_menu menu_3 on" : "sub_menu menu_3"
            }
            onClick={() => {
              set_menu_on("Main3");
            }}
          >
            <button className="label">
              업체관리<i></i>
            </button>
            <ul
              className={
                menu_on === "Main3" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="agency_list"
                className={menu_sub_on === "Sub33" ? "on" : null}
              >
                <Link
                  to={"/Distributor/_management/AgencyList"}
                  className="dep_2"
                >
                  대리점관리
                </Link>
              </li>

              <li
                data-menu="sub_store"
                className={menu_sub_on === "Sub34" ? "on" : null}
              >
                <Link
                  to={"/Distributor/_management/StoreList"}
                  className="dep_2"
                >
                  가맹점관리
                </Link>
              </li>
            </ul>
          </div>

          {/* <div className={menu_on==='Main5'?'sub_menu menu_5 on':'sub_menu menu_5'} onClick={()=> { set_menu_on('Main5') }} >
                        <button className="label">실적조회<i></i></button>
                        <ul className={menu_on==='Main5'?'sub_menu_item open':'sub_menu_item'}>
                            <li data-menu="company_acc_tax"><a href="../_result/company_acc_tax.php" className="dep_2"> 매출계산서(사업자) </a></li>
                            <li data-menu="person_acc_tax"><a href="../_result/person_acc_tax.php" className="dep_2">매출계산서(비사업자)  </a></li>
                            <li data-menu="agent_acc_tax"><a href="../_result/agent_acc_tax.php" className="dep_2">에이전트계산서  </a></li>
                            <li data-menu="month_tax"><a href="../_result/month_tax.php" className="dep_2">세금계산서마감 </a></li>
                        </ul>
                    </div> */}

          <div
            className={
              menu_on === "Main6" ? "sub_menu menu_6 on" : "sub_menu menu_6"
            }
            onClick={() => {
              set_menu_on("Main6");
            }}
          >
            <button className="label">
              운영관리<i></i>
            </button>
            <ul
              className={
                menu_on === "Main6" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="notice"
                className={menu_sub_on === "Sub63" ? "on" : null}
              >
                <Link to="/Distributor/_manage/NoticeList" className="dep_2">
                  공지사항
                </Link>
              </li>
              <li
                data-menu="faq"
                className={menu_sub_on === "Sub70" ? "on" : null}
              >
                <Link to="/Distributor/_manage/FaqList" className="dep_2">
                  자주하는 질문
                </Link>
              </li>
              <li
                data-menu="qna"
                className={menu_sub_on === "Sub71" ? "on" : null}
              >
                <Link to="/Distributor/_manage/QnaList" className="dep_2">
                  1대1 문의
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={
              menu_on === "Main7" ? "sub_menu menu_7 on" : "sub_menu menu_7"
            }
            onClick={() => {
              set_menu_on("Main7");
            }}
          >
            <button className="label">
              정보변경<i></i>
            </button>
            <ul
              className={
                menu_on === "Main7" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li data-menu="setting">
                <Link to={"/Distributor/_admin/Setting"} className="dep_2">
                  정보변경
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default ComonHeaderDistributor;
