import * as axios from 'axios';
import React, {useEffect} from 'react'
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import StoreList from "../../../../page/store/List";

function SiteAdminManagementStoreList() {
            
    useEffect(() => {
      
    },[])


    return (
        <>
        <div id="wrap">
            <Header/>

            
            <StoreList pay_type={""}/>

        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminManagementStoreList