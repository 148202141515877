import React from 'react'
import ManageLogList from "../../../../page/manageLog/List";
import Header from "../../../include/DistributorHeader";
import Footer from "../../../include/SiteAdminFooter";

function DistributorManageManageLog() {
    

    return(
    <>
        <div id="wrap">
            <Header/>
            
            <ManageLogList user_level={3} />

        </div>

        <Footer/>        
    </>
    )
}

export default DistributorManageManageLog