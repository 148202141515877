import * as axios from "axios";

export const fetchWithCallback = async (url, callback) => {
  try {
    const response = await fetch(url);
    const data = await response.json();
    callback(data);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const axiosPostWithCallback = async (url, bodyQuery, callback) => {
  try {
    const response = await axios.post(url, bodyQuery);
    callback(response.data);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
