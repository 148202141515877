import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";
import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';
import HeaderMo from "../../../include/StoreHeaderM";

import queryString from 'query-string';
import PropTypes from 'prop-types';
import jquery from 'jquery';
import $ from 'jquery';


function StoreManagementPayFormStep1() {

    const navigate = useNavigate();
    const location = useLocation();

    
    

    
    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    


    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('goods_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    const qs = queryString.parse(window.location.search);


    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }


    

    const [limitDay, set_limitDay] = useState("");

    const [limitChk, set_limitChk] = useState("");

    const [reg_id, set_reg_id] = useState("");
    
    const [goods_name, set_goods_name] = useState("");

    const on_goods_name_handler = (event) => {
        set_goods_name(event.currentTarget.value)
    }


    const [customer_name, set_customer_name] = useState("");

    const on_customer_name_handler = (event) => {
        set_customer_name(event.currentTarget.value)
    }


    const [customer_hp, set_customer_hp] = useState("");

    const on_customer_hp_handler = (event) => {
        set_customer_hp(event.currentTarget.value)
    }


    const [price, set_price] = useState("");
    
    const on_price_handler = (event) => {
        set_price(event.currentTarget.value)
    }


    const [memo, set_memo] = useState("");

    const on_memo_handler = (evnet) => {
        set_memo(evnet.currentTarget.value)
    }


    /*
    판매자 정보 관련
    */

    const [sellerName, set_sellerName] = useState("");
    const [sellerHp, set_sellerHp] = useState("");
    const [sellerAddr, set_sellerAddr] = useState("");



    useEffect(() => {

        //console.log(sIdx);
           
        let bodyPage = {
            idx: sIdx
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/store/management/goodsModChk', bodyPage).then(function(res){

            if(res.data.length < 1){
                alert("이미 결제되었거나, 주문 가능한 일자가 초과되었습니다.");
                window.close();
            }
            
            res.data.map((v, index) => {
                set_goods_name(v.goods_name);
                set_customer_name(v.customer_name);
                set_customer_hp(v.customer_hp);
                set_price(v.price);
                set_memo(v.memo);
                set_reg_id(v.reg_id);
                set_limitDay(v.limitDay);
                set_limitChk(v.limitChk);

                //let lastNum = v.customer_hp.slice(-3,v.customer_hp);

            
                //alert(v.reg_id);

                let bodyPage2 = {
                    loginId: v.reg_id
                }
        
                axios.post('/api/store/userInfo2', bodyPage2).then(function(response){
                    console.log(response);
                    // 등록자 ID
                    set_sellerName(response.data[0].branch_name);
                    set_sellerHp(response.data[0].user_phone);
                    set_sellerAddr(response.data[0].corp_addr);
                });
                
            
            })
            
        }) 


           
    }, []);

    function pays(){
        let lastNum = customer_hp.slice(-4);
        if( $("#chkPhone").val() == ""){
            alert("본인 확인을 위해서 휴대폰 뒷자리 4자리를 입력해주세요.");
            $("#chkPhone").focus();
            return false;
        }

        if( lastNum != $("#chkPhone").val()){
            alert("인증번호가 일치하지 않습니다.");
            $("#chkPhone").focus();
            return false;
        }
        navigate('/Store/_management/payFormStep2?idx='+sIdx);
        
    }

    return(
        <>
            <div id="wrap_mo">
                <HeaderMo />

                <div id="container" data-menu-page="goods" className="write_form">  
                    <h3 className='mo_page_ttl'>간편결제 주문서</h3>

                    <div className='pay_st_item'>
                        <div className='pay_totoal'>
                            <h3 className="label">결제금액 (원)</h3>
                            <p><strong>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>원</p>
                        </div>
                    </div>

                    <div className='pay_st_item'>
                        <h3 className="label">주문자 정보</h3>
                        <div className='pay_st_list'>
                            <dl>
                                <dt>판매자명</dt>
                                <dd> 
                                    <input type="text" value={sellerName} disabled />
                                </dd>
                            </dl>
                            <dl>
                                <dt>휴대폰</dt>
                                <dd> 
                                    <input type="text" value={sellerHp} disabled />
                                </dd>
                            </dl>
                            <dl>
                                <dt>주소</dt>
                                <dd> 
                                    <input type="text" value={sellerAddr} disabled />
                                </dd>
                            </dl>
                            <dl>
                                <dt>상품명</dt>
                                <dd> 
                                    <input type="text" value={goods_name} disabled />
                                </dd>
                            </dl>
                            <dl>
                                <dt>휴대폰 번호 뒷자리(4자리) <span className="blue">＊</span></dt>
                                <dd> 
                                    <input type="text"  id="chkPhone" />
                                </dd>
                            </dl>
                        </div>
                    </div>

            



                    <div className="mo_page_btn">
                        { limitChk == "T"  ? 
                        <button className="  btn_st_03" onClick={pays}>결제진행하기</button>
                        :
                        null
                        }
                    </div>

                </div>

            </div>
        </>

    )
}
export default StoreManagementPayFormStep1