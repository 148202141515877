import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';


import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';

import Header from "../../../include/StoreHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";

import {PgCardName, PgName} from "../../../include/ConfigSet";


function StorePayTransactionHistoryForm() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }

    const [pg_group, set_pg_group] = useState("");
    const [orderno, set_orderno] = useState("");
    const [authdate, set_authdate] = useState("");
    const [authno, set_authno] = useState("");
    const [cardcode, set_cardcode] = useState("");
    const [cardno, set_cardno] = useState("");
    const [amount, set_amount] = useState("");
    const [quota, set_quota] = useState("");
    const [productname, set_productname] = useState("");

    const on_productname_handler = (event) => {
        set_productname(event.currentTarget.value);
    }

    const [username, set_username] = useState("");

    const on_username_handler = (event) => {
        set_username(event.currentTarget.value);
    }
    
    const [userphone, set_userphone] = useState("");

    const on_userphone_handler = (event) => {
        set_userphone(event.currentTarget.value);
    }
   






    useEffect(() => {

        console.log(sIdx);

        const loginId = sessionStorage.getItem("loginId");

     

        let bodyPage = {
            loginId: loginId,
            idx:sIdx
        }

  
        
        axios.post('/api/store/pay/transaction_history_Mod', bodyPage).then(function(res){
            //console.log(res.data.map);

            res.data.map((v, index) => {
                set_pg_group(v.pg_group);
                set_orderno(v.orderno);
                set_authdate(v.authdate);
                set_authno(v.authno);
                set_cardcode(v.cardcode);
                set_cardno(v.cardno);
                set_amount(v.amount);
                set_quota(v.quota);
                set_productname(v.productname);
                set_username(v.username);
                set_userphone(v.userphone);
            })
            
        }) 
            

        
        return () => {
          
        };
    }, []);



    
    const onSubmitHandler = (event) => {
        event.preventDefault();

       

        let body = {
            idx: qs.idx,
            /*
            pg_group: pg_group,   
            memo: memo
            */
        }
        console.log(body);

        axios.post('/api/management/DistributorUpdate', body)
        .then(function(response){
            

            
            if(response.data === "ModOk"){    
                //navigate('/SiteAdmin/_management/DistributorList');
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


    }

    let card_out_no = cardno.substr(0, cardno.length-7) + "****" + cardno.substr(cardno.length-3, 3);

    let quota_txt = "";

    if(quota === "00"){
        quota_txt = "일시불";
    }else{
        quota_txt = quota;
    }
    
    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="goods" className="write_form">  
                <h3 className="page_ttl">거래내역 상세</h3>
                <form onSubmit={onSubmitHandler}>
                <div className="form_item">
                    <input type="hidden" name="idx" id="idx" value={sIdx} />

                    <div className="top_table">
                        <table>
                            <colgroup>
                                <col width="200px" />
                                <col width="*" />
                                <col width="200px" />
                                <col width="*" />
                            </colgroup>

                            <tbody>
                                <tr>
                                    <th>거래처</th>
                                    <td colSpan="3">
                                        {PgName(pg_group)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>상태</th>
                                    <td>
                                        
                                    </td>
                                    <th>거래번호</th>
                                    <td>{orderno}</td>
                                </tr>
                                <tr>
                                    <th>거래일자</th>
                                    <td>{authdate}</td>
                                    <th>승인번호</th>
                                    <td>{authno}</td>
                                </tr>
                                <tr>
                                    <th>발행사</th>
                                    <td>
                                        {PgCardName(pg_group, cardcode)}
                                    </td>
                                    <th>매입사</th>
                                    <td>
                                        {PgCardName(pg_group, cardcode)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>카드번호</th>
                                    <td>
                                        {card_out_no}
                                    </td>
                                    <th>카드구분</th>
                                    <td>신용</td>
                                </tr>

                                <tr>
                                    <th>승인금액</th>
                                    <td>
                                        {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <th>할부</th>
                                    <td>
                                        {quota_txt}
                                    </td>
                                </tr>

                                <tr>
                                    <th>상품명</th>
                                    <td colSpan="3">
                                        <input type="text" name="productname" value={productname} onChange={on_productname_handler} />
                                    </td>
                                </tr>

                                <tr>
                                    <th>주문자명</th>
                                    <td>
                                        <input type="text" name="username" value={username} onChange={on_username_handler} />
                                    </td>
                                    <th>휴대폰</th>
                                    <td>
                                        <input type="text" name="userphone" value={userphone} onChange={on_userphone_handler} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>마지막 수정일</th>
                                    <td>
                                        
                                    </td>
                                    <th>실행 ID</th>
                                    <td>
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                
                </div>

                <div className="top_table_btn">
                    <button className=" btn_st_01" onClick={  () => navigate(-1) } >목록</button>
                    <button className=" blue btn_st_01">결제정보수정</button>
                </div>

                </form>
            </div>
            
        </div>

        <Footer/>
        </>
    )
}

export default StorePayTransactionHistoryForm