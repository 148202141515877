import React, {useEffect, useState, useRef, useReducer, useCallback } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';

import ManageLogList from "../../../../page/manageLog/List";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";


function SiteAdminManageSetupLoginList() {
    

    return(
    <>
        <div id="wrap">
            <Header/>
            
            <ManageLogList user_level={1} />

        </div>

        <Footer/>        
    </>
    )
}

export default SiteAdminManageSetupLoginList