import * as axios from 'axios';
import React from 'react'
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/MbranchHeader";
import Footer from "../../../include/SiteAdminFooter";
import StoreForm from "../../../../page/store/Form";



function MbranchManagementStoreTerminalForm() {


    return (
        <>
        <div id="wrap">
            <Header/>



        </div>

        <Footer/>
        </>
    )
}

export default MbranchManagementStoreTerminalForm