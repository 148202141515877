import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../../include/Paging";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";


function SiteAdminPGList() {


    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('sname');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');

    // PG idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;

    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);        
        navigate('/SiteAdmin/_manage/_pg/list?searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        
    }
    
    // 전체 수량 구하는 
    function getTotalCnt(){

        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates
        }

        axios.post('/api/manage/pg/pg_pay_total', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }


    
    const Fn_Del =(idx)=>{

        if( ! window.confirm("정말 삭제하시겠습니까?")){
            return false;
        }

        let body = {
            idx: idx
        }

        axios.post('/api/manage/pg/pg_pay_del', body)
        .then(function(response){
            if(response.data === "DelOk"){    
                window.location.reload();
            }else{
                alert("시스템 에러");    
            }
        },[])

    }

    const Board = ({
        idx,
        nums,      
        sname,
        pg,
        cerify,
        store,
        income,
        fee,        
        mode,
        regdate
    }) => {

    
        return (

            <tr>
                <td>{nums}</td>
                <td>{sname}</td>
                <td>{pg}</td>
                {/* <td>{cerify}</td> */}
                <td>{store}</td>
                <td>{income}</td>
                <td>{fee}</td>
                <td>{mode}</td>                             
                <td>
                    <div className="btn_wrap">
                        <Link to={`/SiteAdmin/_manage/_pg/Form?idx=`+idx} className="btn_st_02 blue_line" >관리</Link>
                        <button className="btn_st_02 blue_bg" onClick={()=>{Fn_Del(idx)}} >삭제</button>
                    </div>
                </td>
            </tr>
            
        );
    };

    useEffect(() => {

        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

        getTotalCnt();
        
    },[sWord, sCate, sPg, sParent_idx,  page]);
    
    
    useEffect(()=>{

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
    
        axios.post('/api/manage/pg/pg_pay_list', bodyPage).then(function(res){
            setViewContent(res.data);
        }) 

    },[sWord, sCate, sParent_idx, sPg]); 

    return(
    <>
        <div id="wrap">
            <Header/>
            <div id="container" data-menu-page="setup_admin">
                <h3 className="page_ttl">수기 PG 관리</h3>

                <form onSubmit={onSubmitSearch} >
                    <div className="top_filter">

                        <div className="sel_box">
                            <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                                <option value="sname">관리명칭</option>
                                <option value="pg">pg사</option>
                            </select>
                        </div>

                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" value={searchWord} onChange={onSearchWord}  placeholder='검색해주세요'/>
                            <button type="submit" className="btn_st_02 blue search">검색</button>
                        </div>
                        
                        <div className="btn_box">
                            <Link to="/SiteAdmin/_manage/_pg/Form" className="btn_st_02 blue">등록</Link>                                                        
                        </div>
                    </div>
                </form>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>


                    </div>
                    <div className="table_box">


                        <table>

                            <thead>

                                <tr>
                                    <th>No</th>
                                    <th>관리명칭 </th>
                                    <th>pg사</th>
                                    {/* <th>인증방식</th> */}
                                    <th>pg상점 코드</th>                              
                                    <th>입금주기</th>
                                    <th>수수료율</th>                                    
                                    <th>사용여부</th> 
                                    <th></th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                sname={v.sname}
                                                pg={v.pg}
                                                cerify={v.cerify}
                                                store={v.store}
                                                income={v.income}
                                                fee={v.fee}
                                                mode={v.mode}
                                                regdate={v.regdate}
                                            />
                                        );
                                    })
                                }
                                

                            </tbody>
                        </table>
                    </div>

                    {
                        viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>


                

            </div>
        </div>

        <Footer/>        
    </>
    )
}
export default SiteAdminPGList