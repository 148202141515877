import React  from 'react'
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import SettingForm from "../../../../page/setting/Index";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';


function SiteAdminManageAccessIpForm() {



    return (
        <>
        <div id="wrap">
            <Header/>                  
            <SettingForm user_level={'1'} user_site={'SiteAdmin'} />            
        </div>
        <Footer/>
        </>
    )
}

export default SiteAdminManageAccessIpForm