import * as axios from "axios";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
/*
import '../../../assest/css/adm_login.css';
import '../../../assest/css/reset.css';
import '../../../assest/css/reset.css';
*/

import "../../../assest/css/datepicker.min.css";
import "../../../assest/css/reset.css";
import "../../../assest/css/layout.css";
import logo_02 from "../../../assest/img/common/logo_02.png";

import TimerBox from "../../page/timerBox/Index";
import { fetchWithCallback } from "../../../util/functionHelper";

function SiteAdminHeader() {
  const navigate = useNavigate();
  const [menu_on, set_menu_on] = useState("");
  const [menu_sub_on, set_menu_sub_on] = useState("");
  const sampleLocation = useLocation();

  const cookie_maxage = process.env.REACT_APP_COOKIE_MAXAGE;
  const [loginTime, setLoginTime] = useState({
    hour: parseInt(cookie_maxage / 60),
    min: parseInt(cookie_maxage % 60),
    sec: 0,
  });

  useEffect(() => {
    const urls = sampleLocation.pathname;

    if (urls.includes("/SiteAdmin/_main/main_terminal") === true) {
      set_main_type("terminal");
    } else {
      set_main_type("");
    }

    // 거래조회 #####

    if (urls.includes("/SiteAdmin/_pay/") === true) {
      set_menu_on("Main1");
      sessionStorage.setItem("menu_on", "Main1");

      // 업체관리 - 지사관리
      if (
        urls === "/SiteAdmin/_pay/transactionHistoryList" ||
        urls === "/SiteAdmin/_pay/transactionHistoryView"
      ) {
        set_menu_sub_on("Sub11");
      }

      // 업체관리 - 지사관리
      if (urls === "/SiteAdmin/_pay/payErrorList") {
        set_menu_sub_on("Sub12");
      }

      // 업체관리 - 차감내역
      if (urls === "/SiteAdmin/_pay/payDeduction") {
        set_menu_sub_on("Sub13");
      }

      // 업체관리 - 정산보류내역
      if (
        urls === "/SiteAdmin/_pay/pay_reserve" ||
        urls === "/SiteAdmin/_pay/pay_reserve_view"
      ) {
        set_menu_sub_on("Sub14");
      }

      // 매출집계
      if (urls === "/SiteAdmin/_pay/pay_total") {
        set_menu_sub_on("Sub15");
      }
    }

    // 거래조회 #####//

    // 거래조회(터미널) #####

    if (urls.includes("/SiteAdmin/_pay_terminal/") === true) {
      set_menu_on("Main1_1");
      sessionStorage.setItem("menu_on", "Main1_1");

      // 업체관리 - 지사관리
      if (
        urls === "/SiteAdmin/_pay_terminal/transactionHistoryList" ||
        urls === "/SiteAdmin/_pay_terminal/transactionHistoryView"
      ) {
        set_menu_sub_on("Sub11_1");
      }

      // 업체관리 - 지사관리
      if (urls === "/SiteAdmin/_pay_terminal/payErrorList") {
        set_menu_sub_on("Sub12_1");
      }

      // 업체관리 - 차감내역
      if (urls === "/SiteAdmin/_pay_terminal/payDeduction") {
        set_menu_sub_on("Sub13_1");
      }

      // 업체관리 - 정산보류내역
      if (
        urls === "/SiteAdmin/_pay_terminal/pay_reserve" ||
        urls === "/SiteAdmin/_pay_terminal/pay_reserve_view"
      ) {
        set_menu_sub_on("Sub14_1");
      }

      // 매출집계
      if (urls === "/SiteAdmin/_pay_terminal/pay_total") {
        set_menu_sub_on("Sub15_1");
      }
    }

    // 거래조회 #####//

    // 정산관리 #####
    if (urls.includes("/SiteAdmin/_settlement/") === true) {
      set_menu_on("Main2");
      sessionStorage.setItem("menu_on", "Main2");

      // 가맹점 정산
      if (urls === "/SiteAdmin/_settlement/calculate_calendar") {
        set_menu_sub_on("Sub21");
      }

      // 가맹점 정산
      if (urls === "/SiteAdmin/_settlement/store") {
        set_menu_sub_on("Sub16");
      }

      // 가맹점 정산결과
      if (urls === "/SiteAdmin/_settlement/store_result") {
        set_menu_sub_on("Sub17");
      }

      // 지사 정산
      if (urls === "/SiteAdmin/_settlement/br") {
        set_menu_sub_on("Sub18");
      }

      // 지사 정산결과
      if (urls === "/SiteAdmin/_settlement/br_result") {
        set_menu_sub_on("Sub19");
      }

      // 총판 정산
      if (urls === "/SiteAdmin/_settlement/di") {
        set_menu_sub_on("Sub20");
      }

      // 총판 정산결과
      if (urls === "/SiteAdmin/_settlement/di_result") {
        set_menu_sub_on("Sub21");
      }

      // 대리점 정산
      if (urls === "/SiteAdmin/_settlement/ag") {
        set_menu_sub_on("Sub22");
      }

      // 대리점 정산결과
      if (urls === "/SiteAdmin/_settlement/ag_result") {
        set_menu_sub_on("Sub23");
      }

      // 매출데이터업로드
      if (urls === "/SiteAdmin/_settlement/data_upload") {
        set_menu_sub_on("Sub24");
      }
    }
    // 정산관리 #####//

    // 정산관리(터미널) #####
    if (urls.includes("/SiteAdmin/_settlement_terminal/") === true) {
      set_menu_on("Main2_1");
      sessionStorage.setItem("menu_on", "Main2_1");

      // 가맹점 정산
      if (urls === "/SiteAdmin/_settlement_terminal/calculate_calendar") {
        set_menu_sub_on("Sub21_1");
      }

      // 가맹점 정산
      if (urls === "/SiteAdmin/_settlement_terminal/store") {
        set_menu_sub_on("Sub16_1");
      }

      // 가맹점 정산결과
      if (urls === "/SiteAdmin/_settlement_terminal/store_result") {
        set_menu_sub_on("Sub17_1");
      }

      // 지사 정산
      if (urls === "/SiteAdmin/_settlement_terminal/br") {
        set_menu_sub_on("Sub18_1");
      }

      // 지사 정산결과
      if (urls === "/SiteAdmin/_settlement_terminal/br_result") {
        set_menu_sub_on("Sub19_1");
      }

      // 총판 정산
      if (urls === "/SiteAdmin/_settlement_terminal/di") {
        set_menu_sub_on("Sub20_1");
      }

      // 총판 정산결과
      if (urls === "/SiteAdmin/_settlement_terminal/di_result") {
        set_menu_sub_on("Sub21_1");
      }

      // 대리점 정산
      if (urls === "/SiteAdmin/_settlement_terminal/ag") {
        set_menu_sub_on("Sub22_1");
      }

      // 대리점 정산결과
      if (urls === "/SiteAdmin/_settlement_terminal/ag_result") {
        set_menu_sub_on("Sub23_1");
      }

      // 매출데이터업로드
      if (urls === "/SiteAdmin/_settlement_terminal/data_upload") {
        set_menu_sub_on("Sub24_1");
      }
    }
    // 정산관리 #####//

    // 업체관리 #####
    if (urls.includes("/SiteAdmin/_management/") === true) {
      set_menu_on("Main3");
      sessionStorage.setItem("menu_on", "Main3");

      // 업체관리 - 지사관리
      if (
        urls === "/SiteAdmin/_management/list" ||
        urls === "/SiteAdmin/_management/form" ||
        urls === "/SiteAdmin/_management/mod"
      ) {
        set_menu_sub_on("Sub31");
      }

      // 업체관리 - 총판관리
      if (urls.includes("/SiteAdmin/_management/Distributor") === true) {
        set_menu_sub_on("Sub32");
      }

      // 업체관리 - 대리점관리
      if (urls.includes("/SiteAdmin/_management/Agency") === true) {
        set_menu_sub_on("Sub33");
      }

      // 업체관리 - 가맹점 관리
      if (urls.includes("/SiteAdmin/_management/Store") === true) {
        set_menu_sub_on("Sub34");
      }

      // 업체관리 - 가맹점 관리(터미널)
      if (urls.includes("/SiteAdmin/_management/StoreTerminal") === true) {
        set_menu_sub_on("Sub35");
      }
    }

    // 터미널관리 #####
    if (urls.includes("/SiteAdmin/_terminal/") === true) {
      set_menu_on("Main4");
      sessionStorage.setItem("menu_on", "Main4");

      if (urls === "/SiteAdmin/_terminal/DirectPaymentList") {
        set_menu_sub_on("Sub42");
      } else if (urls === "/SiteAdmin/_terminal/DirectPaymentForm") {
        set_menu_sub_on("Sub42");
      } else if (urls === "/SiteAdmin/_terminal/DirectPaymentMod") {
        set_menu_sub_on("Sub42");
      }
    }

    // 실적조회 #####
    if (urls.includes("/SiteAdmin/_result/") === true) {
      set_menu_on("Main5");
    }

    // 운영관리 #####
    if (urls.includes("/SiteAdmin/_manage/") === true) {
      set_menu_on("Main6");

      sessionStorage.setItem("menu_on", "Main6");

      if (urls === "/SiteAdmin/_manage/ManageLog") {
        set_menu_sub_on("Sub61");
      }
      if (urls === "/SiteAdmin/_manage/OperateLog") {
        set_menu_sub_on("Sub62");
      }
      if (urls.includes("/SiteAdmin/_manage/Notice") === true) {
        set_menu_sub_on("Sub63");
      }

      if (urls.includes("/SiteAdmin/_manage/Faq") === true) {
        set_menu_sub_on("Sub70");
      }
      if (urls.includes("/SiteAdmin/_manage/Qna") === true) {
        set_menu_sub_on("Sub71");
      }

      if (urls.includes("/SiteAdmin/_manage/_pg") === true) {
        set_menu_sub_on("Sub72");
      }
      if (urls.includes("/SiteAdmin/_manage/_pg_terminal") === true) {
        set_menu_sub_on("Sub73");
      }

      if (urls.includes("/SiteAdmin/_manage/SetupAdmin") === true) {
        set_menu_sub_on("Sub64");
      }
      if (urls === "/SiteAdmin/_manage/SetupLoginList") {
        set_menu_sub_on("Sub65");
      }
      if (
        urls === "/SiteAdmin/_manage/AccessIpList" ||
        urls === "/SiteAdmin/_manage/AccessIpForm"
      ) {
        set_menu_sub_on("Sub66");
      }
      if (urls === "/SiteAdmin/_manage/BankList") {
        set_menu_sub_on("Sub67");
      }
    }

    // 정보변경 #####
    if (urls.includes("/SiteAdmin/_admin/") === true) {
      set_menu_on("Main7");
      set_menu_sub_on("Sub69");
      sessionStorage.setItem("menu_on", "Main7");
    }

    // 정보변경 #####
    if (urls.includes("/SiteAdmin/_pay/homepageList") === true) {
      set_menu_on("Main8");
      set_menu_sub_on("Sub68");
      sessionStorage.setItem("menu_on", "Main8");
    }

    if (urls.includes("/SiteAdmin/_main/main") === true) {
      set_DS_MODE("Y");
    }
  }, [sampleLocation]);

  //메뉴 활성화 =================================
  const session_menu_on = sessionStorage.getItem("menu_on");
  useEffect(() => {
    set_menu_on(session_menu_on);
  }, [session_menu_on]);
  //메뉴 활성화 =================================//

  const [user_info, set_user_info] = useState({});
  const [user_name, set_user_name] = useState("");
  const [user_phone, set_user_phone] = useState("");
  const [user_level, set_user_level] = useState("");
  const [last_login, set_last_login] = useState("");
  const [DS_MODE, set_DS_MODE] = useState("N");

  const [main_type, set_main_type] = useState("");

  const [my_ip, set_my_ip] = useState("");
  const [G01, set_G01] = useState("");
  const [G02, set_G02] = useState("");
  const [G03, set_G03] = useState("");
  const [G04, set_G04] = useState("");
  const [G05, set_G05] = useState("");

  const [G01_1, set_G01_1] = useState("");
  const [G02_1, set_G02_1] = useState("");
  const [G03_1, set_G03_1] = useState("");
  const [G04_1, set_G04_1] = useState("");
  const [G05_1, set_G05_1] = useState("");

  const [G06, set_G06] = useState("");
  const [G07, set_G07] = useState("");
  const [G08, set_G08] = useState("");
  const [G09, set_G09] = useState("");
  const [G10, set_G10] = useState("");
  const [G11, set_G11] = useState("");
  const [G12, set_G12] = useState("");
  const [G13, set_G13] = useState("");
  const [G14, set_G14] = useState("");
  const [G14_1, set_G14_1] = useState("");
  const [G15, set_G15] = useState("");
  const [G16, set_G16] = useState("");
  const [G17, set_G17] = useState("");
  const [G18, set_G18] = useState("");
  const [G19, set_G19] = useState("");
  const [G20, set_G20] = useState("");
  const [G21, set_G21] = useState("");
  const [G22, set_G22] = useState("");
  const [G23, set_G23] = useState("");
  const [G24, set_G24] = useState("");
  const [G25, set_G25] = useState("");
  const [G26, set_G26] = useState("");
  const [G27, set_G27] = useState("");
  const [G28, set_G28] = useState("");
  const [G29, set_G29] = useState("");
  const [G30, set_G30] = useState("");
  const [G31, set_G31] = useState("");
  const [G32, set_G32] = useState("");

  const [P01, set_P01] = useState("");
  const [P02, set_P02] = useState("");
  const [P03, set_P03] = useState("");
  const [P04, set_P04] = useState("");
  const [P05, set_P05] = useState("");
  const [P06, set_P06] = useState("");
  const [P07, set_P07] = useState("");
  const [P08, set_P08] = useState("");
  const [P09, set_P09] = useState("");

  const [P01_1, set_P01_1] = useState("");
  const [P02_1, set_P02_1] = useState("");
  const [P03_1, set_P03_1] = useState("");
  const [P04_1, set_P04_1] = useState("");
  const [P05_1, set_P05_1] = useState("");
  const [P06_1, set_P06_1] = useState("");
  const [P07_1, set_P07_1] = useState("");
  const [P08_1, set_P08_1] = useState("");
  const [P09_1, set_P09_1] = useState("");

  const Fn_Use_Info_Api = async () => {
    const loginId = sessionStorage.getItem("loginId");

    if (loginId === null) {
      navigate("/SiteAdmin/");
      return;
    }

    let bodyPage = {
      loginId: loginId,
    };

    axios
      .post("/api/manage/setupAdminInfo", bodyPage)
      .then(function (res) {
        if (res.status === 401 || res.data.length === 0) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/SiteAdmin/");
          return;
        }

        res.data.map((v, index) => {
          set_user_name(v.user_name);
          set_user_phone(v.user_phone);
          set_user_level(v.user_level);
          set_last_login(v.last_login);
          set_G01(v.G01);
          set_G02(v.G02);
          set_G03(v.G03);
          set_G04(v.G04);
          set_G05(v.G05);

          set_G01_1(v.G01_1);
          set_G02_1(v.G02_1);
          set_G03_1(v.G03_1);
          set_G04_1(v.G04_1);
          set_G05_1(v.G05_1);

          set_G06(v.G06);
          set_G07(v.G07);
          set_G08(v.G08);
          set_G09(v.G09);
          set_G10(v.G10);
          set_G11(v.G11);
          set_G12(v.G12);
          set_G13(v.G13);
          set_G14(v.G14);
          set_G14_1(v.G14_1);
          set_G15(v.G15);
          set_G16(v.G16);
          set_G17(v.G17);
          set_G18(v.G18);
          set_G19(v.G19);
          set_G20(v.G20);
          set_G21(v.G21);
          set_G22(v.G22);
          set_G23(v.G23);
          set_G24(v.G24);
          set_G25(v.G25);
          set_G26(v.G26);
          set_G27(v.G27);
          set_G28(v.G28);
          set_G29(v.G29);
          set_G30(v.G30);
          set_G31(v.G31);

          set_P01(v.P01);
          set_P02(v.P02);
          set_P03(v.P03);
          set_P04(v.P04);
          set_P05(v.P05);
          set_P06(v.P06);
          set_P07(v.P07);
          set_P08(v.P08);
          set_P09(v.P09);

          set_P01_1(v.P01_1);
          set_P02_1(v.P02_1);
          set_P03_1(v.P03_1);
          set_P04_1(v.P04_1);
          set_P05_1(v.P05_1);
          set_P06_1(v.P06_1);
          set_P07_1(v.P07_1);
          set_P08_1(v.P08_1);
          set_P09_1(v.P09_1);
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/SiteAdmin/");
          return;
        }
      });
  };

  useEffect(() => {
    Fn_Use_Info_Api();
    Fn_My_Ip();
  }, []);

  // 로그아웃
  const onLogOut = () => {
    axios.post("/api/logout").then(function (res) {
      if (res.status === 200) {
        sessionStorage.removeItem("loginId");
        navigate("/SiteAdmin/");
      }
    });
  };

  // 로그인 날짜
  function converToLocalTime(serverDate) {
    var date = new Date(Date.parse(serverDate));
    const TIME_ZONE = 3240 * 10000;
    return new Date(+date + TIME_ZONE)
      .toISOString()
      .replace("T", " ")
      .replace(/\..*/, "")
      .substring(0, 16);
  }

  // 내 아이피
  const Fn_My_Ip = async () => {
    fetchWithCallback("https://geolocation-db.com/json//", (locationIp) => {
      set_my_ip(locationIp.IPv4);
    });
  };

  //연길 시간 종료
  const onEndTime = () => {
    axios.post("/api/logout").then(function (res) {
      if (res.status === 200) {
        sessionStorage.removeItem("loginId");
        navigate("/SiteAdmin/");
      }
    });
  };

  //시간 연장
  const onReStartTime = () => {
    const loginId = sessionStorage.getItem("loginId");
    let bodyPage = {
      loginId: loginId,
    };
    axios
      .post("/api/manage/setupAdminInfo", bodyPage)
      .then(function (res) {
        if (res.status === 401 || res.data.length === 0) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/SiteAdmin/");
          return;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/SiteAdmin/");
          return;
        }
      });
  };

  const Fn_Main = (loc) => {
    navigate(loc);
  };

  return (
    <>
      <header id="header">
        <div className="left_wrap">
          <div className="left">
            <h1 className="logo">
              <Link to="/SiteAdmin/_main/main">
                <img src={logo_02} alt="" />
              </Link>
            </h1>
          </div>
          {DS_MODE === "Y" && (
            <>
              <button
                type="button"
                onClick={() => {
                  Fn_Main("/SiteAdmin/_main/main");
                }}
                className={main_type === "" ? "active" : ""}
              >
                수기정산
              </button>
              <button
                type="button"
                onClick={() => {
                  Fn_Main("/SiteAdmin/_main/main_terminal");
                }}
                className={main_type === "terminal" ? "active" : ""}
              >
                터미널정산
              </button>
            </>
          )}
        </div>

        <div className="right">
          <div className="right_item">
            <div>
              <span>
                {user_name} ( IP : {my_ip} )
              </span>
              <span>
                접속일시 : {last_login !== "" && converToLocalTime(last_login)}
              </span>
            </div>

            <TimerBox
              hour={loginTime.hour}
              min={loginTime.min}
              sec={loginTime.sec}
              onEndTimeEvent={onEndTime}
              onReStartTimeEvent={onReStartTime}
            />

            <a href="#" className="logout" onClick={onLogOut}>
              <i></i>로그아웃
            </a>
          </div>
        </div>
      </header>

      <nav id="nav">
        {/* <ul className="dep_menu">
                    
                    <li className="dep_1 active"> <Link to="/SiteAdmin/_pay/homepageList">발송내역</Link></li>
                </ul> */}

        <div className="sub_menu_wrap">
          <div
            className={
              menu_on === "Main8" ? "sub_menu on menu_8" : "sub_menu menu_8"
            }
            onClick={() => {
              set_menu_on("Main8");
            }}
          >
            <button className="label">
              발송내역<i></i>
            </button>
            <ul
              className={
                menu_on === "Main8" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="Shipment_history"
                className={menu_sub_on === "Sub68" ? "on" : null}
              >
                <Link to="/SiteAdmin/_pay/homepageList" className="dep_2">
                  웹결제 발송내역
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={
              menu_on === "Main1" ? "sub_menu menu_1 on" : "sub_menu menu_1"
            }
            onClick={() => {
              set_menu_on("Main1");
            }}
          >
            <button className="label">
              거래조회<i></i>
            </button>
            <ul
              className={
                menu_on === "Main1" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              {(G01 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_history"
                  className={menu_sub_on === "Sub11" ? "on" : null}
                >
                  {" "}
                  <Link
                    to="/SiteAdmin/_pay/transactionHistoryList"
                    className="dep_2"
                  >
                    거래내역
                  </Link>
                </li>
              )}
              {(G02 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_error"
                  className={menu_sub_on === "Sub12" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_pay/payErrorList" className="dep_2">
                    거래거절(실패)내역
                  </Link>
                </li>
              )}
              {(G03 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_deduction"
                  className={menu_sub_on === "Sub13" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_pay/payDeduction" className="dep_2">
                    차감내역
                  </Link>
                </li>
              )}
              {(G04 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_reserve"
                  className={menu_sub_on === "Sub14" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_pay/pay_reserve" className="dep_2">
                    정산보류내역
                  </Link>
                </li>
              )}
              {(G05 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_total"
                  className={menu_sub_on === "Sub15" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_pay/pay_total" className="dep_2">
                    매출집계
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div
            className={
              menu_on === "Main2" ? "sub_menu menu_2 on" : "sub_menu menu_2"
            }
            onClick={() => {
              set_menu_on("Main2");
            }}
          >
            <button className="label">
              정산관리<i></i>
            </button>
            <ul
              className={
                menu_on === "Main2" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              {/*(G06 === "Y" || user_level === 0) && (
                            <li data-menu="set_calendar" className={menu_sub_on==='Sub21'?'on':null}><Link to="/SiteAdmin/_settlement/calculate_calendar" className="dep_2">정산승인 달력</Link></li>
                        )*/}
              {(P01 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_store"
                  className={menu_sub_on === "Sub16" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_settlement/store" className="dep_2">
                    가맹점 정산
                  </Link>
                </li>
              )}
              {(P02 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_result"
                  className={menu_sub_on === "Sub17" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement/store_result"
                    className="dep_2"
                  >
                    가맹점 정산 결과
                  </Link>
                </li>
              )}
              {(P03 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_br"
                  className={menu_sub_on === "Sub18" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_settlement/br" className="dep_2">
                    지사 정산
                  </Link>
                </li>
              )}
              {(P04 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_br_result"
                  className={menu_sub_on === "Sub19" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_settlement/br_result" className="dep_2">
                    지사 정산 결과
                  </Link>
                </li>
              )}
              {(P05 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_di"
                  className={menu_sub_on === "Sub20" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_settlement/di" className="dep_2">
                    총판 정산
                  </Link>
                </li>
              )}
              {(P06 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_di_result"
                  className={menu_sub_on === "Sub21" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_settlement/di_result" className="dep_2">
                    총판 정산 결과
                  </Link>
                </li>
              )}
              {(P07 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_ag"
                  className={menu_sub_on === "Sub22" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_settlement/ag" className="dep_2">
                    대리점 정산
                  </Link>
                </li>
              )}
              {(P08 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_ag_result"
                  className={menu_sub_on === "Sub23" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_settlement/ag_result" className="dep_2">
                    대리점 정산 결과
                  </Link>
                </li>
              )}
              {(P09 === "Y" || user_level === 0) && (
                <li
                  data-menu="data_upload"
                  className={menu_sub_on === "Sub24" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement/data_upload"
                    className="dep_2"
                  >
                    매출데이터업로드
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div
            className={
              menu_on === "Main1_1" ? "sub_menu menu_1 on" : "sub_menu menu_1"
            }
            onClick={() => {
              set_menu_on("Main1_1");
            }}
          >
            <button className="label">
              거래조회(터미널)<i></i>
            </button>
            <ul
              className={
                menu_on === "Main1_1" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              {(G01_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_history"
                  className={menu_sub_on === "Sub11_1" ? "on" : null}
                >
                  {" "}
                  <Link
                    to="/SiteAdmin/_pay_terminal/transactionHistoryList"
                    className="dep_2"
                  >
                    거래내역
                  </Link>
                </li>
              )}
              {/* {(G02_1 === "Y" || user_level === 0) && (
                            <li  data-menu="pay_error" className={menu_sub_on==='Sub12_1'?'on':null}><Link to="/SiteAdmin/_pay_terminal/payErrorList" className="dep_2">거래거절(실패)내역</Link></li>
                        )} */}
              {(G03_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_deduction"
                  className={menu_sub_on === "Sub13_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_pay_terminal/payDeduction"
                    className="dep_2"
                  >
                    차감내역
                  </Link>
                </li>
              )}
              {(G04_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_reserve"
                  className={menu_sub_on === "Sub14_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_pay_terminal/pay_reserve"
                    className="dep_2"
                  >
                    정산보류내역
                  </Link>
                </li>
              )}
              {(G05_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="pay_total"
                  className={menu_sub_on === "Sub15_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_pay_terminal/pay_total"
                    className="dep_2"
                  >
                    매출집계
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div
            className={
              menu_on === "Main2_1" ? "sub_menu menu_2 on" : "sub_menu menu_2"
            }
            onClick={() => {
              set_menu_on("Main2_1");
            }}
          >
            <button className="label">
              정산관리(터미널)<i></i>
            </button>
            <ul
              className={
                menu_on === "Main2_1" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              {(P01_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_store"
                  className={menu_sub_on === "Sub16_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/store"
                    className="dep_2"
                  >
                    가맹점 정산
                  </Link>
                </li>
              )}
              {(P02_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_result"
                  className={menu_sub_on === "Sub17_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/store_result"
                    className="dep_2"
                  >
                    가맹점 정산 결과
                  </Link>
                </li>
              )}
              {(P03_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_br"
                  className={menu_sub_on === "Sub18_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/br"
                    className="dep_2"
                  >
                    지사 정산
                  </Link>
                </li>
              )}
              {(P04_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_br_result"
                  className={menu_sub_on === "Sub19_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/br_result"
                    className="dep_2"
                  >
                    지사 정산 결과
                  </Link>
                </li>
              )}
              {(P05_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_di"
                  className={menu_sub_on === "Sub20_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/di"
                    className="dep_2"
                  >
                    총판 정산
                  </Link>
                </li>
              )}
              {(P06_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_di_result"
                  className={menu_sub_on === "Sub21_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/di_result"
                    className="dep_2"
                  >
                    총판 정산 결과
                  </Link>
                </li>
              )}
              {(P07_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_ag"
                  className={menu_sub_on === "Sub22_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/ag"
                    className="dep_2"
                  >
                    대리점 정산
                  </Link>
                </li>
              )}
              {(P08_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="set_ag_result"
                  className={menu_sub_on === "Sub23_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/ag_result"
                    className="dep_2"
                  >
                    대리점 정산 결과
                  </Link>
                </li>
              )}
              {(P09_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="data_upload"
                  className={menu_sub_on === "Sub24_1" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_settlement_terminal/data_upload"
                    className="dep_2"
                  >
                    매출데이터업로드
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div
            className={
              menu_on === "Main3" ? "sub_menu menu_3 on" : "sub_menu menu_3"
            }
            onClick={() => {
              set_menu_on("Main3");
            }}
          >
            <button className="label">
              업체관리<i></i>
            </button>
            <ul
              className={
                menu_on === "Main3" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              {(G11 === "Y" || user_level === 0) && (
                <li
                  data-menu="management_list"
                  className={menu_sub_on === "Sub31" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_management/list" className="dep_2">
                    지사관리
                  </Link>
                </li>
              )}
              {(G12 === "Y" || user_level === 0) && (
                <li
                  data-menu="distributor_list"
                  className={menu_sub_on === "Sub32" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_management/DistributorList"
                    className="dep_2"
                  >
                    총판관리
                  </Link>
                </li>
              )}
              {(G13 === "Y" || user_level === 0) && (
                <li
                  data-menu="agency_list"
                  className={menu_sub_on === "Sub33" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_management/AgencyList"
                    className="dep_2"
                  >
                    대리점관리
                  </Link>
                </li>
              )}
              {(G14 === "Y" || user_level === 0) && (
                <li
                  data-menu="sub_store"
                  className={menu_sub_on === "Sub34" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_management/StoreList" className="dep_2">
                    가맹점관리(일반)
                  </Link>
                </li>
              )}

              {(G14_1 === "Y" || user_level === 0) && (
                <li
                  data-menu="sub_store"
                  className={menu_sub_on === "Sub35" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_management/StoreTerminalList"
                    className="dep_2"
                  >
                    가맹점관리(터미널)
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {/* 
                    <div className={menu_on==='Main4'?'sub_menu on':'sub_menu'} onClick={()=> { set_menu_on('Main4') }} >
                        <button className="label">터미널관리<i></i></button>
                        <ul className={menu_on==='Main4'?'sub_menu_item open':'sub_menu_item'}>
                        {(G15 === "Y" || user_level === 0) && (
                            <li data-menu="com_terminal"><a href="../_terminal/com_terminal.php" className="dep_2">터미널관리 </a></li>
                        )}
                        {(G16 === "Y" || user_level === 0) && (
                            <li data-menu="direct_payment_list" className={menu_sub_on==='Sub42'?'on':null}><Link to="/SiteAdmin/_terminal/DirectPaymentList" className="dep_2">수기결제관리</Link></li>
                        )}
                        </ul>
                    </div> */}

          {/* 
                    <div className={menu_on==='Main5'?'sub_menu menu_5 on':'sub_menu menu_5'} onClick={()=> { set_menu_on('Main5') }} >
                        <button className="label">실적조회<i></i></button>
                        <ul className={menu_on==='Main5'?'sub_menu_item open':'sub_menu_item'}>
                        {(G17 === "Y" || user_level === 0) && (
                            <li data-menu="company_acc_tax"><a href="../_result/company_acc_tax.php" className="dep_2"> 매출계산서(사업자) </a></li>
                        )}
                        {(G18 === "Y" || user_level === 0) && (
                            <li data-menu="person_acc_tax"><a href="../_result/person_acc_tax.php" className="dep_2">매출계산서(비사업자)  </a></li>
                        )}
                        {(G19 === "Y" || user_level === 0) && (
                            <li data-menu="agent_acc_tax"><a href="../_result/agent_acc_tax.php" className="dep_2">에이전트계산서  </a></li>
                        )}
                        {(G20 === "Y" || user_level === 0) && (
                            <li data-menu="month_tax"><a href="../_result/month_tax.php" className="dep_2">세금계산서마감 </a></li>
                        )}
                        </ul>
                    </div>
                    */}

          <div
            className={
              menu_on === "Main6" ? "sub_menu menu_6 on" : "sub_menu menu_6"
            }
            onClick={() => {
              set_menu_on("Main6");
            }}
          >
            <button className="label">
              운영관리<i></i>
            </button>
            <ul
              className={
                menu_on === "Main6" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              {(G21 === "Y" || user_level === 0) && (
                <li
                  data-menu="manage_log"
                  className={menu_sub_on === "Sub61" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_manage/ManageLog" className="dep_2">
                    접속로그관리
                  </Link>
                </li>
              )}
              {(G22 === "Y" || user_level === 0) && (
                <li
                  data-menu="operate_log"
                  className={menu_sub_on === "Sub62" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_manage/OperateLog" className="dep_2">
                    운영로그관리
                  </Link>
                </li>
              )}
              {(G23 === "Y" || user_level === 0) && (
                <>
                  <li
                    data-menu="notice"
                    className={menu_sub_on === "Sub63" ? "on" : null}
                  >
                    <Link to="/SiteAdmin/_manage/NoticeList" className="dep_2">
                      공지사항
                    </Link>
                  </li>
                  <li
                    data-menu="faq"
                    className={menu_sub_on === "Sub70" ? "on" : null}
                  >
                    <Link to="/SiteAdmin/_manage/FaqList" className="dep_2">
                      자주하는 질문
                    </Link>
                  </li>
                  <li
                    data-menu="qna"
                    className={menu_sub_on === "Sub71" ? "on" : null}
                  >
                    <Link to="/SiteAdmin/_manage/QnaList" className="dep_2">
                      1대1 문의
                    </Link>
                  </li>
                </>
              )}
              {user_level === 0 && (
                <li
                  data-menu="setup_admin"
                  className={menu_sub_on === "Sub64" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_manage/SetupAdminList"
                    className="dep_2"
                  >
                    운영자 계정관리
                  </Link>
                </li>
              )}
              {(G24 === "Y" || user_level === 0) && (
                <li
                  data-menu="setup_login"
                  className={menu_sub_on === "Sub65" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_manage/SetupLoginList"
                    className="dep_2"
                  >
                    로그인 관리
                  </Link>
                </li>
              )}
              {(G25 === "Y" || user_level === 0) && (
                <li
                  data-menu="access_ip"
                  className={menu_sub_on === "Sub66" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_manage/AccessIpList" className="dep_2">
                    접속 IP
                  </Link>
                </li>
              )}
              {/*
                            <li  data-menu="holiday">
                                <Link to="/SiteAdmin/_manage/Holiday" className="dep_2">휴일관리</Link>
                            </li>
                            */}
              {(G26 === "Y" || user_level === 0) && (
                <li
                  data-menu="bank_list"
                  className={menu_sub_on === "Sub67" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_manage/BankList" className="dep_2">
                    은행관리
                  </Link>
                </li>
              )}

              {(G28 === "Y" || user_level === 0) && (
                <li
                  data-menu="bank_list"
                  className={menu_sub_on === "Sub72" ? "on" : null}
                >
                  <Link to="/SiteAdmin/_manage/_pg/list" className="dep_2">
                    PG관리
                  </Link>
                </li>
              )}
              {(G29 === "Y" || user_level === 0) && (
                <li
                  data-menu="bank_list"
                  className={menu_sub_on === "Sub73" ? "on" : null}
                >
                  <Link
                    to="/SiteAdmin/_manage/_pg_terminal/list"
                    className="dep_2"
                  >
                    PG관리(터미널)
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div
            className={
              menu_on === "Main7" ? "sub_menu menu_7 on" : "sub_menu menu_7"
            }
            onClick={() => {
              set_menu_on("Main7");
            }}
          >
            <button className="label">
              정보변경<i></i>
            </button>
            <ul
              className={
                menu_on === "Main7" ? "sub_menu_item open" : "sub_menu_item"
              }
            >
              <li
                data-menu="setting"
                className={menu_sub_on === "Sub69" ? "on" : null}
              >
                <Link to="/SiteAdmin/_admin/Setting" className="dep_2">
                  정보변경
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default SiteAdminHeader;
