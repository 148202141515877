import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Editor from '../../../../quill/EditorComponent';
import queryString from 'query-string';
import ReactQuill from 'react-quill';

import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/DistributorHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";




function DistributorManageFaqView() {

    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search);  

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    const [myip, set_myip] = useState(""); 
    const [page_state, set_page_state] = useState(true);


    const [idx, set_idx] = useState(sIdx);
    const on_idx_handler = (event) => {
        set_idx(event.currentTarget.value)
    }
    
    
    //제목
    const [subject, set_subject] = useState('');
    const on_subject_handler = (event) => {
        set_subject(event.currentTarget.value)
    }

    //내용
    const [contents, set_contents] = useState('');
    function onEditorChange(value) {
        set_contents(value)
    }



    //등록
    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: idx,
            code: (query.code === undefined || query.code === '' ? 'faq':query.code),
            subject: subject,
            contents: contents,  
            ip_address: myip,
            user_level: '1',
            user_id: sessionStorage.getItem("loginId"),
        }

        axios.post('/api/manage/FaqFormAdd', body).then(function(response){
            
            if(response.data === "AddOk"){
                navigate('/Distributor/_manage/FaqList');
            
            }else{
                alert("시스템 에러");   
            }
        },[])
    }


    //상세정보
    const Fn_View=(sIdx)=>{

        let bodyPage = {
            idx: sIdx
        }
        
        axios.post('/api/manage/NoticeInfo', bodyPage).then(function(res){                        
            res.data.map((v, index) => {
                set_subject(v.subject);
                set_contents(v.contents);                                
                return ''
            }); 
            
            if(res.data.length === 0){                
                set_page_state(false)                
            }
        })         
    }

    const FnMyip =async()=>{
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();        
        set_myip(locationIp.IPv4)
    }
    
    React.useEffect(() => {
        FnMyip();
        if(sIdx !== ''){
            Fn_View(sIdx);
        }
    }, [sIdx])

    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="distributor_list">
            
                 
                    <input type="hidden" name="idx" id="idx" value={idx} onChange={on_idx_handler} />
                    
                    <h3 className="page_ttl">자주하는 질문</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            
                            <table>
                                
                                <tbody>
                                    <tr>
                                        <th style={{width:'200px'}}>질문</th>
                                        <td>
                                            <div class="in_box">
                                            {subject}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <ReactQuill value={contents} readOnly={true} theme={"bubble"}/>
                                        </td>
                                    </tr>                                
                                </tbody>
                            </table>
                            
                        

                        </div>

                    </div>


                    <div className="top_table_btn">
                 
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>                       
                    </div>

             

            </div> 
            
        </div>

        <Footer/>
        </>
    )
}

export default DistributorManageFaqView