import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import * as XLSX from 'xlsx'

import {
  Link, useLocation, useNavigate
} from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff} from "../../../include/ConfigSet";




function SiteAdminPayTransactionHistoryExcelTerminal() {
  const navigate = useNavigate();
  const location = useLocation();

  // 파라메터 정보 저장
  const queryParam = location.search.substring(1, location.search.length);
  //console.log(queryParam);


  // 문자열의 쿼리스트링을 Object로 변환
  const query = queryString.parse(location.search);    


  // 게시판 내역
  const [viewContent , setViewContent] = useState([]);



  // PG 리스트
  const [sugiList, setSugiList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [parent_idx1, set_parent_idx1] = useState("");
  const [parent_idx2, set_parent_idx2] = useState("");
  
  const [managerList1, setManagerList1] = useState([]);
  const [managerList2, setManagerList2] = useState([]);

  const on_set_parent_idx1_handler = (event) => {
      set_parent_idx1(event.currentTarget.value);
  }

  const on_set_parent_idx2_handler = (event) => {
      set_parent_idx2(event.currentTarget.value);
  }





  const qs = queryString.parse(window.location.search);
  const sWord = qs.searchWord ? qs.searchWord : '';
  const sCate = qs.searchCates ? qs.searchCates : 'authno';
  const pg_group = qs.pg_group ? qs.pg_group : '';
  const paymenttype = qs.paymenttype ? qs.paymenttype : '';
  const ordertype = qs.ordertype ? qs.ordertype : '';
  const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
  const sdate = qs.sdate ? qs.sdate : '';
  const edate = qs.edate ? qs.edate : '';

  const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
  const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';
  const status = qs.status ? qs.status : '';

  const page = qs.pg? qs.pg : 1;


  // 거래금액 합산
  let total_amount =  0;

    

  // PG금액 합산
  let total_pg_fee = 0;

  // PG 입금 합산 금액
  let total_pgImcome = 0;

  // 가맹점 합산 금액
  let total_St_fee = 0;

  // 정산금액 합산
  let total_cal_price = 0;

  // 본사금액 합산
  let total_calculateAdmin = 0;

  // 지사금액 합산
  let total_calculateMbramch = 0;

  // 총판금액 합산
  let total_calculateDistributor = 0;

  //대리점금액 합산
  let total_calculateAgency = 0;



  const Board = ({
    idx,
    nums,
    created_datetime,
    resultcode,
    quota,
    cardno,
    cpid,
    authno,
    
    productname,
    username,
    userphone,
    status,
    status_pay,
    pg_group,
    cardcode,
    orderno,
    regid,
    calculate_date_re,
    feeStore,
    St_fee,
    Br_branch_name,
    Di_branch_name,
    Ag_branch_name,
    St_branch_name,
    income,
    pg_fee,
    fee,
    feeAdmin,
    feeMbramch,
    feeDistributor,
    feeAgency,
    cancel_date,
    st_type,
    amount
  }) => {

    let created_datetime1 = created_datetime.substr(0,10);
    let created_datetime2 = created_datetime.substr(11,8);

    let cancel_date1 = cancel_date.substr(0,10);
    if(cancel_date !== ""){
        if(cancel_date1 === "0000-00-00"){
            cancel_date1 = "";
        }
    }




    
    let result_color = "";

    if(status_pay === "Y"){
        
        result_color = "blue";

    }else if(status_pay === "N"){
        
        result_color = "red";
    }


    let cancelStatus = 1;
    let cancelColor = "";
    let tdBack = "";

    // 취소 상태값
    if(status === "C" || status === "BC" || status === "EC"){
        
        cancelColor = "red";
        tdBack = "red_bg";
    }else{
        cancelColor = "";
        tdBack = "";
    }

    let quota_txt = "";

    if(quota === "00"){
        quota_txt = "일시불";
    }else{
        quota_txt = quota;
    }


 

    // PG 입금
    let pgImcome = parseInt(amount) - parseInt(pg_fee);
    

    // 정산금액 (결제금액 - PG - 가맹점)
    let cal_price = parseInt(amount) - parseInt(pg_fee) - parseInt(St_fee);

    // 관리자 정산금액 = 결제금액 / 100 * (지사 - 본사 수수료)
    let calculateAdmin = parseInt(amount) / 100 * (feeMbramch - feeAdmin);
    
    // 지사 정산금액 = 결제금액 / 100 * (총판 - 지사)
    let calculateMbramch = parseInt(amount) / 100 * (feeDistributor - feeMbramch);

    // 총판 정산금액 = 결제금액 / 100 * (총판 - 지사)
    let calculateDistributor = parseInt(amount) / 100 * (feeAgency - feeDistributor);

    // 대리점 정산금액 = 결제금액 / 100 * (총판 - 지사)
    let calculateAgency = parseInt(amount) / 100 * (feeStore - feeAgency);

    let card_out_no = cardno.substr(0, cardno.length-7) + "****" + cardno.substr(cardno.length-3, 3);

    

    return (
        <tr className={tdBack}>
            <td>{created_datetime1} {created_datetime2}</td>
            <td>{GetPayGubun(status)}</td>
            <td>{Br_branch_name}</td>
            <td>{Di_branch_name}</td>
            <td>{Ag_branch_name}</td>
            <td>{St_branch_name}</td>
            <td>Key-in</td>
            <td>{cpid}</td>
            <td>신용</td>
            <td>{GetPayStatus(status)}</td>
            <td>{PgCardName(pg_group, cardcode)}</td>
            <td>{PgCardName(pg_group, cardcode)}</td>
            <td>{card_out_no}</td>
            <td>{authno}</td>
            <td>{cancel_date1}</td>
            <td>{PgName(pg_group)}</td>
            <td>{calculate_date_re}</td>
            <td>{calculate_date_re} (D+{income})</td>
            <td>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td>{pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({fee}%)</td>
            <td>{pgImcome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td>{St_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({feeStore}%)</td>
            <td>{cal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td>{calculateAdmin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}({feeAdmin}%)</td>
            <td>{calculateMbramch.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}({feeMbramch}%)</td>
            <td>{calculateDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}({feeDistributor}%)</td>
            <td>{calculateAgency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}({feeAgency}%)</td>
            <td>{username}</td>
            <td>{userphone}</td>
            <td>{productname}</td>
        </tr>
    );
  };


  useEffect(()=>{
    
    let bodyPage = {
        searchWord: sWord,
        searchCates: sCate,
        pg_group: pg_group,
        paymenttype: paymenttype,
        ordertype: ordertype,
        chkdate: chkdate,
        sdate: sdate,
        edate: edate,
        parent_idx1: q_parent_idx1,
        parent_idx2: q_parent_idx2,
        status: status

    }
    //console.log("bodyPage : " + bodyPage);

    axios.post('/api/terminal/pay/transaction_history_list_excel', bodyPage).then(function(res){
                
        setViewContent(res.data);
        
    }) 
    
},[sWord, sCate, page]);







  const tableRef = useRef(null);

  const handleDownload = () => {
    const table = tableRef.current;
    let data = [];
    for (let i = 0; i < table.rows.length; i++) {
      let rowData = [];
      for (let j = 0; j < table.rows[i].cells.length; j++) {
        rowData.push(table.rows[i].cells[j].innerHTML);
        
      }
      data.push(rowData);
    }
    const ws = XLSX.utils.aoa_to_sheet(data);
    

    if (data.length > 0) {
      ws["!merges"] = [{ s: { r: data.length - 1, c: 0 }, e: { r: data.length - 1, c: 17 } }];

      ws["A1"].s = 
      { 
        font: 
        {
          color: { rgb: "FFFFAA00" }
        } 
      }
      
    }
    
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    XLSX.writeFile(wb, "data.xlsx");

  };

  

  return (
    <div>
      <table ref={tableRef} style={{"display":"none"}}>
        <thead>
          <tr>
          <th>거래일자</th>
          <th>구분</th>
          <th>지사</th>
          <th>총판</th>
          <th>대리점</th>
          <th>가맹점</th>
          <th>매체</th>
          <th>TID</th>
          <th>구분</th>
          <th>정산</th>
          <th>발행</th>
          <th>매입</th>
          <th>카드번호</th>
          <th>승인번호</th>
          <th>취소</th>
          <th>PG</th>
          <th>PG정산</th>
          <th>정산예정</th>
          <th>거래금액</th>
          <th>PG</th>
          <th>PG입금</th>
          <th>가맹점</th>
          <th>정산금액</th>
          <th>본사</th>
          <th>지사</th>
          <th>총판</th>
          <th>대리점</th>
          <th>주문자</th>
          <th>연락처</th>
          <th>상품명</th>
          </tr>
        </thead>
        <tbody>
          {
              viewContent.map((v, index) => {
                  
                  // 거래금액 합산
                  total_amount = parseInt(total_amount) + parseInt(v.amount);

                  // PG금액 합산
                  total_pg_fee = parseInt(total_pg_fee) + parseInt(v.pg_fee);


                  // PG 입금 합산 금액
                  total_pgImcome = parseInt(total_pgImcome) + ( parseInt(v.amount) - parseInt(v.pg_fee) );

                  // 가맹점 합산 금액
                  total_St_fee = parseInt(total_St_fee) + parseInt(v.St_fee);

                  // 정산금액 합산
                  total_cal_price = parseInt(total_cal_price) + ( parseInt(v.amount) - parseInt(v.pg_fee) - parseInt(v.St_fee) );

                  // 본사금액 합산
                  total_calculateAdmin = parseInt(total_calculateAdmin) + ( parseInt(v.amount) /100 * ( v.feeMbramch - v.feeAdmin ) );

                  // 지사금액 합산
                  total_calculateMbramch = parseInt(total_calculateMbramch) + ( parseInt(v.amount) /100 * ( v.feeDistributor - v.feeMbramch ) );

                  // 총판금액 합산
                  total_calculateDistributor = parseInt(total_calculateDistributor) + ( parseInt(v.amount) /100 * ( v.feeAgency - v.feeDistributor ) );

                  // 대리점금액 합산
                  total_calculateAgency = parseInt(total_calculateAgency) + ( parseInt(v.amount) /100 * ( v.feeStore - v.feeAgency ) );


                  return (
                      <Board
                          key={v.idx}
                          idx={v.idx}
                          created_datetime={v.created_datetime}
                          resultcode={v.resultcode}
                          quota={v.quota}
                          cardno={v.cardno}
                          cpid={v.cpid}
                          authno={v.authno}
                          amount={v.amount}
                          productname={v.productname}
                          username={v.username}
                          userphone={v.userphone}
                          status={v.status}
                          status_pay={v.status_pay}
                          pg_group={v.pg_group}
                          cardcode={v.cardcode}
                          orderno={v.orderno}
                          regid={v.regid}
                          calculate_date_re={v.calculate_date_re}
                          feeStore={v.feeStore}
                          St_fee={v.St_fee}
                          Br_branch_name={v.Br_branch_name}
                          Di_branch_name={v.Di_branch_name}
                          Ag_branch_name={v.Ag_branch_name}
                          St_branch_name={v.St_branch_name}
                          income={v.income}
                          pg_fee={v.pg_fee}
                          fee={v.fee}
                          feeAdmin={v.feeAdmin}
                          feeMbramch={v.feeMbramch}
                          feeDistributor={v.feeDistributor}
                          feeAgency={v.feeAgency}
                          cancel_date={v.cancel_date}
                          st_type={v.st_type}
                          
                      />
                      
                  );
              })
            
          }
              
              <tr className="total">
                  <td>TOTAL</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_pgImcome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_St_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_cal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_calculateAdmin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_calculateMbramch.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_calculateDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{total_calculateAgency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td colSpan="3"></td>
              </tr>
          </tbody>
      </table>
      <button onClick={handleDownload}>Download</button>
    </div>
  );
  
  
}

export default SiteAdminPayTransactionHistoryExcelTerminal