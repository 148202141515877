import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';
import '../../../../../assest/css/datepicker.min.css';

import Header from "../../../include/StoreHeader";
import Footer from "../../../include/SiteAdminFooter";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff} from "../../../include/ConfigSet";
import jquery from 'jquery';
import $ from 'jquery';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import re_closebtn_w from '../../../../../assest/img/common/re_closebtn_w.png';
import { ko } from "date-fns/esm/locale";


function StorePayCalculateCalendar() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);

    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);
    
    // 스케쥴 정보 저장
    const [schedules, setSchedules] = useState([]);

    // 선택된 날짜 정보
    const [selDate, set_selDate] = useState('');

    const loginId = sessionStorage.getItem("loginId");

    const today = new Date();
    
    const qs = queryString.parse(window.location.search);
    const year = qs.year ? qs.year : today.getFullYear();
    const month = qs.month ? qs.month : today.getMonth()+1;
    const calMonth = month -1;

    
    
    // 셀렉트 년
    const yearInfo = [];

    for(let tmpYear = 2022; tmpYear<=today.getFullYear(); tmpYear++){
        yearInfo.push({'year':tmpYear});
    }

    // 셀렉트 월
    const monthInfo = [];
    
    for(let tmpMonth = 1; tmpMonth<=12; tmpMonth++){
        monthInfo.push({'month':tmpMonth});
    }


    // 달력정보
    const calArr = [];
        
    const SelYear = ({
        setYear,
        nowYear
    }) => {
        return (
            <>
                <option value={setYear} selected={ setYear == nowYear ? "selected" : ""} >{setYear}</option>
            </>
        )
    };

    const SelMonth = ({
        setMonth,
        nowMonth
    }) => {
        return (
            <>
                <option value={setMonth} selected={ setMonth == nowMonth ? "selected" : ""} >{setMonth}</option>
            </>
        )
    };

    // 달력 이전달
    const handlePrevMonth = () => {
        let r_month = parseInt(month) - 1;
        let r_year = year;

        if(r_month < 1){
            r_month = 12;
            r_year = parseInt(r_year) - 1;
        }

        $("#year").val(r_year);
        $("#month").val(r_month);
        $("#frmCal").submit();
        
    }

    // 달력 다음달
    const handleNextMonth = () => {
        let r_month = parseInt(month) + 1;
        let r_year = year;

        if(r_month > 12){
            r_month = 1;
            r_year = parseInt(r_year) + 1;
        }

        $("#year").val(r_year);
        $("#month").val(r_month);
        $("#frmCal").submit();
    }

    function onSubmitSearch(event){
        event.preventDefault();
                
    }


    let lastDay = new Date(year,month,0).getDate();
    let firstDay = new Date(year,calMonth,1);
    let firstYoil = new Date(year,calMonth,1).getDay();


    let arrMax = parseInt(lastDay) + parseInt(firstYoil);
    //alert(arrMax)
    if(arrMax % 7 >0){
        arrMax = (7 - arrMax % 7) + parseInt(arrMax);
    }
    //alert(firstYoil + " + " + lastDay + " = " +  arrMax);


    useEffect(() => {
        let DateYm = year + "-" + String(month).padStart(2,'0');
        let bodyPage = {
            DateYm: DateYm,
            loginId: loginId
        }

        axios.post('/api/store/pay/calDetail', bodyPage).then(response => {
            setSchedules(response.data);
          })
          .catch(error => {
            console.error(error);
          });
    }, []);


    useEffect(()=>{
        
        let bodyPage = {
            DateYmd: selDate,
            loginId: loginId
        }

        axios.post('/api/store/pay/calDetailList', bodyPage).then(function(res){
            setViewContent(res.data);
        }) 

    },[selDate]);

    
    for(let n=1; n<=arrMax; n++){
        let outYmd = firstYoil < n && (lastDay+firstYoil) >= n ? year+'-'+String(month).padStart(2,'0')+"-"+String(n - firstYoil).padStart(2,'0') : '';
        let outDay = firstYoil < n && (lastDay+firstYoil) >= n ? (n - firstYoil) : '';

        calArr.push({no:n,DateYmd:outYmd,'Day':outDay});
    }
   

    const weeks = [];
        
    while (calArr.length) {
        weeks.push(calArr.splice(0, 7));
    }


    const Board = ({
        nums,
        pg_group,
        cpid,
        username,
        chkDate,
        calculate_date_re,
        income,
        cancel_date_re,
        orderno,
        productname,
        amount,
        St_fee,
        feeStore

    }) => {
        
        return (
            <>
            <tr className="">
                <td className="point_link">{nums}</td>
                <td>{PgName(pg_group)}</td>
                <td>{cpid}</td>
                <td>{chkDate}</td>
                <td>{calculate_date_re} <span style={{'color':'red','marginLeft':'3px'}}>(+{income})</span></td>
                <td>{cancel_date_re}</td>
                <td>{orderno}</td>
                <td>{username}</td>
                <td>{productname}</td>
                <td>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</td>
                <td>{feeStore} %</td>
                <td>{(amount-St_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</td>
                <td>{St_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</td>
            </tr>
            </>
        )

    }
    

    const CalDetail = ({
        DateYmd
    }) => {
        if( DateYmd !== undefined && DateYmd !== ""){

            let bodyPage = {
                DateYmd: DateYmd,
                loginId: loginId
            }

            
            if(schedules.find(s => s.chkDate === DateYmd)){
                const schedulesSet = schedules.find(s => s.chkDate === DateYmd);
                let fee_hap = parseInt(schedulesSet.E_fee) + parseInt(schedulesSet.EC_fee);
                
                return(
                    <ul className="desc_list">
                        <li>
                            <div className="left">
                                <strong>통합정산</strong>
                                <p>{fee_hap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                            </div>
                            <div className="right">
                                <button type="button" className="more_ico" onClick={()=>{detailPop(DateYmd)}}></button>
                            </div>
                        </li>    
                    </ul>
                )
            }
        }

    }

    const CalTd = ({
        no,
        DateYmd,
        Day
    }) => {
        return (
            <td >
                <div className='date_wrap'>
                    <span className="date">{Day}</span>
                    <CalDetail 
                        DateYmd={DateYmd}
                    />        
                </div>     
            </td>
        )
    }

    
    function detailPop(DateYmd) {
        set_selDate(DateYmd);

        $('.pop_common').show();
    }

    function closePopup() {
        $('.pop_common').hide();
    }

    
    $(document).ready(function(){

        // $('.calendar_table td').on('click', function () {
        //     $('.calendar_table td').removeClass('on');
        //     $(this).addClass('on');

        // });

        $('.calendar_table td .desc_list').parents('td').addClass('active')
      
    })


    return (
        <>
        <div id="wrap">
            <Header/>

            
            <div id="container" data-menu-page="set_calendar">
                <h3 className="page_ttl">정산승인달력</h3>
                
                <form method="get" id="frmCal">
                    <div className="form_item">
                        <h3 className="label">* 정산승인달력</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>지급월</th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="sel_box wd_auto">
                                                    <select id="year" name="year">
                                                        <option>년</option>
                                                        {
                                                            yearInfo.map((v,index) => {
                                                                return (
                                                                    <SelYear
                                                                        setYear={v.year}
                                                                        nowYear={year}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    

                                                </div>

                                                <div className="sel_box wd_auto">
                                                    <select id="month" name="month">
                                                    <option>월</option>
                                                        {
                                                            monthInfo.map((v,index) => {
                                                                return (
                                                                    <SelMonth
                                                                        setMonth={v.month}
                                                                        nowMonth={month}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="top_table_btn">
                        <button type="submit" className=" blue btn_st_01">내역조회</button>
                    </div>
                </form>

                <div className="calendar_box">
                    <table className="calendar_table">
                        <thead>
                            <tr>
                                <th colSpan="7">
                                    <div className="head_nav">
                                        <button type="button" className="btn prev" onClick={handlePrevMonth}></button>
                                        <h3>{year}.{String(month).padStart(2,'0')}</h3>
                                        <button type="button" className="btn next" onClick={handleNextMonth}></button>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th className="sun">
                                    일요일
                                </th>
                                <th>
                                    월요일
                                </th>
                                <th>
                                    화요일
                                </th>
                                <th>
                                    수요일
                                </th>
                                <th>
                                    목요일
                                </th>
                                <th>
                                    금요일
                                </th>
                                <th className="sat">
                                    토요일
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            weeks.map((week, i) => {

                                return (
                                    <tr key={i}>
                                    {week.map((v, index) => {
                                        //alert(i + " : " + index + " = " + v.no)
                                        return (
                                            <CalTd
                                                key={index}
                                                no={v.no}
                                                DateYmd={v.DateYmd}
                                                Day={v.Day}
                                                
                                            />
                                        )                                    
                                    })}
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                        {/*
                        <tbody>
                            <tr>
                                <td><span className="date">2</span>
                                    <ul className="desc_list">
                                        <li>
                                            <div className="left">
                                                <strong>통합정산</strong>
                                                <p>97원</p>
                                            </div>
                                            <div className="right">
                                                <button type="button" className="more_ico" onClick={detailPop}></button>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                </td>
                                <td><span className="date">3</span></td>
                                <td><span className="date">4</span></td>
                                <td><span className="date">5</span></td>
                                <td><span className="date">6</span></td>
                                <td><span className="date">7</span></td>
                                <td><span className="date">8</span></td>
                            </tr>
                        </tbody>
                        */}
                    </table>

                </div>

            </div>

            <div className=" pop_common ">
                <div className="pop_cont">
                    <div className="pop_top">
                        <h3 className="pop_ttl">상세보기</h3>
                        <button className="btn_closed" onClick={closePopup} ><img src={re_closebtn_w} alt="닫기" /></button>
                        
                    </div>
                    <div className="pop_item">
                        <div className="pop_inner">
                            <div className="layout_wrap">
                                <div className="table_layout02_wrap scrollable_table">
                                    <table className="table_layout02">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>PG</th>
                                                <th>CPID</th>
                                                <th>결제일자</th>
                                                <th>승인일자</th>
                                                <th>취소일자</th>
                                                <th>주문번호</th>
                                                <th>구매자</th>
                                                <th>상품명</th>
                                                <th>거래금액</th>
                                                <th>수수료율</th>
                                                <th>수수료</th>
                                                <th>지급액</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                        {
                                            viewContent.map((v, index) => {

                                                return(
                                                    <Board
                                                        key={v.idx}
                                                        nums={index+1}
                                                        selDate={selDate}
                                                        pg_group={v.pg_group}
                                                        cpid={v.cpid}
                                                        username={v.username}
                                                        chkDate={v.chkDate}
                                                        calculate_date_re={v.calculate_date_re}
                                                        income={v.income}
                                                        cancel_date_re={v.cancel_date_re}
                                                        orderno={v.orderno}
                                                        productname={v.productname}
                                                        amount={v.amount}
                                                        feeStore={v.feeStore}
                                                        St_fee={v.St_fee}
                                                    />
                                                )
                                            })
                                        }
                                            
                                            {/*

                                            <tr className="red_bg">
                                                <td className="point_link">1</td>
                                                <td>페이조아</td>
                                                <td className="point_link">GP210518</td>
                                                <td>CTS17547</td>
                                                <td>매출</td>
                                                <td>2022.07.01</td>
                                                <td>2022-06-30</td>
                                                <td>2022-06-29</td>
                                                <td>20220630101427</td>
                                                <td>이미란</td>
                                                <td>테스트구매</td>
                                                <td>10원</td>
                                                <td>1%</td>
                                                <td>0원</td>
                                                <td>0원</td>
                                                <td>0원</td>
                                                <td>10원</td>
                                            </tr>

                                            <tr className="">
                                                <td className="point_link">2</td>
                                                <td>페이조아</td>
                                                <td className="point_link">GP210518</td>
                                                <td>CTS17547</td>
                                                <td>매입</td>
                                                <td>2022.07.19</td>
                                                <td>2022-07-18</td>
                                                <td></td>
                                                <td>20220718165806</td>
                                                <td>김평진</td>
                                                <td>구매테스트</td>
                                                <td>10원</td>
                                                <td>1%</td>
                                                <td>0원</td>
                                                <td>0원</td>
                                                <td>0원</td>
                                                <td>10원</td>
                                            </tr>
                                            */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            {
                                /*
                                viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                                */
                            }
                            
                            <div className="btn_box">
                                <a className="blue btn_st_02 w_230" onClick={closePopup} >확인</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dim"></div>
            </div>



            
            
        </div>

        <Footer/>
        </>
    )

    
}
export default StorePayCalculateCalendar