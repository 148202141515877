import React from "react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import IndexPage from "./components/views/IndexPage/IndexPage";
import SiteAdmin from "./components/views/SiteAdmin/SiteAdmin";
import SiteAdminMain from "./components/views/SiteAdmin/_main/Main/Main";
import SiteAdminMainTerminal from "./components/views/SiteAdmin/_main/Main/MainTerminal";

import SiteAdminManagementList from "./components/views/SiteAdmin/_management/List/List";
import SiteAdminManagementMod from "./components/views/SiteAdmin/_management/Mod/Mod";
import SiteAdminManagementTest from "./components/views/SiteAdmin/_management/List/Test";
import SiteAdminManagementForm from "./components/views/SiteAdmin/_management/Form/Form";
import SiteAdminManagementDistributorList from "./components/views/SiteAdmin/_management/DistributorList/List";
import SiteAdminManagementDistributorForm from "./components/views/SiteAdmin/_management/DistributorForm/Form";
import SiteAdminManagementDistributorMod from "./components/views/SiteAdmin/_management/DistributorMod/Mod";
import SiteAdminManagementAgencyList from "./components/views/SiteAdmin/_management/AgencyList/List";
import SiteAdminManagementAgencyForm from "./components/views/SiteAdmin/_management/AgencyForm/Form";
import SiteAdminManagementAgencyMod from "./components/views/SiteAdmin/_management/AgencyMod/Mod";

import SiteAdminManagementStoreList from "./components/views/SiteAdmin/_management/StoreList/List";
import SiteAdminManagementStoreForm from "./components/views/SiteAdmin/_management/StoreForm/Form";
import SiteAdminManagementStoreMod from "./components/views/SiteAdmin/_management/StoreMod/Mod.js";

import SiteAdminManagementStoreTerminalList from "./components/views/SiteAdmin/_management/StoreList/Terminal_List";
import SiteAdminManagementStoreTerminalForm from "./components/views/SiteAdmin/_management/StoreForm/TerminalForm";
import SiteAdminManagementStoreTerminalMod from "./components/views/SiteAdmin/_management/StoreMod/TerminalMod.js";

import SiteAdminTerminalDirectPaymentList from "./components/views/SiteAdmin/_terminal/DirectPaymentList/List";
import SiteAdminTerminalDirectPaymentForm from "./components/views/SiteAdmin/_terminal/DirectPaymentForm/Form";
import SiteAdminTerminalDirectPaymentMod from "./components/views/SiteAdmin/_terminal/DirectPaymentMod/Mod";

import SiteAdminManageManageLog from "./components/views/SiteAdmin/_manage/ManageLog/List"; //접속로그관리
import SiteAdminManageOperateLog from "./components/views/SiteAdmin/_manage/OperateLog/List"; //운영로그관리

import SiteAdminManageBankList from "./components/views/SiteAdmin/_manage/BankList/List";
import SiteAdminManageHoliday from "./components/views/SiteAdmin/_manage/Holiday/List";

import SiteAdminManageSetupAdminList from "./components/views/SiteAdmin/_manage/SetupAdminList/List";
import SiteAdminManageSetupAdminForm from "./components/views/SiteAdmin/_manage/SetupAdminForm/Form";
import SiteAdminManageSetupAdminMod from "./components/views/SiteAdmin/_manage/SetupAdminMod/Mod";
import SiteAdminManageAccessIpList from "./components/views/SiteAdmin/_manage/AccessIp/List";
import SiteAdminManageAccessIpForm from "./components/views/SiteAdmin/_manage/AccessIp/Form";
import SiteAdminManageNoticeList from "./components/views/SiteAdmin/_manage/Notice/List";
import SiteAdminManageNoticeForm from "./components/views/SiteAdmin/_manage/Notice/Form";
import SiteAdminManageNoticeView from "./components/views/SiteAdmin/_manage/Notice/View";

import SiteAdminManageFaqList from "./components/views/SiteAdmin/_manage/Faq/List";
import DistributorManageFaqList from "./components/views/Distributor/_manage/Faq/List";
import AgencyManageFaqList from "./components/views/Agency/_manage/Faq/List";
import StoreManageFaqList from "./components/views/Store/_management/Faq/List";

import SiteAdminManageFaqForm from "./components/views/SiteAdmin/_manage/Faq/Form";
import SiteAdminManageFaqMod from "./components/views/SiteAdmin/_manage/Faq/Mod";
import SiteAdminManageFaqView from "./components/views/SiteAdmin/_manage/Faq/View";
import DistributorManageFaqView from "./components/views/Distributor/_manage/Faq/View";
import AgencyManageFaqView from "./components/views/Agency/_manage/Faq/View";
import StoreManageFaqView from "./components/views/Store/_management/Faq/View";

import SiteAdminManageQnaList from "./components/views/SiteAdmin/_manage/Qna/List";
import DistributorManageQnaList from "./components/views/Distributor/_manage/Qna/List";
import AgencyManageQnaList from "./components/views/Agency/_manage/Qna/List";
import StoreManageQnaList from "./components/views/Store/_management/Qna/List";

import SiteAdminManageQnaForm from "./components/views/SiteAdmin/_manage/Qna/Form";
import DistributorManageQnaForm from "./components/views/Distributor/_manage/Qna/Form";
import AgencyManageQnaForm from "./components/views/Agency/_manage/Qna/Form";
import StoreManageQnaForm from "./components/views/Store/_management/Qna/Form";

import SiteAdminManageQnaMod from "./components/views/SiteAdmin/_manage/Qna/Mod";
import DistributorManageQnaMod from "./components/views/Distributor/_manage/Qna/Mod";
import AgencyManageQnaMod from "./components/views/Agency/_manage/Qna/Mod";
import StoreManageQnaMod from "./components/views/Store/_management/Qna/Mod";

import SiteAdminManageQnaView from "./components/views/SiteAdmin/_manage/Qna/View";
import DistributorManageQnaView from "./components/views/Distributor/_manage/Qna/View";
import AgencyManageQnaView from "./components/views/Agency/_manage/Qna/View";
import StoreManageQnaView from "./components/views/Store/_management/Qna/View";

import MbranchManageFaqList from "./components/views/Mbranch/_manage/Faq/List";
import MbranchManageFaqForm from "./components/views/Mbranch/_manage/Faq/Form";
import MbranchManageFaqMod from "./components/views/Mbranch/_manage/Faq/Mod";
import MbranchManageFaqView from "./components/views/Mbranch/_manage/Faq/View";

import MbranchManageQnaList from "./components/views/Mbranch/_manage/Qna/List";
import MbranchManageQnaForm from "./components/views/Mbranch/_manage/Qna/Form";
import MbranchManageQnaMod from "./components/views/Mbranch/_manage/Qna/Mod";
import MbranchManageQnaView from "./components/views/Mbranch/_manage/Qna/View";

import SiteAdminManageSetupLoginList from "./components/views/SiteAdmin/_manage/SetupLogin/List";
import SiteAdminAdminSetting from "./components/views/SiteAdmin/_admin/Setting/Info";

// 발송 거래내역
import SiteAdminPayHomepageList from "./components/views/SiteAdmin/_pay/HomepageList/List";

// 가맹점 거래내역
import SiteAdminPayTransactionHistoryList from "./components/views/SiteAdmin/_pay/TransactionHistoryList/List";

// 가맹점 거래내역 상세
import SiteAdminPayTransactionHistoryView from "./components/views/SiteAdmin/_pay/TransactionHistoryView/View";

// 가맹점 거래내역 엑셀
import SiteAdminPayTransactionHistoryExcel from "./components/views/SiteAdmin/_pay/TransactionHistoryExcel/Excel";

import SiteAdminPayTransactionHistoryCancelPart from "./components/views/SiteAdmin/_pay/TransactionHistoryCancelPart/CancelPart";
import SiteAdminPayTransactionHistoryCancel from "./components/views/SiteAdmin/_pay/TransactionHistoryCancel/Cancel";

import SiteAdminPayPayErrorList from "./components/views/SiteAdmin/_pay/PayErrorList/List";
import SiteAdminPayPayDeductionList from "./components/views/SiteAdmin/_pay/PayDeduction/List";

import SiteAdminPayPayReserveList from "./components/views/SiteAdmin/_pay/PayReserve/List";
import SiteAdminPayPayReserveView from "./components/views/SiteAdmin/_pay/PayReserveView/View";

import SiteAdminPayPayTotalList from "./components/views/SiteAdmin/_pay/PayTotal/List";

// 터미널 ########

// 가맹점 거래내역 엑셀
import SiteAdminPayTransactionHistoryExcelTerminal from "./components/views/SiteAdmin/_pay_terminal/TransactionHistoryExcel/Excel";
import SiteAdminPayTransactionHistoryCancelPartTerminal from "./components/views/SiteAdmin/_pay_terminal/TransactionHistoryCancelPart/CancelPart";
import SiteAdminPayTransactionHistoryCancelTerminal from "./components/views/SiteAdmin/_pay_terminal/TransactionHistoryCancel/Cancel";

// ########################	거래조회 터미널	########################
import SiteAdminPayTransactionHistoryTerminalList from "./components/views/SiteAdmin/_pay_terminal/TransactionHistoryList/List";
import SiteAdminPayTransactionHistoryTerminalView from "./components/views/SiteAdmin/_pay_terminal/TransactionHistoryView/View";
import SiteAdminPayPayErrorTerminalList from "./components/views/SiteAdmin/_pay_terminal/PayErrorList/List";
import SiteAdminPayPayDeductionTerminalList from "./components/views/SiteAdmin/_pay_terminal/PayDeduction/List";
import SiteAdminPayPayReserveTerminalList from "./components/views/SiteAdmin/_pay_terminal/PayReserve/List";
import SiteAdminPayPayReserveTerminalView from "./components/views/SiteAdmin/_pay_terminal/PayReserveView/View";
import SiteAdminPayPayTotalTerminalList from "./components/views/SiteAdmin/_pay_terminal/PayTotal/List";
// ########################	거래조회 터미널	######################## //

import SiteAdminCalculateCalendar from "./components/views/SiteAdmin/_settlement/CalculateCalendar/List";
import SiteAdminSettlementStore from "./components/views/SiteAdmin/_settlement/Store/List";
import SiteAdminSettlementStoreResult from "./components/views/SiteAdmin/_settlement/StoreResult/List";
import SiteAdminSettlementStoreResultOpcition from "./components/views/SiteAdmin/_settlement/StoreResultOpcition/List";
import SiteAdminSettlementBr from "./components/views/SiteAdmin/_settlement/Br/List";
import SiteAdminSettlementBrResult from "./components/views/SiteAdmin/_settlement/BrResult/List";
import SiteAdminSettlementDi from "./components/views/SiteAdmin/_settlement/Di/List";
import SiteAdminSettlementDiResult from "./components/views/SiteAdmin/_settlement/DiResult/List";
import SiteAdminSettlementAg from "./components/views/SiteAdmin/_settlement/Ag/List";
import SiteAdminSettlementAgResult from "./components/views/SiteAdmin/_settlement/AgResult/List";
import SiteAdminSettlementDataUpload from "./components/views/SiteAdmin/_settlement/Data_upload/List";
import SiteAdminSettlementDataUploadForm from "./components/views/SiteAdmin/_settlement/Data_upload_form/List";
import SiteAdminSettlementDataUploadIns from "./components/views/SiteAdmin/_settlement/Data_upload_ins/List";
import SiteAdminSettlementDataUploadHome from "./components/views/SiteAdmin/_settlement/Data_upload_home/List";

// ############## 정산관리 ###############
import SiteAdminCalculateCalendarTerminal from "./components/views/SiteAdmin/_settlement_terminal/CalculateCalendar/List";
import SiteAdminSettlementStoreTerminal from "./components/views/SiteAdmin/_settlement_terminal/Store/List";
import SiteAdminSettlementStoreResultTerminal from "./components/views/SiteAdmin/_settlement_terminal/StoreResult/List";
import SiteAdminSettlementStoreResultOpcitionTerminal from "./components/views/SiteAdmin/_settlement_terminal/StoreResultOpcition/List";
import SiteAdminSettlementBrTerminal from "./components/views/SiteAdmin/_settlement_terminal/Br/List";
import SiteAdminSettlementBrResultTerminal from "./components/views/SiteAdmin/_settlement_terminal/BrResult/List";
import SiteAdminSettlementDiTerminal from "./components/views/SiteAdmin/_settlement_terminal/Di/List";
import SiteAdminSettlementDiResultTerminal from "./components/views/SiteAdmin/_settlement_terminal/DiResult/List";
import SiteAdminSettlementAgTerminal from "./components/views/SiteAdmin/_settlement_terminal/Ag/List";
import SiteAdminSettlementAgResultTerminal from "./components/views/SiteAdmin/_settlement_terminal/AgResult/List";
import SiteAdminSettlementDataUploadTerminal from "./components/views/SiteAdmin/_settlement_terminal/Data_upload/List";
import SiteAdminSettlementDataUploadFormTerminal from "./components/views/SiteAdmin/_settlement_terminal/Data_upload_form/List";
import SiteAdminSettlementDataUploadInsTerminal from "./components/views/SiteAdmin/_settlement_terminal/Data_upload_ins/List";
import SiteAdminSettlementDataUploadHomeTerminal from "./components/views/SiteAdmin/_settlement_terminal/Data_upload_home/List";

import SiteAdminPGList from "./components/views/SiteAdmin/_manage/_pg/List";
import SiteAdminPGForm from "./components/views/SiteAdmin/_manage/_pg/Form";
import SiteAdminPGTerminalList from "./components/views/SiteAdmin/_manage/_pg_termial/List";
import SiteAdminPGTerminalForm from "./components/views/SiteAdmin/_manage/_pg_termial/Form";

import SiteStore from "./components/views/Store/Store";
import StoreMain from "./components/views/Store/_main/Main/main";

import StorePayCalculateCalendar from "./components/views/Store/_pay/CalculateCalendar/List";
import StorePayTransactionHistoryList from "./components/views/Store/_pay/TransactionHistoryList/List";
import StorePayTransactionHistoryForm from "./components/views/Store/_pay/TransactionHistoryForm/Form";
import StorePayTransactionHistoryView from "./components/views/Store/_pay/TransactionHistoryView/View";
import StorePayPayTotalList from "./components/views/Store/_pay/payTotalList/List";
import StorePayEasyPaymentList from "./components/views/Store/_pay/easyPaymentList/List";

import StorePayCalculateCalendarTerminal from "./components/views/Store/_pay_terminal/CalculateCalendar/List";
import StorePayTransactionHistoryListTerminal from "./components/views/Store/_pay_terminal/TransactionHistoryList/List";
import StorePayTransactionHistoryFormTerminal from "./components/views/Store/_pay_terminal/TransactionHistoryForm/Form";
import StorePayTransactionHistoryViewTerminal from "./components/views/Store/_pay_terminal/TransactionHistoryView/View";
import StorePayPayTotalListTerminal from "./components/views/Store/_pay_terminal/payTotalList/List";
import StorePayEasyPaymentListTerminal from "./components/views/Store/_pay_terminal/easyPaymentList/List";

import StoreManagementGoodsList from "./components/views/Store/_management/goodsList/List";
import StoreManagementGoodsForm from "./components/views/Store/_management/goodsForm/Form";
import StoreManagementGoodsMod from "./components/views/Store/_management/goodsMod/Mod";

import StoreManagementNoticeList from "./components/views/Store/_management/noticeList/List";
import StoreManagementNoticeView from "./components/views/Store/_management/noticeView/View";

import StoreAdminSettingList from "./components/views/Store/_admin/settingList/List";

import StoreManagementPayFormStep1 from "./components/views/Store/_management/payFormStep1/Step1";
import StoreManagementPayFormStep2 from "./components/views/Store/_management/payFormStep2/Step2";
import StoreManagementPayFormStep3 from "./components/views/Store/_management/payFormStep3/Step3";

//지사
import Mbranch from "./components/views/Mbranch/Mbranch";
import MbranchMain from "./components/views/Mbranch/_main/Main/Main";
import MbranchMainTerminal from "./components/views/Mbranch/_main/Main/MainTerminal";
import MbranchManageManageLog from "./components/views/Mbranch/_manage/ManageLog/List";
import MbranchManageNoticeList from "./components/views/Mbranch/_manage/Notice/List";
import MbranchManageNoticeForm from "./components/views/Mbranch/_manage/Notice/Form";
import MbranchManageNoticeView from "./components/views/Mbranch/_manage/Notice/View";
import MbranchManageHoliday from "./components/views/Mbranch/_manage/Holiday/List";

import MbranchAdminSetting from "./components/views/Mbranch/_admin/Setting/Info";

// 지사 정보
import MbranchPayTransactionHistoryList from "./components/views/Mbranch/_pay/TransactionHistoryList/List";
import MbranchPayTransactionHistoryView from "./components/views/Mbranch/_pay/TransactionHistoryView/View";
import MbranchPayPayErrorList from "./components/views/Mbranch/_pay/PayErrorList/List";
import MbranchPayPayDeductionList from "./components/views/Mbranch/_pay/PayDeduction/List";
import MbranchPayPayReserveList from "./components/views/Mbranch/_pay/PayReserve/List";
import MbranchPayPayTotalList from "./components/views/Mbranch/_pay/PayTotal/List";

import MbranchPayTransactionHistoryListTerminal from "./components/views/Mbranch/_pay_terminal/TransactionHistoryList/List";
import MbranchPayTransactionHistoryViewTerminal from "./components/views/Mbranch/_pay_terminal/TransactionHistoryView/View";
import MbranchPayPayErrorListTerminal from "./components/views/Mbranch/_pay_terminal/PayErrorList/List";
import MbranchPayPayDeductionListTerminal from "./components/views/Mbranch/_pay_terminal/PayDeduction/List";
import MbranchPayPayReserveListTerminal from "./components/views/Mbranch/_pay_terminal/PayReserve/List";
import MbranchPayPayTotalListTerminal from "./components/views/Mbranch/_pay_terminal/PayTotal/List";

import MbranchSettlementStore from "./components/views/Mbranch/_settlement/Store/List";
import MbranchSettlementStoreResult from "./components/views/Mbranch/_settlement/StoreResult/List";
import MbranchSettlementBr from "./components/views/Mbranch/_settlement/Br/List";
import MbranchSettlementBrResult from "./components/views/Mbranch/_settlement/BrResult/List";
import MbranchSettlementDi from "./components/views/Mbranch/_settlement/Di/List";
import MbranchSettlementDiResult from "./components/views/Mbranch/_settlement/DiResult/List";
import MbranchSettlementAg from "./components/views/Mbranch/_settlement/Ag/List";
import MbranchSettlementAgResult from "./components/views/Mbranch/_settlement/AgResult/List";

import MbranchSettlementStoreTerminal from "./components/views/Mbranch/_settlement_terminal/Store/List";
import MbranchSettlementStoreResultTerminal from "./components/views/Mbranch/_settlement_terminal/StoreResult/List";
import MbranchSettlementBrTerminal from "./components/views/Mbranch/_settlement_terminal/Br/List";
import MbranchSettlementBrResultTerminal from "./components/views/Mbranch/_settlement_terminal/BrResult/List";
import MbranchSettlementDiTerminal from "./components/views/Mbranch/_settlement_terminal/Di/List";
import MbranchSettlementDiResultTerminal from "./components/views/Mbranch/_settlement_terminal/DiResult/List";
import MbranchSettlementAgTerminal from "./components/views/Mbranch/_settlement_terminal/Ag/List";
import MbranchSettlementAgResultTerminal from "./components/views/Mbranch/_settlement_terminal/AgResult/List";

import MbranchManagementDistributorList from "./components/views/Mbranch/_management/DistributorList/List";
import MbranchManagementAgencyList from "./components/views/Mbranch/_management/AgencyList/List";
import MbranchManagementStoreList from "./components/views/Mbranch/_management/StoreList/List";
import MbranchManagementStoreTerminalList from "./components/views/Mbranch/_management/StoreList/Terminal_List";

import MbranchManagementStoreForm from "./components/views/Mbranch/_management/StoreForm/Form";
import MbranchManagementStoreTerminalForm from "./components/views/Mbranch/_management/StoreForm/TerminalForm";

//총판
import Distributor from "./components/views/Distributor/Distributor";
import DistributorMain from "./components/views/Distributor/_main/Main/Main";
import DistributorMainTerminal from "./components/views/Distributor/_main/Main/MainTerminal";

import DistributorManageManageLog from "./components/views/Distributor/_manage/ManageLog/List";
import DistributorManageNoticeList from "./components/views/Distributor/_manage/Notice/List";
import DistributorManageNoticeForm from "./components/views/Distributor/_manage/Notice/Form";
import DistributorManageNoticeView from "./components/views/Distributor/_manage/Notice/View";

import AgencyManageNoticeView from "./components/views/Agency/_manage/Notice/View";
import DistributorManageHoliday from "./components/views/Distributor/_manage/Holiday/List";
import DistributorAdminSetting from "./components/views/Distributor/_admin/Setting/Info";

import DistributorPayTransactionHistoryList from "./components/views/Distributor/_pay/TransactionHistoryList/List";
import DistributorPayTransactionHistoryView from "./components/views/Distributor/_pay/TransactionHistoryView/View";
import DistributorPayPayErrorList from "./components/views/Distributor/_pay/PayErrorList/List";
import DistributorPayPayDeductionList from "./components/views/Distributor/_pay/PayDeduction/List";
import DistributorPayPayReserveList from "./components/views/Distributor/_pay/PayReserve/List";
import DistributorPayPayTotalList from "./components/views/Distributor/_pay/PayTotal/List";

import DistributorPayTransactionHistoryListTerminal from "./components/views/Distributor/_pay_terminal/TransactionHistoryList/List";
import DistributorPayTransactionHistoryViewTerminal from "./components/views/Distributor/_pay_terminal/TransactionHistoryView/View";
import DistributorPayPayErrorListTerminal from "./components/views/Distributor/_pay_terminal/PayErrorList/List";
import DistributorPayPayDeductionListTerminal from "./components/views/Distributor/_pay_terminal/PayDeduction/List";
import DistributorPayPayReserveListTerminal from "./components/views/Distributor/_pay_terminal/PayReserve/List";
import DistributorPayPayTotalListTerminal from "./components/views/Distributor/_pay_terminal/PayTotal/List";

import DistributorSettlementStore from "./components/views/Distributor/_settlement/Store/List";
import DistributorSettlementStoreResult from "./components/views/Distributor/_settlement/StoreResult/List";
import DistributorSettlementAg from "./components/views/Distributor/_settlement/Ag/List";
import DistributorSettlementAgResult from "./components/views/Distributor/_settlement/AgResult/List";

import DistributorSettlementStoreTerminal from "./components/views/Distributor/_settlement_terminal/Store/List";
import DistributorSettlementStoreResultTerminal from "./components/views/Distributor/_settlement_terminal/StoreResult/List";
import DistributorSettlementAgTerminal from "./components/views/Distributor/_settlement_terminal/Ag/List";
import DistributorSettlementAgResultTerminal from "./components/views/Distributor/_settlement_terminal/AgResult/List";

import DistributorManagementAgencyList from "./components/views/Distributor/_management/AgencyList/List";
import DistributorManagementStoreList from "./components/views/Distributor/_management/StoreList/List";

//대리점
import Agency from "./components/views/Agency/Agency";
import AgencyMain from "./components/views/Agency/_main/Main/Main";
import AgencyMainTerminal from "./components/views/Agency/_main/Main/MainTerminal";

import AgencyManageManageLog from "./components/views/Agency/_manage/ManageLog/List";
import AgencyManageNoticeList from "./components/views/Agency/_manage/Notice/List";
import AgencyManageNoticeForm from "./components/views/Agency/_manage/Notice/Form";
import AgencyManageHoliday from "./components/views/Agency/_manage/Holiday/List";
import AgencyAdminSetting from "./components/views/Agency/_admin/Setting/Info";

import AgencyPayTransactionHistoryList from "./components/views/Agency/_pay/TransactionHistoryList/List";
import AgencyPayTransactionHistoryView from "./components/views/Agency/_pay/TransactionHistoryView/View";
import AgencyPayPayErrorList from "./components/views/Agency/_pay/PayErrorList/List";
import AgencyPayPayDeductionList from "./components/views/Agency/_pay/PayDeduction/List";
import AgencyPayPayReserveList from "./components/views/Agency/_pay/PayReserve/List";
import AgencyPayPayTotalList from "./components/views/Agency/_pay/PayTotal/List";
import AgencyPayTransactionHistoryListTerminal from "./components/views/Agency/_pay_terminal/TransactionHistoryList/List";
import AgencyPayTransactionHistoryViewTerminal from "./components/views/Agency/_pay_terminal/TransactionHistoryView/View";
import AgencyPayPayErrorListTerminal from "./components/views/Agency/_pay_terminal/PayErrorList/List";
import AgencyPayPayDeductionListTerminal from "./components/views/Agency/_pay_terminal/PayDeduction/List";
import AgencyPayPayReserveListTerminal from "./components/views/Agency/_pay_terminal/PayReserve/List";
import AgencyPayPayTotalListTerminal from "./components/views/Agency/_pay_terminal/PayTotal/List";

import AgencySettlementStore from "./components/views/Agency/_settlement/Store/List";
import AgencySettlementStoreResult from "./components/views/Agency/_settlement/StoreResult/List";
import AgencySettlementStoreTerminal from "./components/views/Agency/_settlement_terminal/Store/List";
import AgencySettlementStoreResultTerminal from "./components/views/Agency/_settlement_terminal/StoreResult/List";

import AgencyManagementStoreList from "./components/views/Agency/_management/StoreList/List";

import ScrollToTop from "./components/views/include/ScrollToTop";
import SiteAdminPayTransactionHistoryTerminalPop from "./components/views/SiteAdmin/_pay_terminal/transactionHistoryPop/Pop";

import axios from "axios";
console.log("process.env.NODE_ENV ", process.env.NODE_ENV);
axios.defaults.baseURL =
  process.env.NODE_ENV === "development" && "http://localhost:5001";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/SiteAdmin" element={<SiteAdmin />} />
        <Route path="/SiteAdmin/_main/main" element={<SiteAdminMain />} />
        <Route
          path="/SiteAdmin/_main/main_terminal"
          element={<SiteAdminMainTerminal />}
        />
        <Route
          path="/SiteAdmin/_management/list"
          element={<SiteAdminManagementList />}
        />
        <Route
          path="/SiteAdmin/_management/test"
          element={<SiteAdminManagementTest />}
        />
        <Route
          path="/SiteAdmin/_management/form"
          element={<SiteAdminManagementForm />}
        />
        <Route
          path="/SiteAdmin/_management/mod"
          element={<SiteAdminManagementMod />}
        />
        <Route
          path="/SiteAdmin/_management/DistributorList"
          element={<SiteAdminManagementDistributorList />}
        />
        <Route
          path="/SiteAdmin/_management/DistributorForm"
          element={<SiteAdminManagementDistributorForm />}
        />
        <Route
          path="/SiteAdmin/_management/DistributorMod"
          element={<SiteAdminManagementDistributorMod />}
        />
        <Route
          path="/SiteAdmin/_management/AgencyList"
          element={<SiteAdminManagementAgencyList />}
        />
        <Route
          path="/SiteAdmin/_management/AgencyForm"
          element={<SiteAdminManagementAgencyForm />}
        />
        <Route
          path="/SiteAdmin/_management/AgencyMod"
          element={<SiteAdminManagementAgencyMod />}
        />
        <Route
          path="/SiteAdmin/_management/StoreList"
          element={<SiteAdminManagementStoreList />}
        />
        <Route
          path="/SiteAdmin/_management/StoreForm"
          element={<SiteAdminManagementStoreForm />}
        />
        <Route
          path="/SiteAdmin/_management/StoreMod"
          element={<SiteAdminManagementStoreMod />}
        />
        <Route
          path="/SiteAdmin/_management/StoreTerminalList"
          element={<SiteAdminManagementStoreTerminalList />}
        />
        <Route
          path="/SiteAdmin/_management/StoreTerminalForm"
          element={<SiteAdminManagementStoreTerminalForm />}
        />
        <Route
          path="/SiteAdmin/_management/StoreTerminalMod"
          element={<SiteAdminManagementStoreTerminalMod />}
        />
        <Route
          path="/SiteAdmin/_terminal/DirectPaymentList"
          element={<SiteAdminTerminalDirectPaymentList />}
        />
        <Route
          path="/SiteAdmin/_terminal/DirectPaymentForm"
          element={<SiteAdminTerminalDirectPaymentForm />}
        />
        <Route
          path="/SiteAdmin/_terminal/DirectPaymentMod"
          element={<SiteAdminTerminalDirectPaymentMod />}
        />
        <Route
          path="/SiteAdmin/_manage/ManageLog"
          element={<SiteAdminManageManageLog />}
        />{" "}
        {/* 접속로그관리 */}
        <Route
          path="/SiteAdmin/_manage/OperateLog"
          element={<SiteAdminManageOperateLog />}
        />{" "}
        {/* 운영로그관리 */}
        <Route
          path="/SiteAdmin/_manage/BankList"
          element={<SiteAdminManageBankList />}
        />
        <Route
          path="/SiteAdmin/_manage/Holiday"
          element={<SiteAdminManageHoliday />}
        />
        <Route
          path="/SiteAdmin/_manage/SetupAdminList"
          element={<SiteAdminManageSetupAdminList />}
        />
        <Route
          path="/SiteAdmin/_manage/SetupAdminForm"
          element={<SiteAdminManageSetupAdminForm />}
        />
        <Route
          path="/SiteAdmin/_manage/SetupAdminMod"
          element={<SiteAdminManageSetupAdminMod />}
        />
        <Route
          path="/SiteAdmin/_manage/AccessIpList"
          element={<SiteAdminManageAccessIpList />}
        />
        <Route
          path="/SiteAdmin/_manage/AccessIpForm"
          element={<SiteAdminManageAccessIpForm />}
        />
        {/* 공지사항 */}
        <Route
          path="/SiteAdmin/_manage/NoticeList"
          element={<SiteAdminManageNoticeList />}
        />
        <Route
          path="/SiteAdmin/_manage/NoticeForm"
          element={<SiteAdminManageNoticeForm />}
        />
        <Route
          path="/SiteAdmin/_manage/NoticeView"
          element={<SiteAdminManageNoticeView />}
        />
        {/* 자주하는 질문 */}
        <Route
          path="/SiteAdmin/_manage/FaqList"
          element={<SiteAdminManageFaqList />}
        />
        <Route
          path="/Distributor/_manage/FaqList"
          element={<DistributorManageFaqList />}
        />
        <Route
          path="/Agency/_manage/FaqList"
          element={<AgencyManageFaqList />}
        />
        <Route
          path="/Store/_management/FaqList"
          element={<StoreManageFaqList />}
        />
        <Route
          path="/SiteAdmin/_manage/FaqForm"
          element={<SiteAdminManageFaqForm />}
        />
        <Route
          path="/SiteAdmin/_manage/FaqMod"
          element={<SiteAdminManageFaqMod />}
        />
        <Route
          path="/SiteAdmin/_manage/FaqView"
          element={<SiteAdminManageFaqView />}
        />
        <Route
          path="/Distributor/_manage/FaqView"
          element={<DistributorManageFaqView />}
        />
        <Route
          path="/Agency/_manage/FaqView"
          element={<AgencyManageFaqView />}
        />
        <Route path="/Store/_manage/FaqView" element={<StoreManageFaqView />} />
        {/* 1대1문의 */}
        <Route
          path="/SiteAdmin/_manage/QnaList"
          element={<SiteAdminManageQnaList />}
        />
        <Route
          path="/Distributor/_manage/QnaList"
          element={<DistributorManageQnaList />}
        />
        <Route
          path="/Agency/_manage/QnaList"
          element={<AgencyManageQnaList />}
        />
        <Route
          path="/Store/_management/QnaList"
          element={<StoreManageQnaList />}
        />
        <Route
          path="/SiteAdmin/_manage/QnaForm"
          element={<SiteAdminManageQnaForm />}
        />
        <Route
          path="/Distributor/_manage/QnaForm"
          element={<DistributorManageQnaForm />}
        />
        <Route
          path="/Agency/_manage/QnaForm"
          element={<AgencyManageQnaForm />}
        />
        <Route path="/Store/_manage/QnaForm" element={<StoreManageQnaForm />} />
        <Route
          path="/SiteAdmin/_manage/QnaMod"
          element={<SiteAdminManageQnaMod />}
        />
        <Route
          path="/Distributor/_manage/QnaMod"
          element={<DistributorManageQnaMod />}
        />
        <Route path="/Agency/_manage/QnaMod" element={<AgencyManageQnaMod />} />
        <Route path="/Store/_manage/QnaMod" element={<StoreManageQnaMod />} />
        <Route
          path="/SiteAdmin/_manage/QnaView"
          element={<SiteAdminManageQnaView />}
        />
        <Route
          path="/Distributor/_manage/QnaView"
          element={<DistributorManageQnaView />}
        />
        <Route
          path="/Agency/_manage/QnaView"
          element={<AgencyManageQnaView />}
        />
        <Route path="/Store/_manage/QnaView" element={<StoreManageQnaView />} />
        {/* 자주하는 질문 */}
        <Route
          path="/Mbranch/_manage/FaqList"
          element={<MbranchManageFaqList />}
        />
        <Route
          path="/Mbranch/_manage/FaqForm"
          element={<MbranchManageFaqForm />}
        />
        <Route
          path="/Mbranch/_manage/FaqMod"
          element={<MbranchManageFaqMod />}
        />
        <Route
          path="/Mbranch/_manage/FaqView"
          element={<MbranchManageFaqView />}
        />
        {/* 1대1문의 */}
        <Route
          path="/Mbranch/_manage/QnaList"
          element={<MbranchManageQnaList />}
        />
        <Route
          path="/Mbranch/_manage/QnaForm"
          element={<MbranchManageQnaForm />}
        />
        <Route
          path="/Mbranch/_manage/QnaMod"
          element={<MbranchManageQnaMod />}
        />
        <Route
          path="/Mbranch/_manage/QnaView"
          element={<MbranchManageQnaView />}
        />
        {/* 로그인 관리 */}
        <Route
          path="/SiteAdmin/_manage/SetupLoginList"
          element={<SiteAdminManageSetupLoginList />}
        />
        {/* 정보변경 */}
        <Route
          path="/SiteAdmin/_admin/Setting"
          element={<SiteAdminAdminSetting />}
        />
        {/*발송 거래내역*/}
        <Route
          path="/SiteAdmin/_pay/homepageList"
          element={<SiteAdminPayHomepageList />}
        />
        {/* #########################	거래조회(본사) 일반	#############################*/}
        {/*가맹점 거래내역*/}
        <Route
          path="/SiteAdmin/_pay/transactionHistoryList"
          element={<SiteAdminPayTransactionHistoryList />}
        />
        {/*가맹점 거래내역 상세*/}
        <Route
          path="/SiteAdmin/_pay/transactionHistoryView"
          element={<SiteAdminPayTransactionHistoryView />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/SiteAdmin/_pay/payErrorList"
          element={<SiteAdminPayPayErrorList />}
        />
        {/*차감내역*/}
        <Route
          path="/SiteAdmin/_pay/payDeduction"
          element={<SiteAdminPayPayDeductionList />}
        />
        {/*정산보류내역*/}
        <Route
          path="/SiteAdmin/_pay/pay_reserve"
          element={<SiteAdminPayPayReserveList />}
        />
        {/*정산보류상세내역*/}
        <Route
          path="/SiteAdmin/_pay/pay_reserve_view"
          element={<SiteAdminPayPayReserveView />}
        />
        {/*매출집계*/}
        <Route
          path="/SiteAdmin/_pay/pay_total"
          element={<SiteAdminPayPayTotalList />}
        />
        {/*가맹점 거래내역 엑셀*/}
        <Route
          path="/SiteAdmin/_pay/transactionHistoryExcel"
          element={<SiteAdminPayTransactionHistoryExcel />}
        />
        {/*거래내역부분취소*/}
        <Route
          path="/SiteAdmin/_pay/transactionHistoryCancelPart"
          element={<SiteAdminPayTransactionHistoryCancelPart />}
        />
        {/*거래내역취소*/}
        <Route
          path="/SiteAdmin/_pay/transactionHistoryCancel"
          element={<SiteAdminPayTransactionHistoryCancel />}
        />
        {/* #########################	거래조회(본사) // #############################*/}
        {/* #########################	거래조회(본사) 터미널	#############################*/}
        {/*가맹점 거래내역*/}
        <Route
          path="/SiteAdmin/_pay_terminal/transactionHistoryList"
          element={<SiteAdminPayTransactionHistoryTerminalList />}
        />
        {/*가맹점 거래내역 상세*/}
        <Route
          path="/SiteAdmin/_pay_terminal/transactionHistoryView"
          element={<SiteAdminPayTransactionHistoryTerminalView />}
        />
        <Route
          path="/SiteAdmin/_pay_terminal/transactionHistoryPop"
          element={<SiteAdminPayTransactionHistoryTerminalPop />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/SiteAdmin/_pay_terminal/payErrorList"
          element={<SiteAdminPayPayErrorTerminalList />}
        />
        {/*차감내역*/}
        <Route
          path="/SiteAdmin/_pay_terminal/payDeduction"
          element={<SiteAdminPayPayDeductionTerminalList />}
        />
        {/*정산보류내역*/}
        <Route
          path="/SiteAdmin/_pay_terminal/pay_reserve"
          element={<SiteAdminPayPayReserveTerminalList />}
        />
        {/*정산보류상세내역*/}
        <Route
          path="/SiteAdmin/_pay_terminal/pay_reserve_view"
          element={<SiteAdminPayPayReserveTerminalView />}
        />
        {/*매출집계*/}
        <Route
          path="/SiteAdmin/_pay_terminal/pay_total"
          element={<SiteAdminPayPayTotalTerminalList />}
        />
        {/*가맹점 거래내역 엑셀_터미널*/}
        <Route
          path="/SiteAdmin/_pay_terminal/transactionHistoryExcel"
          element={<SiteAdminPayTransactionHistoryExcelTerminal />}
        />
        {/*거래내역부분취소_터미널*/}
        <Route
          path="/SiteAdmin/_pay_terminal/transactionHistoryCancelPart"
          element={<SiteAdminPayTransactionHistoryCancelPartTerminal />}
        />
        {/*거래내역취소_터미널*/}
        <Route
          path="/SiteAdmin/_pay_terminal/transactionHistoryCancel"
          element={<SiteAdminPayTransactionHistoryCancelTerminal />}
        />
        {/* #########################	거래조회(본사) 터미널 // #############################*/}
        {/* #########################	정산관리(본사)	#############################*/}
        {/*가맹점정산*/}
        <Route
          path="/SiteAdmin/_settlement/store"
          element={<SiteAdminSettlementStore />}
        />
        {/*가맹점정산 결과*/}
        <Route
          path="/SiteAdmin/_settlement/store_result"
          element={<SiteAdminSettlementStoreResult />}
        />
        {/*가맹점정산 결과 - 정산차감 설정*/}
        <Route
          path="/SiteAdmin/_settlement/store_result_opcition"
          element={<SiteAdminSettlementStoreResultOpcition />}
        />
        {/*지사정산*/}
        <Route
          path="/SiteAdmin/_settlement/br"
          element={<SiteAdminSettlementBr />}
        />
        {/*지사정산 결과*/}
        <Route
          path="/SiteAdmin/_settlement/br_result"
          element={<SiteAdminSettlementBrResult />}
        />
        {/*총판정산*/}
        <Route
          path="/SiteAdmin/_settlement/di"
          element={<SiteAdminSettlementDi />}
        />
        {/*총판정산 결과*/}
        <Route
          path="/SiteAdmin/_settlement/di_result"
          element={<SiteAdminSettlementDiResult />}
        />
        {/*대리점정산*/}
        <Route
          path="/SiteAdmin/_settlement/ag"
          element={<SiteAdminSettlementAg />}
        />
        {/*대리점정산 결과*/}
        <Route
          path="/SiteAdmin/_settlement/ag_result"
          element={<SiteAdminSettlementAgResult />}
        />
        {/* ##############	매출데이터 ##############	*/}
        {/*매출데이터 업로드*/}
        <Route
          path="/SiteAdmin/_settlement/data_upload"
          element={<SiteAdminSettlementDataUpload />}
        />
        {/*매출거래 생성*/}
        <Route
          path="/SiteAdmin/_settlement/data_upload_form"
          element={<SiteAdminSettlementDataUploadForm />}
        />
        {/*[홈페이지]매출거래 생성*/}
        <Route
          path="/SiteAdmin/_settlement/data_upload_home"
          element={<SiteAdminSettlementDataUploadHome />}
        />
        {/*정산결제*/}
        <Route
          path="/SiteAdmin/_settlement/data_upload_ins"
          element={<SiteAdminSettlementDataUploadIns />}
        />
        {/* ##############	매출데이터 // ##############	*/}
        {/*정산승인달력*/}
        <Route
          path="/SiteAdmin/_settlement/calculate_calendar"
          element={<SiteAdminCalculateCalendar />}
        />
        {/* #########################	정산관리(본사) // #############################*/}
        {/* #########################	정산관리(터미널)	#############################*/}
        <Route
          path="/SiteAdmin/_settlement_terminal/calculate_calendar"
          element={<SiteAdminCalculateCalendarTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/store"
          element={<SiteAdminSettlementStoreTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/store_result"
          element={<SiteAdminSettlementStoreResultTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/store_result_opcition"
          element={<SiteAdminSettlementStoreResultOpcitionTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/br"
          element={<SiteAdminSettlementBrTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/br_result"
          element={<SiteAdminSettlementBrResultTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/di"
          element={<SiteAdminSettlementDiTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/di_result"
          element={<SiteAdminSettlementDiResultTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/ag"
          element={<SiteAdminSettlementAgTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/ag_result"
          element={<SiteAdminSettlementAgResultTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/data_upload"
          element={<SiteAdminSettlementDataUploadTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/data_upload_form"
          element={<SiteAdminSettlementDataUploadFormTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/data_upload_home"
          element={<SiteAdminSettlementDataUploadHomeTerminal />}
        />
        <Route
          path="/SiteAdmin/_settlement_terminal/data_upload_ins"
          element={<SiteAdminSettlementDataUploadInsTerminal />}
        />
        {/* #########################	정산관리(터미널)	// #############################*/}
        <Route
          path="/SiteAdmin/_manage/_pg/list"
          element={<SiteAdminPGList />}
        />
        <Route
          path="/SiteAdmin/_manage/_pg/form"
          element={<SiteAdminPGForm />}
        />
        <Route
          path="/SiteAdmin/_manage/_pg_terminal/list"
          element={<SiteAdminPGTerminalList />}
        />
        <Route
          path="/SiteAdmin/_manage/_pg_terminal/form"
          element={<SiteAdminPGTerminalForm />}
        />
        <Route path="/Store" element={<SiteStore />} />
        <Route path="/Store/_main/main" element={<StoreMain />} />
        <Route
          path="/Store/_pay/calculate_calendar"
          element={<StorePayCalculateCalendar />}
        />
        <Route
          path="/Store/_pay/transactionHistoryList"
          element={<StorePayTransactionHistoryList />}
        />
        <Route
          path="/Store/_pay/transactionHistoryForm"
          element={<StorePayTransactionHistoryForm />}
        />
        <Route
          path="/Store/_pay/transactionHistoryView"
          element={<StorePayTransactionHistoryView />}
        />
        <Route
          path="/Store/_pay/payTotalList"
          element={<StorePayPayTotalList />}
        />
        <Route
          path="/Store/_pay/easyPaymentList"
          element={<StorePayEasyPaymentList />}
        />
        <Route
          path="/Store/_pay_terminal/calculate_calendar"
          element={<StorePayCalculateCalendarTerminal />}
        />
        <Route
          path="/Store/_pay_terminal/transactionHistoryList"
          element={<StorePayTransactionHistoryListTerminal />}
        />
        <Route
          path="/Store/_pay_terminal/transactionHistoryForm"
          element={<StorePayTransactionHistoryFormTerminal />}
        />
        <Route
          path="/Store/_pay_terminal/transactionHistoryView"
          element={<StorePayTransactionHistoryViewTerminal />}
        />
        <Route
          path="/Store/_pay_terminal/payTotalList"
          element={<StorePayPayTotalListTerminal />}
        />
        <Route
          path="/Store/_pay_terminal/easyPaymentList"
          element={<StorePayEasyPaymentListTerminal />}
        />
        <Route
          path="/Store/_management/goodsList"
          element={<StoreManagementGoodsList />}
        />
        <Route
          path="/Store/_management/goodsForm"
          element={<StoreManagementGoodsForm />}
        />
        <Route
          path="/Store/_management/goodsMod"
          element={<StoreManagementGoodsMod />}
        />
        <Route
          path="/Store/_management/noticeList"
          element={<StoreManagementNoticeList />}
        />
        <Route
          path="/Store/_management/noticeView"
          element={<StoreManagementNoticeView />}
        />
        <Route
          path="/Store/_admin/settingList"
          element={<StoreAdminSettingList />}
        />
        <Route
          path="/Store/_management/payFormStep1"
          element={<StoreManagementPayFormStep1 />}
        />
        <Route
          path="/Store/_management/payFormStep2"
          element={<StoreManagementPayFormStep2 />}
        />
        <Route
          path="/Store/_management/payFormStep3"
          element={<StoreManagementPayFormStep3 />}
        />
        {/* 지사 */}
        <Route path="/Mbranch" element={<Mbranch />} />
        <Route path="/Mbranch/_main/main" element={<MbranchMain />} />
        <Route
          path="/Mbranch/_main/main_terminal"
          element={<MbranchMainTerminal />}
        />
        <Route
          path="/Mbranch/_manage/ManageLog"
          element={<MbranchManageManageLog />}
        />
        <Route
          path="/Mbranch/_manage/NoticeList"
          element={<MbranchManageNoticeList />}
        />
        <Route
          path="/Mbranch/_manage/NoticeForm"
          element={<MbranchManageNoticeForm />}
        />
        <Route
          path="/Mbranch/_manage/NoticeView"
          element={<MbranchManageNoticeView />}
        />
        <Route
          path="/Mbranch/_manage/Holiday"
          element={<MbranchManageHoliday />}
        />
        <Route
          path="/Mbranch/_admin/Setting"
          element={<MbranchAdminSetting />}
        />
        <Route
          path="/Mbranch/_pay/transactionHistoryList"
          element={<MbranchPayTransactionHistoryList />}
        />
        {/*지사 거래내역*/}
        <Route
          path="/Mbranch/_pay/transactionHistoryView"
          element={<MbranchPayTransactionHistoryView />}
        />
        {/*지사 거래내역 상세*/}
        <Route
          path="/Mbranch/_pay/payErrorList"
          element={<MbranchPayPayErrorList />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/Mbranch/_pay/payDeduction"
          element={<MbranchPayPayDeductionList />}
        />
        {/*차감내역*/}
        <Route
          path="/Mbranch/_pay/pay_reserve"
          element={<MbranchPayPayReserveList />}
        />
        {/*정산보류내역*/}
        <Route
          path="/Mbranch/_pay/pay_total"
          element={<MbranchPayPayTotalList />}
        />
        {/*매출집계*/}
        <Route
          path="/Mbranch/_pay_terminal/transactionHistoryList"
          element={<MbranchPayTransactionHistoryListTerminal />}
        />
        {/*지사 거래내역*/}
        <Route
          path="/Mbranch/_pay_terminal/transactionHistoryView"
          element={<MbranchPayTransactionHistoryViewTerminal />}
        />
        {/*지사 거래내역 상세*/}
        <Route
          path="/Mbranch/_pay_terminal/payErrorList"
          element={<MbranchPayPayErrorListTerminal />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/Mbranch/_pay_terminal/payDeduction"
          element={<MbranchPayPayDeductionListTerminal />}
        />
        {/*차감내역*/}
        <Route
          path="/Mbranch/_pay_terminal/pay_reserve"
          element={<MbranchPayPayReserveListTerminal />}
        />
        {/*정산보류내역*/}
        <Route
          path="/Mbranch/_pay_terminal/pay_total"
          element={<MbranchPayPayTotalListTerminal />}
        />
        {/*매출집계*/}
        <Route
          path="/Mbranch/_settlement/store"
          element={<MbranchSettlementStore />}
        />{" "}
        {/*가맹점정산*/}
        <Route
          path="/Mbranch/_settlement/store_result"
          element={<MbranchSettlementStoreResult />}
        />
        {/*가맹점정산 결과*/}
        <Route
          path="/Mbranch/_settlement/br"
          element={<MbranchSettlementBr />}
        />
        {/*지사정산*/}
        <Route
          path="/Mbranch/_settlement/br_result"
          element={<MbranchSettlementBrResult />}
        />
        {/*지사정산 결과*/}
        <Route
          path="/Mbranch/_settlement/di"
          element={<MbranchSettlementDi />}
        />{" "}
        {/*총판정산*/}
        <Route
          path="/Mbranch/_settlement/di_result"
          element={<MbranchSettlementDiResult />}
        />
        {/*총판정산 결과*/}
        <Route
          path="/Mbranch/_settlement/ag"
          element={<MbranchSettlementAg />}
        />
        {/*대리점정산*/}
        <Route
          path="/Mbranch/_settlement/ag_result"
          element={<MbranchSettlementAgResult />}
        />
        {/*대리점정산 결과*/}
        <Route
          path="/Mbranch/_settlement_terminal/store"
          element={<MbranchSettlementStoreTerminal />}
        />{" "}
        {/*가맹점정산*/}
        <Route
          path="/Mbranch/_settlement_terminal/store_result"
          element={<MbranchSettlementStoreResultTerminal />}
        />
        {/*가맹점정산 결과*/}
        <Route
          path="/Mbranch/_settlement_terminal/br"
          element={<MbranchSettlementBrTerminal />}
        />
        {/*지사정산*/}
        <Route
          path="/Mbranch/_settlement_terminal/br_result"
          element={<MbranchSettlementBrResultTerminal />}
        />
        {/*지사정산 결과*/}
        <Route
          path="/Mbranch/_settlement_terminal/di"
          element={<MbranchSettlementDiTerminal />}
        />{" "}
        {/*총판정산*/}
        <Route
          path="/Mbranch/_settlement_terminal/di_result"
          element={<MbranchSettlementDiResultTerminal />}
        />
        {/*총판정산 결과*/}
        <Route
          path="/Mbranch/_settlement_terminal/ag"
          element={<MbranchSettlementAgTerminal />}
        />
        {/*대리점정산*/}
        <Route
          path="/Mbranch/_settlement_terminal/ag_result"
          element={<MbranchSettlementAgResultTerminal />}
        />
        {/*대리점정산 결과*/}
        {/*총판관리*/}
        <Route
          path="/Mbranch/_management/DistributorList"
          element={<MbranchManagementDistributorList />}
        />
        {/*대리점 관리*/}
        <Route
          path="/Mbranch/_management/AgencyList"
          element={<MbranchManagementAgencyList />}
        />
        {/*가맹점관리*/}
        <Route
          path="/Mbranch/_management/StoreList"
          element={<MbranchManagementStoreList />}
        />
        <Route
          path="/Mbranch/_management/StoreTerminalList"
          element={<MbranchManagementStoreTerminalList />}
        />
        <Route
          path="/Mbranch/_management/StoreForm"
          element={<MbranchManagementStoreForm />}
        />
        <Route
          path="/Mbranch/_management/StoreTerminalForm"
          element={<MbranchManagementStoreTerminalForm />}
        />
        {/* 총판 */}
        <Route path="/Distributor" element={<Distributor />} />
        <Route path="/Distributor/_main/main" element={<DistributorMain />} />
        <Route
          path="/Distributor/_main/main_terminal"
          element={<DistributorMainTerminal />}
        />
        <Route
          path="/Distributor/_manage/ManageLog"
          element={<DistributorManageManageLog />}
        />
        <Route
          path="/Distributor/_manage/NoticeList"
          element={<DistributorManageNoticeList />}
        />
        <Route
          path="/Distributor/_manage/NoticeForm"
          element={<DistributorManageNoticeForm />}
        />
        <Route
          path="/Distributor/_manage/NoticeView"
          element={<DistributorManageNoticeView />}
        />
        <Route
          path="/Agency/_manage/NoticeView"
          element={<AgencyManageNoticeView />}
        />
        <Route
          path="/Distributor/_manage/Holiday"
          element={<DistributorManageHoliday />}
        />
        <Route
          path="/Distributor/_admin/Setting"
          element={<DistributorAdminSetting />}
        />
        <Route
          path="/Distributor/_pay/transactionHistoryList"
          element={<DistributorPayTransactionHistoryList />}
        />
        {/*거래내역*/}
        <Route
          path="/Distributor/_pay/transactionHistoryView"
          element={<DistributorPayTransactionHistoryView />}
        />
        {/*거래내역 상세*/}
        <Route
          path="/Distributor/_pay/payErrorList"
          element={<DistributorPayPayErrorList />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/Distributor/_pay/payDeduction"
          element={<DistributorPayPayDeductionList />}
        />
        {/*차감내역*/}
        <Route
          path="/Distributor/_pay/pay_reserve"
          element={<DistributorPayPayReserveList />}
        />
        {/*정산보류내역*/}
        <Route
          path="/Distributor/_pay/pay_total"
          element={<DistributorPayPayTotalList />}
        />
        {/*매출집계*/}
        <Route
          path="/Distributor/_pay_terminal/transactionHistoryList"
          element={<DistributorPayTransactionHistoryListTerminal />}
        />
        {/*거래내역*/}
        <Route
          path="/Distributor/_pay_terminal/transactionHistoryView"
          element={<DistributorPayTransactionHistoryViewTerminal />}
        />
        {/*거래내역 상세*/}
        <Route
          path="/Distributor/_pay_terminal/payErrorList"
          element={<DistributorPayPayErrorListTerminal />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/Distributor/_pay_terminal/payDeduction"
          element={<DistributorPayPayDeductionListTerminal />}
        />
        {/*차감내역*/}
        <Route
          path="/Distributor/_pay_terminal/pay_reserve"
          element={<DistributorPayPayReserveListTerminal />}
        />
        {/*정산보류내역*/}
        <Route
          path="/Distributor/_pay_terminal/pay_total"
          element={<DistributorPayPayTotalListTerminal />}
        />
        {/*매출집계*/}
        <Route
          path="/Distributor/_settlement/store"
          element={<DistributorSettlementStore />}
        />
        {/*가맹점정산*/}
        <Route
          path="/Distributor/_settlement/store_result"
          element={<DistributorSettlementStoreResult />}
        />
        {/*가맹점정산 결과*/}
        <Route
          path="/Distributor/_settlement/ag"
          element={<DistributorSettlementAg />}
        />
        {/*대리점정산*/}
        <Route
          path="/Distributor/_settlement/ag_result"
          element={<DistributorSettlementAgResult />}
        />
        {/*대리점정산 결과*/}
        <Route
          path="/Distributor/_settlement_terminal/store"
          element={<DistributorSettlementStoreTerminal />}
        />
        {/*가맹점정산*/}
        <Route
          path="/Distributor/_settlement_terminal/store_result"
          element={<DistributorSettlementStoreResultTerminal />}
        />
        {/*가맹점정산 결과*/}
        <Route
          path="/Distributor/_settlement_terminal/ag"
          element={<DistributorSettlementAgTerminal />}
        />
        {/*대리점정산*/}
        <Route
          path="/Distributor/_settlement_terminal/ag_result"
          element={<DistributorSettlementAgResultTerminal />}
        />
        {/*대리점정산 결과*/}
        {/*대리점 관리*/}
        <Route
          path="/Distributor/_management/AgencyList"
          element={<DistributorManagementAgencyList />}
        />
        {/*가맹점관리*/}
        <Route
          path="/Distributor/_management/StoreList"
          element={<DistributorManagementStoreList />}
        />
        {/* 대리점 */}
        <Route path="/Agency" element={<Agency />} />
        <Route path="/Agency/_main/main" element={<AgencyMain />} />
        <Route
          path="/Agency/_main/main_terminal"
          element={<AgencyMainTerminal />}
        />
        <Route
          path="/Agency/_manage/ManageLog"
          element={<AgencyManageManageLog />}
        />
        <Route
          path="/Agency/_manage/NoticeList"
          element={<AgencyManageNoticeList />}
        />
        <Route
          path="/Agency/_manage/NoticeForm"
          element={<AgencyManageNoticeForm />}
        />
        <Route
          path="/Agency/_manage/Holiday"
          element={<AgencyManageHoliday />}
        />
        <Route path="/Agency/_admin/Setting" element={<AgencyAdminSetting />} />
        <Route
          path="/Agency/_pay/transactionHistoryList"
          element={<AgencyPayTransactionHistoryList />}
        />
        {/*거래내역*/}
        <Route
          path="/Agency/_pay/transactionHistoryView"
          element={<AgencyPayTransactionHistoryView />}
        />
        {/*거래내역 상세*/}
        <Route
          path="/Agency/_pay/payErrorList"
          element={<AgencyPayPayErrorList />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/Agency/_pay/payDeduction"
          element={<AgencyPayPayDeductionList />}
        />
        {/*차감내역*/}
        <Route
          path="/Agency/_pay/pay_reserve"
          element={<AgencyPayPayReserveList />}
        />
        {/*정산보류내역*/}
        <Route
          path="/Agency/_pay/pay_total"
          element={<AgencyPayPayTotalList />}
        />
        {/*매출집계*/}
        <Route
          path="/Agency/_pay_terminal/transactionHistoryList"
          element={<AgencyPayTransactionHistoryListTerminal />}
        />
        {/*거래내역*/}
        <Route
          path="/Agency/_pay_terminal/transactionHistoryView"
          element={<AgencyPayTransactionHistoryViewTerminal />}
        />
        {/*거래내역 상세*/}
        <Route
          path="/Agency/_pay_terminal/payErrorList"
          element={<AgencyPayPayErrorListTerminal />}
        />
        {/*거래 거절내역*/}
        <Route
          path="/Agency/_pay_terminal/payDeduction"
          element={<AgencyPayPayDeductionListTerminal />}
        />
        {/*차감내역*/}
        <Route
          path="/Agency/_pay_terminal/pay_reserve"
          element={<AgencyPayPayReserveListTerminal />}
        />
        {/*정산보류내역*/}
        <Route
          path="/Agency/_pay_terminal/pay_total"
          element={<AgencyPayPayTotalListTerminal />}
        />
        {/*매출집계*/}
        <Route
          path="/Agency/_settlement/store"
          element={<AgencySettlementStore />}
        />
        {/*가맹점정산*/}
        <Route
          path="/Agency/_settlement/store_result"
          element={<AgencySettlementStoreResult />}
        />
        {/*가맹점정산 결과*/}
        <Route
          path="/Agency/_settlement_terminal/store"
          element={<AgencySettlementStoreTerminal />}
        />
        {/*가맹점정산*/}
        <Route
          path="/Agency/_settlement_terminal/store_result"
          element={<AgencySettlementStoreResultTerminal />}
        />
        {/*가맹점정산 결과*/}
        <Route
          path="/Agency/_management/StoreList"
          element={<AgencyManagementStoreList />}
        />
        {/*가맹점관리*/}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
