import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/DistributorHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble} from "../../../include/ConfigSet_Terminal";


function DistributorPayTransactionHistoryViewTerminal() {

    const navigate = useNavigate();
    const location = useLocation();

    const qs = queryString.parse(window.location.search);
    const idx = qs.idx ? qs.idx : '';

    
    if(idx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }
 

    const [created_datetime, set_created_datetime] = useState("");
    const [pg_group, set_pg_group] = useState("");
    const [St_branch_name, set_St_branch_name] = useState("");
    const [cardno, set_cardno] = useState("");
    const [authno, set_authno] = useState("");
    const [amount, set_amount] = useState("");
    const [quota, set_quota] = useState("");
    const [cpid, set_cpid] = useState("");
    const [status, set_status] = useState("");
    const [daoutrx, set_daoutrx] = useState("");
    const [calculate_date_re, set_calculate_date_re] = useState("");
    const [orderno, set_orderno] = useState("");
    const [delay_chk, set_delay_chk] = useState("");

    

    function cancel_out(status){
        //alert( ChkDelayAble(authno, amount * -1) )
        let cancel_chk = ChkDelayAble(authno, amount * -1);
        
        
        if(status === "E" && cancel_chk < 1){
            return (
                <>
                    <button type="button" className=" btn_st_01" >신용카드 매출전표</button>
                    {/*<button type="button" className="line_btn btn_st_01" >[전산처리] 부분취소 적용</button>*/}
                    <Link to={`/SiteAdmin/_pay_terminal/transactionHistoryCancelPart?idx=`+idx}  className=" btn_st_01 wid_auto" >[전산처리] 부분취소 적용</Link>
                    <Link to={`/SiteAdmin/_pay_terminal/transactionHistoryCancel?idx=`+idx}  className=" btn_st_01 wid_auto" >[전산처리] 전체취소 적용</Link>
                </>
            )
        }else if(status === "B" && cancel_chk < 1){
            return (
                <>
                    <button type="button" className=" btn_st_01" >신용카드 매출전표</button>
                    <Link to={`/SiteAdmin/_pay_terminal/transactionHistoryCancel?idx=`+idx}  className=" btn_st_01" >[전산처리] 전체취소 적용</Link>
                </>
            )
        }

    }

    useEffect(() => {


        let bodyPage = {
            idx: idx
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/pay/transaction_history_view', bodyPage).then(function(res){
            
            res.data.map((v, index) => {
                set_created_datetime(v.created_datetime);
                set_pg_group(v.pg_group);
                set_St_branch_name(v.St_branch_name);
                set_cardno(v.cardno);
                set_authno(v.authno);
                set_amount(v.amount);
                set_quota(v.quota);
                set_cpid(v.cpid);
                set_status(v.status);
                set_daoutrx(v.daoutrx);
                set_calculate_date_re(v.calculate_date_re);
                set_orderno(v.orderno);               
            })
            
        }) 

        return () => {
          
        };
    }, []);
  


    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: qs.idx,
            
        }

        axios.post('/api/management/update', body)
        .then(function(response){
            

            
            if(response.data === "ModOk"){    
                //navigate('/SiteAdmin/_management/list');
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


        
        
    }

    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="management_list">  
                <form onSubmit={onSubmitHandler}>
                    <h3 className="page_ttl">거래정보</h3>
                    <div className="form_item">
                        

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="150px" />
                                    <col width="*" />
                                    <col width="150px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>거래일자</th>
                                        <td>{created_datetime}</td>
                                        <th>PG</th>
                                        <td>{PgName(pg_group)}</td>
                                    </tr>
                                    <tr>
                                        <th>구분</th>
                                        <td>
                                            {GetPayGubun(status)}
                                        </td>
                                        <th>결제구분</th>
                                        <td>신용</td>
                                    </tr>
                                    <tr>
                                        <th>가맹점</th>
                                        <td>{St_branch_name}</td>
                                        <th>터미널코드</th>
                                        <td>{cpid}</td>
                                    </tr>
                                    <tr>
                                        <th>카드번호</th>
                                        <td>{cardno.substr(0, cardno.length-4) + "****"}</td>
                                        <th>승인번호</th>
                                        <td>{authno}</td>
                                    </tr>

                                    <tr>
                                        <th>금액</th>
                                        <td>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            
                                            
                                        </td>
                                        <th>할부</th>
                                        <td>{(quota==="00"?'일시불':quota)}</td>
                                    </tr>

                                    <tr>
                                        <th>정산상태</th>
                                        <td>{GetPayStatus(status)}</td>
                                        <th>주문번호 / TID</th>
                                        <td>{orderno} / {daoutrx}</td>
                                    </tr>

                                    <tr>
                                        <th>PG정산예정</th>
                                        <td>{status !=='' ? calculate_date_re : ''}</td>
                                        <th>가맹점정산예정</th>
                                        <td>{status !=='' ? calculate_date_re : ''}</td>
                                    </tr>
                                    

                                </tbody>
                            </table>

                        </div>

                    </div>


                    

                    <div className="top_table_btn">
                        {/*<button type="submit" className="black_btn btn_st_01">등록하기</button> */}
                        <button type="button" className="line_btn btn_st_01" onClick={  () => navigate(-1) }>이전으로</button>
                    </div>
                </form>
            </div>
            
        </div>

        <Footer/>
        </>
    )


}
export default DistributorPayTransactionHistoryViewTerminal