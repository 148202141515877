import React, { useEffect, useRef, forwardRef } from "react";
import axios from "axios";
import ApexCharts from "apexcharts";

const DashboardChart = forwardRef(
  (
    { apiEndpoint, chartCaption, oneYearAgoFormatted, nowFormatted, user_id },
    ref
  ) => {
    const internalRef = useRef(null);
    const chartRef = ref || internalRef;

    const processChartData = (chart_price_list, dateRange) => {
      const { startDate } = dateRange;
      const chart_price = [];
      const chart_cnt = [];
      const chart_x = [];

      for (let i = 0; i < 12; i++) {
        let tmpDay = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + i,
          startDate.getDate()
        );

        let tmpDayFormatted =
          tmpDay.getFullYear() +
          "." +
          (tmpDay.getMonth() + 1).toString().padStart(2, "0");

        chart_x.push(tmpDayFormatted);

        let arr_index = chart_price_list.findIndex(
          (v) => v.months === tmpDayFormatted
        );

        if (arr_index < 0) {
          chart_price.push(0);
          chart_cnt.push(0);
        } else {
          // Ensure the values are valid numbers
          const totalAmount = Number(
            chart_price_list[arr_index]?.total_amount || 0
          );
          const totalCnt = Number(chart_price_list[arr_index]?.total_cnt || 0);

          chart_price.push(isNaN(totalAmount) ? 0 : totalAmount);
          chart_cnt.push(isNaN(totalCnt) ? 0 : totalCnt);
        }
      }

      return { chart_x, chart_price, chart_cnt };
    };
    const renderChart = (chart_x, chart_price, chart_cnt, chartCaption) => {
      if (!chartRef.current) {
        console.error("Chart element not found");
        return;
      }

      // Check for NaN in the data before rendering
      if (chart_price.some(isNaN) || chart_cnt.some(isNaN)) {
        console.error("Chart data contains NaN values, aborting rendering.");
        return;
      }

      const options = {
        series: [
          {
            name: "매출내역",
            type: "column",
            data: chart_price,
          },
          {
            name: "매출건수",
            type: "line",
            data: chart_cnt,
          },
        ],
        colors: ["#3a82f8", "#ff2c27"],
        chart: {
          height: 390,
          type: "line",
          stacked: false,
        },
        stroke: {
          width: [0, 4],
          curve: "straight",
          colors: ["#3a82f8", "#ff2c27"],
        },
        plotOptions: {
          bar: {
            borderRadius: 12,
            columnWidth: "30%",
          },
        },
        dataLabels: {
          style: {
            colors: ["#3a82f8", "#ff2c27"],
          },
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          colors: ["#3a82f8", "#ff2c27"],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: chart_x,
        markers: {
          size: 4,
          colors: ["#ff2c27"],
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
        xaxis: {
          type: "category",
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "yyyy 'MM",
            },
          },
        },
        yaxis: [
          {
            title: {},
            labels: {
              formatter: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          {
            opposite: true,
            title: {},
            labels: {
              formatter: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (value, { seriesIndex }) {
              if (seriesIndex === 0) {
                return (
                  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원"
                );
              } else {
                return (
                  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 건"
                );
              }
            },
          },
        },
        subtitle: {
          text: `월별 매출액 : 최근 12개월 (${chartCaption})`,
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Spoqa Han Sans Neo",
            color: "#252525",
          },
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
        noData: {
          text: "데이터가 없습니다",
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "#252525",
            fontSize: "14px",
            fontFamily: "Spoqa Han Sans Neo",
          },
        },
      };

      setTimeout(() => {
        const chart = new ApexCharts(chartRef.current, options);
        chart.render();
      }, 0);
    };

    const fetchAndRenderChartData = async ({
      apiEndpoint,
      bodyQuery,
      chartCaption,
      dateRange,
    }) => {
      try {
        const response = await axios.post(apiEndpoint, bodyQuery);
        let chart_price_list = response.data;
        if (!Array.isArray(chart_price_list)) {
          console.error("차트 데이터가 배열이 아닙니다:", chart_price_list);
          chart_price_list = []; // 기본적으로 빈 배열로 초기화
        }

        const { chart_x, chart_price, chart_cnt } = processChartData(
          chart_price_list,
          dateRange
        );
        renderChart(chart_x, chart_price, chart_cnt, chartCaption);
      } catch (error) {
        console.error("차트 데이터를 가져오는 중 오류가 발생했습니다:", error);
      }
    };

    useEffect(() => {
      const dateRange = {
        startDate: new Date(oneYearAgoFormatted),
        endDate: new Date(nowFormatted),
      };

      const bodyQuery = {
        sdate: oneYearAgoFormatted,
        edate: nowFormatted,
        ...(user_id && { user_id }),
      };

      if (chartRef.current) {
        fetchAndRenderChartData({
          apiEndpoint,
          bodyQuery,
          chartCaption,
          dateRange,
        });
      }
    }, [apiEndpoint, oneYearAgoFormatted, nowFormatted, user_id]);

    return <div ref={chartRef}></div>;
  }
);

export default DashboardChart;
