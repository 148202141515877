import * as axios from 'axios';
import {
    Link, useLocation
  } from "react-router-dom";
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import ComonHeader from '../include/ComonHeaderMbranch'


import '../../../assest/css/reset.css';
import '../../../assest/css/layout.css';


import '../../../assest/css/datepicker.min.css';
import '../../../assest/css/reset.css';
import '../../../assest/css/layout.css';
import logo_02 from '../../../assest/img/common/logo_02.png';


function MbranchHeader() {
    
    const navigate = useNavigate();

    const [user_name, set_user_name] = useState("");
    const [user_phone, set_user_phone] = useState("");
    const [user_level, set_user_level] = useState("");
    
    useEffect(() => {

        const loginId = sessionStorage.getItem("loginId")

        if(loginId === null){
            navigate('/Mbranch/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }
        
        axios.post('/api/mbranch/userInfo', bodyPage).then(function(res){

            if(res.status === 401 || res.data.length === 0){
                alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
                navigate('/Mbranch/');
                return;
            }

            res.data.map((v, index) => {    
                set_user_name(v.user_name);
                set_user_phone(v.user_phone); 
                set_user_level(v.user_level);  

            });
        }).catch(error => {
        
            //if(error.response.status === 401){
                alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
                navigate('/Mbranch/');
                return;
            //}

        });
        
    }, []);

    return (
        <>
            <ComonHeader/>
        </>
    )
}



export default MbranchHeader