import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/AgencyHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff, GetBankName} from "../../../include/ConfigSet_Terminal";
import {maskingFunc} from "../../../include/ConfigSet_masking";
import jquery from 'jquery';
import $ from 'jquery';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";



function AgencySettlementStoreResult() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(15);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    
    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('St_branch_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });



    
    

    // PG 리스트
    const [sugiList, setSugiList] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
    }

    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value);
    }
    
    
    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'St_branch_name';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';
    
    const status = qs.status ? qs.status : '';
    $("#status").val(status);


    const viewType = qs.viewType ? qs.viewType : 'store';
    $("#viewType").val(viewType);


    const dateType = qs.dateType ? qs.dateType : 'during';
    $("#dateType").val(dateType);
    
    
    
    const page = qs.pg? qs.pg : 1;

    let corp_class_txt = '';
    let contract_status_txt = '';
    let login_able_txt = '';
    let calculate_txt = '';
    

   


    /**
     * 
     * @param {{nums:number,regdate:string}} nums 게시판 키값
     * @param {*} regdate 등록일자
     * @param {*} branch_name 지사명
     * @param {string} representative 대표자명
     * @param {string} user_id ID
     * @param {string} corp_class 구분
     * @param {string} calculate 정산Type
     * @param {string} commission 제공수수료
     * @param {string} contract_status 상태
     * @param {string} login_able 로그인 허용여부
     * @returns 
     */

    // 정상매출 - 건수 합계
    let total_E_cnt =  0;

    // 정상매출 - 금액 합계
    let total_E_amount = 0;


    // 정상매출 수수료 합계
    let total_E_income =  0;

    // 정상매출 정산금액 합계
    let total_E_fee = 0;

    // 차감매출 - 건수 합계
    let total_EC_cnt =  0;

    // 차감매출 - 금액 합계
    let total_EC_amount = 0;

    // 차감매출 수수료	 - 합계
    let total_EC_income = 0;

    // 차감매출 차감금액	 - 합계
    let total_EC_fee = 0;

    
    // 정산차감 - 합계
    let total_calculate_price = 0;





    const Board = ({
        idx,
        nums,
        regid,
        calculate_price,
        calculate_date,
        st_name,
        Total_amount,
        E_cnt,
        E_amount,
        E_income,
        E_fee,
        EC_cnt,
        EC_amount,
        EC_income,
        EC_fee,
        bank,
        account_num,
        account_holder
    }) => {

        
        return (
            <>
            <tr>
                <td>{nums}</td>
                <td>{calculate_date}</td>
                <td>{st_name}</td>
                <td>{(E_fee-EC_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="purple_txt">{(E_fee-EC_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                <td className="blue_txt">{E_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{E_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{E_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{E_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                
                <td className="red_txt">{EC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="red_txt">{EC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="red_txt">{EC_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="red_txt">{EC_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="red_txt">{EC_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                <td>{calculate_price*-1}</td>

                <td className="blue_txt">{(E_cnt-EC_cnt).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{(E_amount-EC_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{(E_income-EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{(E_fee-EC_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>0</td>
                <td>{bank}</td>
                <td>{GetBankName(bank)}</td>
                <td>{maskingFunc.account(account_num)}</td>
                <td>{maskingFunc.name(account_holder)}</td>
            </tr>
            </>
        );






    };


    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates);
        navigate('/SiteAdmin/_management/list?searchWord='+searchWord+'&searchCates='+searchCates);
        
    }

    // 전체 수량 구하는 
    function getTotalCnt(){

        const loginId = sessionStorage.getItem("loginId");
        
        if(loginId === null){
            navigate('/SiteAdmin/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }

      
        
        let bodyQuery = {
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: parent_idx2,
            status: status,
            viewType: viewType
        }

        axios.post('/api/terminal/ag/settlement/store_result_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })


    }

    
    

    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
   

        getTotalCnt();
        
        if(sdate !== ""){
            setStartDate( new Date(sdate) );
        }

        if(edate !== ""){
            setEndDate( new Date(edate) );
        }

        if(q_parent_idx1 !== ""){
            set_parent_idx1(q_parent_idx1);
        }
        /*
        if(q_parent_idx2 !== ""){
            set_parent_idx2(q_parent_idx2)
        }
        */
        

    },[sWord, sCate]);
    /*
    pg, sWord
    */

  
    useEffect(()=>{

        const loginId = sessionStorage.getItem("loginId");

 
        
        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: parent_idx2,
            status: status,
            viewType: viewType,
            dateType: dateType

        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/ag/settlement/store_result_list', bodyPage).then(function(res){
            console.log("res : ", res);
            
            setViewContent(res.data);
            //console.log(res.data)
        }) 

          

        
    },[sWord, sCate, page]);
    // nums
    
    
    // 수기상점코드 리스트 가져오기
    function getSugiList(){
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/terminal/terminal/sugi_sel_list',bodyPage).then(function(res){            
            setSugiList(res.data);
            
        })

    }
   
    function rendSugiList(){
        return (
                sugiList.map((v, index) => {
                    return (
                        <option value={v.pg} key={v.idx} >{v.sname}</option>
                    )
                })
            

        )
    }


    function rendManagerList(){
        return (
            <select name="parent_idx1" id="parent_idx1" onChange={on_set_parent_idx1_handler} value={parent_idx1}  >
                <option value=''>지사</option>
            {
                managerList1.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    function rendManagerList2(){
        return (
            <select name="parent_idx2" id="parent_idx2" onChange={on_set_parent_idx2_handler} value={parent_idx2} >
                <option value=''>총판</option>
            {
                managerList2.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    
    useEffect(() => {
        getManagerList1()
        getSugiList()
    },[])

    // 총판 내역 가져오기
    useEffect(() => {
        getManagerList2(q_parent_idx1);

        
    },[managerList1])
  


    function getManagerList1(){
        
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list',bodyPage).then(function(res){            
            setManagerList1(res.data);
            
        })
    }

    function getManagerList2(temp_idx){

        
        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list',bodyPage).then(function(res){            
            setManagerList2(res.data);

            
            
        })
        if(q_parent_idx2 !== ""){
            set_parent_idx2(q_parent_idx2)
        }
    }



    const fn_submit = (e) => {
        $("#pg").val("1");
        //e.preventDefault();
    }

    

    


    return (
        <>
        <div id="wrap">
            <Header/>

            
            <div id="container"  data-menu-page="set_result">
                <h3 className="page_ttl">가맹점 정산 결과</h3>
                <form name="" id="" action="./store_result" method="get" onSubmit={ fn_submit } >
                    <input type="hidden" name="pg" id="pg" value={page} />

                    <div className="top_filter">

                        <div className="date_box datepick_box">
                            <input type="hidden" name="sdate" id="sdate" value={ (startDate === null || startDate === "") ? '' : new Date( startDate.toString().split('GMT')[0]+' UTC') .toISOString().slice(0,10) } />
                            <input type="hidden" name="edate" id="edate" value={ (endDate === null || endDate === "") ? '' : new Date( endDate.toString().split('GMT')[0]+' UTC') .toISOString().slice(0,10) } />
                            
                            <div className="datepick">
                                <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)}  />
                            </div>
                            <span>-</span>
                            <div className="datepick">
                                <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                        </div>

                        <div className="sel_box">
                            <select name="searchCates" id="searchCates">
                                <option value="St_branch_name">가맹점명</option>
                            </select>
                        </div>

                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" placeholder='검색해주세요'/>
                            <button type="submit" className="btn_st_02 search">검색</button>
                        </div>


                    </div>
                </form>



                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>
                    </div>
                    <div className="table_box">

                        <table>

                            <thead>

                                <tr>
                                    <th rowSpan="2"> NO</th>
                                    <th rowSpan="2">정산일자</th>
                                    <th rowSpan="2">가맹점명</th>
                                    <th rowSpan="2">정산금액</th>
                                    <th rowSpan="2" className="purple_txt">입금금액 </th>

                                    <th colSpan="4" className="blue_txt th_top left_line">정상매출</th>
                                    <th colSpan="5" className="red_txt th_top left_line right_line">차감매출</th>

                                    <th rowSpan="2">정산차감</th>
                                    <th colSpan="4" className="blue_txt th_top left_line right_line">매출합계</th>

                                    <th rowSpan="2">타행 Fee	</th>
                                    <th rowSpan="2">은행코드	</th>
                                    <th rowSpan="2">입금은행	</th>
                                    <th rowSpan="2">계좌번호	</th>
                                    <th rowSpan="2">예금주	</th>
                                </tr>
                                <tr>
                                    <th className="blue_bg left_line">건수</th>
                                    <th className="blue_bg">금액</th>
                                    <th className="blue_bg">수수료</th>
                                    <th className="blue_bg">정산금액</th>

                                    <th className="red_bg left_line">건수</th>
                                    <th className="red_bg">금액</th>
                                    <th className="red_bg">수수료</th>
                                    <th className="red_bg">차감설정금액</th>
                                    <th className="red_bg right_line">완료금액</th>

                                    <th className="blue_bg left_line">건수</th>
                                    <th className="blue_bg">총금액</th>
                                    <th className="blue_bg">총수수료</th>
                                    <th className="blue_bg right_line">총정산금액</th>

                                </tr>

                            </thead>

                            <tbody>

                            {
                                viewContent.map((v, index) => {

                                    // 정상매출 - 건수 합계
                                    total_E_cnt = parseInt(total_E_cnt) + parseInt(v.E_cnt);

                                    // 정상매출 - 금액 합계
                                    total_E_amount = parseInt(total_E_amount) + parseInt(v.E_amount);

                                    // 정상매출 수수료 합계
                                    total_E_income = parseInt(total_E_income) + parseInt(v.E_income);

                                    // 정상매출 정산금액 합계
                                    total_E_fee = parseInt(total_E_fee) + parseInt(v.E_fee);


                                    // 차감매출	 - 건수 합계
                                    total_EC_cnt = parseInt(total_EC_cnt) + parseInt(v.EC_cnt);

                                    // 차감매출	 - 금액 합계
                                    total_EC_amount = parseInt(total_EC_amount) + parseInt(v.EC_amount);

                                    // 차감매출 수수료	 - 합계
                                    total_EC_income = parseInt(total_EC_income) + parseInt(v.EC_income);

                                    // 차감매출 차감금액	 - 합계
                                    total_EC_fee = parseInt(total_EC_fee) + parseInt(v.EC_fee);

                                    // 정산차감 - 합계
                                    total_calculate_price = parseInt(total_calculate_price) + parseInt(v.calculate_price);

                                    

                                    return (
                                        <Board
                                            key={v.idx}
                                            idx={v.idx}
                                            regid={v.regid}
                                            calculate_price={v.calculate_price}
                                            calculate_date={v.calculate_date}
                                            nums={nums-index}
                                            st_name={v.st_name}
                                            Total_amount={v.Total_amount}
                                            E_cnt={v.E_cnt}
                                            E_amount={v.E_amount}
                                            E_income={v.E_income}
                                            E_fee={v.E_fee}
                                            EC_cnt={v.EC_cnt}
                                            EC_amount={v.EC_amount}
                                            EC_income={v.EC_income}
                                            EC_fee={v.EC_fee}
                                            bank={v.bank}
                                            account_num={v.account_num}
                                            account_holder={v.account_holder}
                                        />
                                    );
                                })   
                            }
                                <tr className="total">
                                    <td colSpan="3">TOTAL</td>
                                    <td>{(total_E_fee-total_EC_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{(total_E_fee-total_EC_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_E_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_E_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_E_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_E_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_calculate_price*-1}</td>
                                    <td>{(total_E_cnt-total_EC_cnt).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{(total_E_amount-total_EC_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{(total_E_income-total_EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{(total_E_fee-total_EC_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>0</td>
                                    <td colSpan="4"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {
                        viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>






            </div>

            
            
        </div>

        <Footer/>
        </>
    )

    
}
export default AgencySettlementStoreResult