import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import NoticeForm from "../../../../page/notice/Form";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';


function SiteAdminManageNoticeForm() {


    return (
        <>
        <div id="wrap">
            <Header/>        
            
            <NoticeForm user_level={'1'} user_site={'SiteAdmin'} />
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManageNoticeForm