import * as axios from 'axios';
import React from 'react'
import Header from "../../../include/AgencyHeader";
import Footer from "../../../include/SiteAdminFooter";
import HolidayList from "../../../../page/holiday/List";

function AgencyManageHoliday() {
              
    return (
        <>
        <div id="wrap">
            <Header/>

            <HolidayList />
        </div>

        <Footer/>
        </>
    )


}
export default AgencyManageHoliday