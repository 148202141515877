import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';

import Header from "../../../include/StoreHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import jquery from 'jquery';
import $ from 'jquery';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";


function StorePayPayTotalList() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(15);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    
    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('branch_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'authno';
    $("#searchCates").val(sCate);

    
    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);


    
    const status = qs.status ? qs.status : '';
    $("#status").val(status);


    const page = qs.pg? qs.pg : 1;


    const Board = ({
        idx,
        E_cnt,
        E_income,
        EC_cnt,
        EC_income,
        E_fee,
        EC_fee
    }) => {

        return (
            <tr>
                <td>{E_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{E_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="orange">{EC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="orange">{EC_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{(E_income-EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{(E_fee-EC_fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            </tr>
        );
    };

    const fn_submit = (e) => {
        //e.preventDefault();

    }

    useEffect(()=>{

        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
   
        if(sdate !== ""){
            setStartDate( new Date(sdate) );
        }

        if(edate !== ""){
            setEndDate( new Date(edate) );
        }


        const loginId = sessionStorage.getItem("loginId");
        
        let bodyPage = {
            user_id: loginId,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/store/pay/payTotalList', bodyPage).then(function(res){
            setViewContent(res.data);
        })  
    },[]);

    return(
        <>
        <div id="wrap">
            <Header/>

            <div id="container"  data-menu-page="pay_total">
                <h3 className="page_ttl">매출합계</h3>

                <form name="" id="" action="./payTotalList" method="get"  >
                    <div className="top_filter">
                        <div className="sel_box">
                            <select name="chkdate" id="chkdate" >
                                <option value="authdate">거래일자</option>
                                <option value="calculatedate">정산일</option>
                            </select>
                        </div>
                        <div className="date_box datepick_box">
                            <input type="hidden" name="sdate" id="sdate" value={ (startDate === null || startDate === "") ? '' : new Date( startDate.toString().split('GMT')[0]+' UTC') .toISOString().slice(0,10) } />
                            <input type="hidden" name="edate" id="edate" value={ (endDate === null || endDate === "") ? '' : new Date( endDate.toString().split('GMT')[0]+' UTC') .toISOString().slice(0,10) } />

                            <div className="datepick">
                                <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)}  />
                            </div>
                            <span>-</span>
                            <div className="datepick">
                                <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                        </div>
                        
                        <div className="btn_box">
                            <button type="submit" className="btn_st_02 blue search">검색</button>
                        </div>



                    </div>
                </form>

                <div className="bot_item">
                  

                    <div className="table_box">
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan="2" className="th_top">매출내역</th>
                                    <th colSpan="2" className="th_top">취소</th>
                                    <th colSpan="2" className="th_top">합계</th>
                                </tr>
                                <tr>
                                    <th className="left_line">건</th>
                                    <th>금액</th>
                                    <th>건</th>
                                    <th>금액</th>
                                    <th>금액</th>
                                    <th>가맹점정산</th>
                                </tr>
                            </thead>

                            <tbody>
                                
                            {
                                viewContent.map((v, index) => {
                                    return (
                                        <Board
                                            key={v.idx}
                                            E_cnt={v.E_cnt}
                                            E_income={v.E_income}
                                            EC_cnt={v.EC_cnt}
                                            EC_income={v.EC_income}
                                            E_fee={v.E_fee}
                                            EC_fee={v.EC_fee}
                                        />
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default StorePayPayTotalList