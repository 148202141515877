import * as axios from "axios";
import { Link } from "react-router-dom";

//import * as axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import ApexCharts from "apexcharts";

import "../../../../../assest/css/reset.css";
import "../../../../../assest/css/layout.css";
import Header from "../../../include/MbranchHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";

import DashboardChart from "../../../../charts/DashboardChart";
import { axiosPostWithCallback } from "../../../../../util/functionHelper";
import { useFinancialData } from "../../../../../hooks/useFinancialData";

function MbranchMainTerminal() {
  const loginId = sessionStorage.getItem("loginId");

  const chartRef = useRef(null);
  const {
    todayPrice,
    todayIncome,
    todaySpend,
    yesterdayPrice,
    yesterdayIncome,
    yesterdaySpend,
    weekPrice,
    weekIncome,
    weekSpend,
    monthPrice,
    monthIncome,
    monthSpend,
    pay_cnt7,
    pay_hap7,
  } = useFinancialData(loginId, "terminal/mbranch");

  // 정산내역 - 금액
  const [pay_end_hap, set_pay_end_hap] = useState(0);

  // PG 수수료
  const [pg_fee, set_pg_fee] = useState(0);

  // 가맹점 수수료
  const [st_fee, set_st_fee] = useState(0);

  // 지사 수수료
  const [br_fee, set_br_fee] = useState(0);

  // 총판 수수료
  const [di_fee, set_di_fee] = useState(0);

  // 대리점 수수료
  const [ag_fee, set_ag_fee] = useState(0);

  const [notice_res, set_notice_res] = useState({
    code: "notice",
    user_level: "1",
  });

  const [notice_res2, set_notice_res2] = useState({
    code: "notice",
    user_level: "2",
    user_id: sessionStorage.getItem("loginId"),
  });

  const [noticeContent, setNoticeContent] = useState([]);

  // 차트에 넣을 기간
  let chart_caption = "";
  let today = new Date();
  var oneYearAgo = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );
  var oneYearAgoFormatted =
    oneYearAgo.getFullYear() +
    "." +
    (oneYearAgo.getMonth() + 1).toString().padStart(2, "0");
  var nowFormatted =
    today.getFullYear() +
    "." +
    (today.getMonth() + 1).toString().padStart(2, "0");
  chart_caption = oneYearAgoFormatted + "~" + nowFormatted;

  // 차트 날짜 부분 구하기
  let chart_x = [];

  // 차트에 들어갈 월별 매출 배열
  let chart_price = [];

  // 차트에 들어갈 월별 건수 배열
  let chart_cnt = [];

  const [faqContent, setFaqContent] = useState([]);
  const [qnaContent, setQnaContent] = useState([]);

  useEffect(() => {
    axios.post("/api/manage/NoticeMainTop", notice_res).then(function (res) {
      setNoticeContent(res.data);
    });

    axios.post("/api/manage/FaqMainTop", notice_res).then(function (res) {
      setFaqContent(res.data);
    });

    axios.post("/api/manage/QnaMainTop", notice_res2).then(function (res) {
      setQnaContent(res.data);
    });
  }, []);

  return (
    <>
      <div id="wrap">
        <Header />

        <div id="container" className="main">
          {/* payment_info_sec */}
          <div className="payment_info_sec main_cont">
            <div className="w_80">
              <ul className="payment_info_row">
                <li>
                  <strong className="label">
                    금일 매출액 <span>※현재기준</span>
                  </strong>
                  <p className="all_pay">
                    <b>
                      {("" + todayPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + todayIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + todaySpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <strong className="label">전 일 매출액</strong>
                  <p className="all_pay">
                    <b>
                      {("" + yesterdayPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + yesterdayIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + yesterdaySpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <strong className="label">전 주 매출액</strong>
                  <p className="all_pay">
                    <b>
                      {("" + weekPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + weekIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + weekSpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <strong className="label">전 월 매출액</strong>
                  <p className="all_pay">
                    <b>
                      {("" + monthPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + monthIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + monthSpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w_20">
              <div className="color_cont  top">
                <div className="left">
                  <p>결제완료건수</p>
                  <span>최근 1주일 이내</span>
                </div>
                <div className="right">
                  <b>
                    {("" + pay_cnt7)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </b>
                  건
                </div>
              </div>
              <div className="color_cont  bot">
                <div className="left">
                  <p>결제금액</p>
                  <span>최근 1주일 이내</span>
                </div>
                <div className="right">
                  <b>
                    {("" + pay_hap7)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </b>
                  원
                </div>
              </div>
            </div>
          </div>
          {/*  // payment_info_sec // */}

          {/* calculate_sec */}
          <div className="calculate_sec main_cont">
            <div className="graph_cont">
              <div>
                <DashboardChart
                  ref={chartRef}
                  apiEndpoint="/api/terminal/mbranch/chart_month_price"
                  chartCaption="터미널정산"
                  oneYearAgoFormatted={oneYearAgoFormatted}
                  nowFormatted={nowFormatted}
                  user_id={loginId}
                />
              </div>
            </div>
          </div>
          {/* // calculate_sec //  */}

          {/* list_cont_sec */}
          <div className="bod_list_cont main_cont">
            <ul className="bod_list_wrap">
              <li>
                <div className="ttl">
                  <strong>공지사항</strong>
                  <Link to="/Mbranch/_manage/NoticeList">
                    더보기 <i>&#xE001;</i>
                  </Link>
                </div>
                <ul className="bod_list">
                  {noticeContent.map((v, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/Mbranch/_manage/noticeView?idx=${v.idx}`}>
                          <span className="sb_ttl">{v.subject}</span>
                          <em className="date">{v.regdate.substr(0, 10)}</em>
                        </Link>
                      </li>
                    );
                  })}
                  {noticeContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                </ul>
              </li>
              <li>
                <div className="ttl">
                  <strong>자주하는 질문</strong>
                  <Link to="/Mbranch/_manage/FaqList">
                    더보기 <i>&#xE001;</i>
                  </Link>
                </div>
                <ul className="bod_list">
                  {faqContent.map((v, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/Mbranch/_manage/FaqView?idx=${v.idx}`}>
                          <span className="sb_ttl">{v.subject}</span>
                          <em className="date">{v.regdate.substr(0, 10)}</em>
                        </Link>
                      </li>
                    );
                  })}
                  {faqContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                </ul>
              </li>
              <li>
                <div className="ttl">
                  <strong>1대1 문의</strong>
                  <Link to="/Mbranch/_manage/QnaList">
                    더보기 <i>&#xE001;</i>
                  </Link>
                </div>
                <ul className="bod_list">
                  {qnaContent.map((v, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/Mbranch/_manage/QnaView?idx=${v.idx}`}>
                          <span className="sb_ttl">{v.subject}</span>
                          <em className="date">{v.regdate.substr(0, 10)}</em>
                        </Link>
                      </li>
                    );
                  })}
                  {qnaContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                </ul>
              </li>
            </ul>
          </div>
          {/*  // list_cont_sec // */}

          {/* <FooterInfo/> */}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default MbranchMainTerminal;
