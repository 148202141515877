import * as axios from 'axios';
import React, {useEffect, useState} from 'react'


function PgCardName( pgType, cardCode ) {

    let payjoaCardCode = Array();
    
    if(pgType === "korpay"){

        // 페이조아 카드사 코드표
        payjoaCardCode['01'] = "비씨";
        payjoaCardCode['02'] = "국민";
        payjoaCardCode['03'] = "하나(구외환)";
        payjoaCardCode['04'] = "삼성";
        payjoaCardCode['06'] = "신한";
        payjoaCardCode['07'] = "현대";
        payjoaCardCode['08'] = "롯데";
        payjoaCardCode['09'] = "한미";
        payjoaCardCode['10'] = "신세계한미";
        payjoaCardCode['11'] = "씨티";
        payjoaCardCode['12'] = "NH농협카드";
        payjoaCardCode['13'] = "수협";
        payjoaCardCode['14'] = "평화";
        payjoaCardCode['15'] = "우리";
        payjoaCardCode['16'] = "하나";
        payjoaCardCode['17'] = "동남(주택)";
        payjoaCardCode['18'] = "주택";
        payjoaCardCode['19'] = "조흥(강원)";
        payjoaCardCode['20'] = "축협(농협)";
        payjoaCardCode['21'] = "광주";
        payjoaCardCode['22'] = "전북";
        payjoaCardCode['23'] = "제주";
        payjoaCardCode['24'] = "산은";
        payjoaCardCode['25'] = "해외비자";
        payjoaCardCode['26'] = "해외마스터";
        payjoaCardCode['27'] = "해외다이너스";
        payjoaCardCode['28'] = "해외AMX";
        payjoaCardCode['29'] = "해외JCB";
        payjoaCardCode['30'] = "해외";
        payjoaCardCode['31'] = "SK-OKCashBag";
        payjoaCardCode['32'] = "우체국";
        payjoaCardCode['33'] = "MG새마을체크";
        payjoaCardCode['34'] = "중국은행체크";
        payjoaCardCode['38'] = "은련";
        payjoaCardCode['46'] = "카카오";
        payjoaCardCode['47'] = "강원";

    }
    return (
        <>
            {payjoaCardCode[cardCode]}
        </>
    );
}





function PgName( pgType ) {

    let pgOut = "";
    
    if(pgType === "korpay"){
        pgOut = "코페이";   
    }
    return (
        <>
            {pgOut}
        </>
    );
}

function GetPayStatus(status){
    let payStatus = Array();
    
    if(status === undefined || status === ''){

        return (
            <>
                실패
            </>
        );

    }else{

        payStatus['B'] = "정산전";
        payStatus['BC'] = "정산전취소";
        payStatus['D'] = "정산보류";
        payStatus['E'] = "정산완료";
        payStatus['EC'] = "정산완료후취소";
            
        
        return (
            <>
                {payStatus[status]}
            </>
        );

    }

}

function GetPayGubun(status){
    let payStatus = Array();
    
    if(status === undefined || status === ''){

        return (
            <>
                실패
            </>
        );

    }else{

        payStatus['B'] = "승인";
        payStatus['BC'] = "취소";
        payStatus['D'] = "정산보류";
        payStatus['E'] = "승인";
        payStatus['EC'] = "취소";
            
        
        return (
            <>
                {payStatus[status]}
            </>
        );

    }

}

// 가맹점 정산
function Calculate_store(orderno, userid){

    
    const [commission, set_commission] = useState("");
    const [price, set_price] = useState("");

    let bodyPage = {
        loginId: userid
    }

    let out = "";

    axios.post('/api/store/storeInfo', bodyPage).then(function(response){
        
        //console.log(response.data.user_id)
        set_commission(response.data[0].commission);

        let bodyPage2 = {
            orderno: orderno
        }

        axios.post('/api/terminal/pay/payInfo', bodyPage2).then(function(res2){
            set_price(res2.data[0].amount);

        });
      

    });

    
    return (
        <>
            {commission} / {price}
        </>
    );
    

}




// 보류가 가능한지 확인하기 
/*
같은 승인번호이면서 결제 금액 x -1 값을 가지고 있으면 취소된 내역이 있기 때문에 보류가 안되어야 함.
*/
function ChkDelayAble(authno, amount){

    
    const [chkAble, set_chkAble] = useState("");

    let bodyPage = {
        authno: authno,
        amount: amount
    }

    let out = "";

    axios.post('/api/terminal/pay/delay_able', bodyPage).then(function(response){
        set_chkAble(response.data[0].cnts);
    });

    
    return chkAble;
}

// 보류설정
function ChgDelay(idx){

    let bodyPage = {
        idx: idx
    }


    axios.post('/api/terminal/pay/chg_delay', bodyPage).then(function(response){
        
    });
}



// 보류해제
function ChgDelayOff(idx){

    let bodyPage = {
        idx: idx
    }


    axios.post('/api/terminal/pay/chg_delay_off', bodyPage).then(function(response){
        
    });
}


// 보류설정 가맹점
function SettlementStChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/terminal/settlement/SettlementStChk', bodyPage).then(function(response){
    });
}


// 보류설정 지사
function SettlementBrChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/terminal/settlement/SettlementBrChk', bodyPage).then(function(response){
    });
}

// 보류설정 총판
function SettlementDiChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/terminal/settlement/SettlementDiChk', bodyPage).then(function(response){
    });
}

// 보류설정 대리점
function SettlementAgChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/terminal/settlement/SettlementAgChk', bodyPage).then(function(response){
    });
}


// 은행이름 가져오기
function GetBankName(code){

    let bodyPage = {
        code: code
    }

    const [bankName, set_bankName] = useState("");

    axios.post('/api/management/bankName', bodyPage).then(function(response){
        set_bankName(response.data[0].bank_name)
    });

    return bankName;
}


function Calculate_set(idx){

    let bodyPage = {
        idx: idx
    }

    axios.post('/api/management/pay/chg_cal', bodyPage).then(function(response){
        
    });
}

function TodayCnt(regdate, card_no, authno, order_no, reg_id, idx){
    /*
    regdate : 결제일자
    card_no : 카드번호
    authno : 결제 번호 (취소된 내역들은 중복으로 체크하지 않기 위해서 확인 용도)
    order_no : 같은 주문 번호의 합으로 최종적으로 결제 내역이 있는지 확인 용도
    reg_id : 가맹점 아이디 (가맹점이 다르면 중복이 아니기 때문)
    idx : 화면에서 보여주는 행이 중복으로 표기해줄지 여부를 판단하기 위한 키값
    */


    let bodyPage = {
        regdate: regdate,
        card_no: card_no,
        reg_id: reg_id
      };
    
    // axios.post 요청의 결과값을 처리하기 위해 Promise를 반환합니다.
    return axios.post('/api/management/pay/doublePriceChk', bodyPage)
    .then(function(response) {
        if (response.data.length > 0 && response.data[0].cnts > 1) {
            return "T";
        } else {
            return "F";
        }
    })
    .catch(function(error) {
        console.error(error);
    });

}


function DoublePayChk(regdate, cardno, regid){
    /*
    regdate : 결제일자
    card_no : 카드번호
    reg_id : 가맹점 아이디 (가맹점이 다르면 중복이 아니기 때문)
    */

    let body = {
        user_id: regid
    };

    let bodyPage = {
        regdate: regdate,
        card_no: cardno,
        user_id: regid
    };


    // 가맹점이 중복결제 막혀있는지 우선 확인
    return axios.post('/api/store/storeDoubleAble', body).then(function(res){
        
        console.log(res.data[0].double_able);

        if(res.data[0].double_able == "Y"){
            //return "F";
            return Promise.resolve("F");

        }else{
            return axios.post('/api/terminal/pay/doublePayChk', bodyPage)
            .then(function(response) {

                //alert( response.data[0].cnts );
                //console.log(bodyPage);

                if (response.data.length > 0 && response.data[0].cnts > 1) {
                    //return "T";
                    return Promise.resolve("T");
                } else {
                    //return "F";
                    return Promise.resolve("F");
                }
            })
            .catch(function(error) {
                console.error(error);
            });

        }

    });
}

function Set_User_Name_Cert(user_name){    
    
    let ret_user_nm = user_name.substr(0, (user_name.length)-2 ) + "*" + user_name.substr(-1,1);
    
    if(ret_user_nm === '*'){
        ret_user_nm = '';
    }
    return ret_user_nm;
}

function Set_User_HP_Cert(user_hp){    
    
    let userphone_new = "";
    for (let i= 0; i<user_hp.length - 4; i++){
        userphone_new += "*";
    }
    userphone_new += user_hp.substr(-4,4);
    
    return userphone_new;
}

export  {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay
    , ChgDelayOff, SettlementStChk, SettlementBrChk, SettlementDiChk, SettlementAgChk, GetBankName
    , Calculate_set, TodayCnt, DoublePayChk, Set_User_Name_Cert, Set_User_HP_Cert

}