import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff, TodayCnt, Set_User_HP_Cert, Set_User_Name_Cert} from "../../../include/ConfigSet_Terminal";
import {maskingFunc} from "../../../include/ConfigSet_masking";
import jquery from 'jquery';
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";

export default function SiteAdminPayTransactionHistoryTerminalPop() {



    const navigate = useNavigate();
    const location = useLocation();

    const qs = queryString.parse(window.location.search);
    const idx = qs.idx ? qs.idx : '';
    


    const [bank_name, set_bank_name] = useState("");
    const [kp_cardNo, set_kp_cardNo] = useState("");
    const [cancelYN, set_cancelYN] = useState("");
    const [kp_quota, set_kp_quota] = useState("");
    const [appDtm, set_appDtm] = useState("");

    const [username, set_username] = useState("");
    const [appNo, set_appNo] = useState("");
    const [ordNo, set_ordNo] = useState("");
    const [goodsName, set_goodsName] = useState("");
    const [amt, set_amt] = useState("");

    const [sname, set_sname] = useState("");
    const [bs_number, set_bs_number] = useState("");
    const [bs_name, set_bs_name] = useState("");
    const [bs_addr, set_bs_addr] = useState("");

    const [St_branch_name, set_St_branch_name] = useState("");
    const [corp_nun, set_corp_nun] = useState("");
    const [corp_addr, set_corp_addr] = useState("");
    
    
    
    

    useEffect(() => {

        let bodyPage = {
            idx: idx
        }
        
        
        axios.post('/api/terminal/pay/transaction_history_chit', bodyPage).then(function(res){
            
            res.data.map((v, index) => {

                console.log('v : ', v);
                set_bank_name(v.bank_name);
                set_kp_cardNo(v.kp_cardNo);
                set_cancelYN(v.cancelYN);
                set_kp_quota(v.kp_quota);
                set_appDtm(v.appDtm);
                set_username(v.username);
                set_appNo(v.appNo);
                set_ordNo(v.ordNo);
                set_goodsName(v.goodsName);
                set_amt(v.amt);
                set_sname(v.sname);
                set_bs_number(v.bs_number);
                set_bs_name(v.bs_name);
                set_bs_addr(v.bs_addr);
                set_St_branch_name(v.St_branch_name);
                set_corp_nun(v.corp_nun);
                set_corp_addr(v.corp_addr);
                
                
            })
            
        }) 

        return () => {
          
        };
    }, []);


    const Fn_Print =()=>{
    
        window.print();
    }

    return (
        <div className="popup_wrap">
        <div id="divDetailPop" className="pop_con w500">
                <div className="tit">
                    <h4 >신용카드 매출전표</h4>
                </div>
                <div className="pop_inner">
                    <h3 className="mt0 ">결제정보</h3>
                    <div className="stats_tbl">
                        <table>
                        <colgroup>
                        <col width="100px"/>
                        <col width="150px"/>
                        <col width="100px"/>
                        <col width="200px"/>
                        </colgroup>
                            <tbody><tr>
                                <th>카드종류</th>
                                <td><span id="cardNm">{bank_name}</span></td>
                                <th>카드번호</th>
                                <td><span id="cardNo">{kp_cardNo}</span></td>
                            </tr>
                            <tr>
                                <th >거래상태</th>
                                <td><span id="statusNM">{(cancelYN === 'N' ? '승인' : '취소')}</span></td>
                                <th >할부</th>
                                <td ><span id="allotMon">{kp_quota}</span></td>
                            </tr>
                            <tr>
                                <th>결제일시</th>
                                <td colSpan={3} className="br04"><span id="authDt">{appDtm}</span></td>
                            </tr>
                        </tbody></table>
                    </div>
                    <span className="mt0">구매정보</span>
                    <span className="mt0" id="paymentStatusInfo">결제금액정보</span>
                    <div className="stats_tbl sales">
                        <table>
                    <colgroup>
                        <col width="100px"/>
                        <col width="150px"/>
                        <col width="100px"/>
                        <col width="150px"/>
                        </colgroup>
                            <tbody>
                            <tr>
                                <th className='bg-pink'><span id="spanCustomerNM">주문자명</span></th>
                                <td><span id="customerNm">{username}</span></td>
                                <th className='bg-navy'>부가세</th>
                                <td  className="right"><span id="authTaxAmt">{(amt * 0.01).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span> 원</td>
                            </tr>
                            <tr>
                                <th className='bg-pink'>승인번호</th>
                                <td><span id="authNo">{appNo}</span></td>
                                <th  className='bg-navy'>주문금액</th>
                                <td className="right "><span id="auttotalAmthTaxAmt">{(amt - (amt * 0.01)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span> 원</td>
                            </tr>
                            <tr>
                                <th className='bg-pink'>주문번호</th>
                                <td><p id="orderNo">{ordNo}</p></td>
                                <th  className='bg-navy'>할인금액</th>
                                <td className="right ">0 원</td>
                            </tr>
                            <tr>
                                <th rowSpan="4" className='bg-pink'>상품명</th>
                                <td rowSpan="4" className="br04"><span id="productNm">{goodsName}</span></td>
                            </tr>
                           
                            <tr>
                                <th className='bg-navy'>총 결제금액</th>
                                <td className="right br04 e4007f "><span className="e4007f" id="totalAmt2">{amt.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span> 원</td>
                            </tr>
                        </tbody></table>
                    </div>
    {/*                 
                    <h3 className="mt0 ">이용상점정보</h3>
                    <div className="stats_tbl">
                        <table>
                    <colgroup>
                        <col width="100px"/>
                        <col width="150px"/>
                        <col width="100px"/>
                        <col width="150px"/>
                        </colgroup>
                            <tbody>
                            <tr>
                            <th>상점명</th>
                                <td><span id="corporationNm"></span></td>
                                <th >대표자</th>
                                <td><span id="ceoNm"></span></td>
                            </tr>
                            <tr>
                                <th >URL</th>
                                <td><span id="url"></span></td>
                                <th >사업자번호</th>
                                <td><span id="regNo"></span></td>
                            </tr>
                            <tr>
                                <th >이용문의</th>
                            </tr>
                            <tr>
                                <th >주소</th>
                                <td className="br04" colspan="3"><span id="addrNm"></span></td>
                            </tr>
                        </tbody></table>
                    </div> */}
                    
                    <h3 className="mt0 ">가맹점 정보</h3>
                    <div className="stats_tbl">
                        <table>
                    <colgroup>
                        <col width="100px"/>
                        <col width="150px"/>
                        <col width="100px"/>
                        <col width="150px"/>
                        </colgroup>
                            <tbody>
                            <tr>
                            <th>상호명</th>
                                <td>{St_branch_name}</td>
                                <th>사업자번호</th>
                                <td>{corp_nun}</td>
                            </tr>
                            <tr>
                                <th >대표자</th>
                                <td colSpan={3}>{bs_name}</td>
                                {/* <th >가맹점번호</th> */}
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colSpan={3}>{corp_addr}</td>
                            </tr>
                        </tbody></table>
                    </div>
                    <div className="no_ufound text_box">
                        <ul>
                            <li><span className="e4007f ">신용카드 청구서에는 <span id="t_text">{sname}</span>로 표기됩니다.</span></li>
                            <li><span >신용카드 매출전표는 부가가치세법 제32조 2 제3항에 의하여 발행되었으며, 동법 제17조 1항 및 제26조 제3항의 규정에 따라 매입세액으로 공제받을 수 있습니다. </span></li>
                        </ul>
                    </div>
                </div>
                <div className="pop_btn">
                    <form id="mailForm">
                        {/* <input type="text" id="userMail" name="userMail" value="" className="email" placeholder="이메일 입력"/> */}
                        <input type="button" id="hprint" value="인쇄하기" onClick={Fn_Print}/>
                        {/* <input type="button" id="btnSendMail" value="메일발송" className="gray"/> */}
                        {/* <input type="hidden" id="mailData" name="mailData" value=""/> */}
                    </form>
                </div>

                {
                    (cancelYN === 'Y') && (
                        <div id="cancel_view" className="cancel_view"><img src="https://agenttest.payjoa.co.kr/images/egovframework/cmmn/credit_cancel.png"/></div>
                    )

                }
                

            </div>
    </div>
    );
}
