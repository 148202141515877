import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import NoticeView from "../../../../page/notice/View";
import Header from "../../../include/MbranchHeader";
import Footer from "../../../include/SiteAdminFooter";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';


function MbranchManageNoticeView() {


    return (
        <>
        <div id="wrap">
            <Header/>        
            
            <NoticeView user_level={'2'} user_site={'Mbranch'} />
            
        </div>

        <Footer/>
        </>
    )
}

export default MbranchManageNoticeView