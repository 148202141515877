import * as axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import "../../../assest/css/datepicker.min.css";
import "../../../assest/css/reset.css";
import "../../../assest/css/layout.css";

import logo_02 from "../../../assest/img/common/logo_02.png";
import TimerBox from "../../page/timerBox/Index";
import { fetchWithCallback } from "../../../util/functionHelper";

function StoreHeader() {
  const cookie_maxage = process.env.REACT_APP_COOKIE_MAXAGE;
  const [loginTime, setLoginTime] = useState({
    hour: parseInt(cookie_maxage / 60),
    min: parseInt(cookie_maxage % 60),
    sec: 0,
  });

  const [menu_on, set_menu_on] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const sampleLocation = useLocation();
  //console.log(sampleLocation);

  const [user_name, set_user_name] = useState("");
  const [last_login, set_last_login] = useState("");
  const [pay_type, set_pay_type] = useState("");

  const [my_ip, set_my_ip] = useState("");

  // 로그인 날짜
  function converToLocalTime(serverDate) {
    var date = new Date(Date.parse(serverDate));
    const TIME_ZONE = 3240 * 10000;
    return new Date(+date + TIME_ZONE)
      .toISOString()
      .replace("T", " ")
      .replace(/\..*/, "")
      .substring(0, 16);
  }

  // 내 아이피
  const Fn_My_Ip = async () => {
    fetchWithCallback("https://geolocation-db.com/json//", (locationIp) => {
      set_my_ip(locationIp.IPv4);
    });
  };

  useEffect(() => {
    Fn_My_Ip();

    const loginId = sessionStorage.getItem("loginId");

    if (loginId === null) {
      navigate("/Store/");
      return;
    }

    let bodyPage = {
      loginId: loginId,
    };

    axios
      .post("/api/Store/userInfo", bodyPage)
      .then(function (res) {
        if (res.status === 401 || res.data.length === 0) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/Store/");
          return;
        }

        res.data.map((v, index) => {
          set_user_name(v.username);
          set_last_login(v.last_login);
          set_pay_type(v.pay_type);
        });
      })
      .catch((error) => {
        //if(error.response.status === 401){
        alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
        navigate("/Store/");
        return;
        //}
      });
  }, []);

  useEffect(() => {
    const loginId = sessionStorage.getItem("loginId");

    if (loginId === null) {
      navigate("/Store/");
      return;
    }

    let bodyPage = {
      loginId: loginId,
    };

    axios
      .post("/api/store/userInfo", bodyPage)
      .then(function (res) {
        if (res.status === 401 || res.data.length === 0) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/Store/");
          return;
        }

        res.data.map((v, index) => {
          //사용자 정보
        });
      })
      .catch((error) => {
        //if(error.response.status === 401){
        alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
        navigate("/Store/");
        return;
        //}
      });
  }, []);

  useEffect(() => {
    if (sampleLocation.pathname === "/Store/_admin/settingList") {
      set_menu_on("Store6");
    } else if (sampleLocation.pathname === "/Store/_management/noticeList") {
      set_menu_on("Store5");
    } else if (sampleLocation.pathname === "/Store/_management/NoticeView") {
      set_menu_on("Store5");
    } else if (sampleLocation.pathname === "/Store/_management/FaqList") {
      set_menu_on("Store8");
    } else if (sampleLocation.pathname === "/Store/_management/FaqView") {
      set_menu_on("Store8");
    } else if (sampleLocation.pathname === "/Store/_management/QnaList") {
      set_menu_on("Store9");
    } else if (sampleLocation.pathname === "/Store/_management/QnaView") {
      set_menu_on("Store9");
    } else if (sampleLocation.pathname === "/Store/_management/QnaMod") {
      set_menu_on("Store9");
    } else if (sampleLocation.pathname === "/Store/_management/QnaForm") {
      set_menu_on("Store9");
    } else if (sampleLocation.pathname === "/Store/_management/goodsList") {
      set_menu_on("Store4");
    } else if (sampleLocation.pathname === "/Store/_management/goodsForm") {
      set_menu_on("Store4");
    } else if (sampleLocation.pathname === "/Store/_management/goodsMod") {
      set_menu_on("Store4");
    } else if (sampleLocation.pathname === "/Store/_pay/easyPaymentList") {
      set_menu_on("Store3");
    } else if (sampleLocation.pathname === "/Store/_pay/payTotalList") {
      set_menu_on("Store2");
    } else if (
      sampleLocation.pathname === "/Store/_pay/transactionHistoryList"
    ) {
      set_menu_on("Store1");
    } else if (sampleLocation.pathname === "/Store/_pay/calculate_calendar") {
      set_menu_on("Store7");
    }
  }, [sampleLocation]);

  // 로그아웃
  const onLogOut = () => {
    axios.post("/api/logout").then(function (res) {
      if (res.status === 200) {
        sessionStorage.removeItem("loginId");
        navigate("/Store/");
      }
    });
  };

  //연길 시간 종료
  const onEndTime = () => {
    axios.post("/api/logout").then(function (res) {
      if (res.status === 200) {
        sessionStorage.removeItem("loginId");
        navigate("/Store/");
      }
    });
  };

  //시간 연장
  const onReStartTime = () => {
    const loginId = sessionStorage.getItem("loginId");
    let bodyPage = {
      loginId: loginId,
    };
    axios
      .post("/api/Store/userInfo", bodyPage)
      .then(function (res) {
        if (res.status === 401 || res.data.length === 0) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/Store/");
          return;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
          navigate("/Store/");
          return;
        }
      });
  };

  return (
    <>
      <header id="header">
        <div className="left">
          <h1 className="logo">
            <Link to="/Store/_main/main">
              <img src={logo_02} alt="" />
            </Link>
          </h1>
        </div>

        <div className="right">
          <div className="right_item">
            <div>
              <span>
                {user_name} ( {my_ip} )
              </span>
              <span>
                접속일시 : {last_login !== "" && converToLocalTime(last_login)}
              </span>
            </div>
            <TimerBox
              hour={loginTime.hour}
              min={loginTime.min}
              sec={loginTime.sec}
              onEndTimeEvent={onEndTime}
              onReStartTimeEvent={onReStartTime}
            />
            <a href="#" className="logout" onClick={onLogOut}>
              <i></i>로그아웃
            </a>
          </div>
        </div>
      </header>

      <nav id="nav">
        <ul className="gnb sub_menu_wrap">
          {pay_type === 1 && (
            <>
              <li
                data-menu="pay_history"
                className={menu_on === "Store1" ? "on" : null}
              >
                <div className="sub_menu menu_8">
                  <Link
                    to="/Store/_pay_terminal/transactionHistoryList"
                    className="label"
                  >
                    결제내역
                  </Link>
                </div>
              </li>
              <li
                data-menu="set_calendar"
                className={menu_on === "Store7" ? "on" : null}
              >
                <div className="sub_menu menu_2">
                  <Link
                    to="/Store/_pay_terminal/calculate_calendar"
                    className="label"
                  >
                    정산승인 달력
                  </Link>
                </div>
              </li>
              <li
                data-menu="pay_total"
                className={menu_on === "Store2" ? "on" : null}
              >
                <div className="sub_menu menu_5">
                  <Link
                    to="/Store/_pay_terminal/payTotalList"
                    className="label"
                  >
                    매출합계
                  </Link>
                </div>
              </li>
              <li
                data-menu="easy_pay"
                className={menu_on === "Store3" ? "on" : null}
              >
                <div className="sub_menu menu_8">
                  <Link
                    to="/Store/_pay_terminal/easyPaymentList"
                    className="label"
                  >
                    간편결제
                  </Link>
                </div>
              </li>
            </>
          )}

          {pay_type !== 1 && (
            <>
              <li
                data-menu="pay_history"
                className={menu_on === "Store1" ? "on" : null}
              >
                <div className="sub_menu menu_8">
                  <Link
                    to="/Store/_pay/transactionHistoryList"
                    className="label"
                  >
                    결제내역
                  </Link>
                </div>
              </li>
              <li
                data-menu="set_calendar"
                className={menu_on === "Store7" ? "on" : null}
              >
                <div className="sub_menu menu_2">
                  <Link to="/Store/_pay/calculate_calendar" className="label">
                    정산승인 달력
                  </Link>
                </div>
              </li>
              <li
                data-menu="pay_total"
                className={menu_on === "Store2" ? "on" : null}
              >
                <div className="sub_menu menu_5">
                  <Link to="/Store/_pay/payTotalList" className="label">
                    매출합계
                  </Link>
                </div>
              </li>
              <li
                data-menu="easy_pay"
                className={menu_on === "Store3" ? "on" : null}
              >
                <div className="sub_menu menu_8">
                  <Link to="/Store/_pay/easyPaymentList" className="label">
                    간편결제
                  </Link>
                </div>
              </li>
            </>
          )}

          <li data-menu="goods" className={menu_on === "Store4" ? "on" : null}>
            <div className="sub_menu menu_8">
              <Link to="/Store/_management/goodsList" className="label">
                상품관리
              </Link>
            </div>
          </li>
          <li data-menu="notice" className={menu_on === "Store5" ? "on" : null}>
            <div className="sub_menu menu_8">
              <Link to="/Store/_management/noticeList" className="label">
                공지사항
              </Link>
            </div>
          </li>

          <li data-menu="faq" className={menu_on === "Store8" ? "on" : null}>
            <div className="sub_menu menu_8">
              <Link to="/Store/_management/FaqList" className="label">
                자주하는 질문
              </Link>
            </div>
          </li>

          <li data-menu="qna" className={menu_on === "Store9" ? "on" : null}>
            <div className="sub_menu menu_8">
              <Link to="/Store/_management/QnaList" className="label">
                1대1 문의
              </Link>
            </div>
          </li>

          <li
            data-menu="setting"
            className={menu_on === "Store6" ? "on" : null}
          >
            <div className="sub_menu menu_7">
              <Link to="/Store/_admin/settingList" className="label">
                정보변경
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default StoreHeader;
