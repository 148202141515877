import React from 'react'
import ManageLogList from "../../../../page/manageLog/List";
import Header from "../../../include/MbranchHeader";
import Footer from "../../../include/SiteAdminFooter";

function MbranchManageManageLog() {
    

    return(
    <>
        <div id="wrap">
            <Header/>
            
            <ManageLogList user_level={2} />

        </div>

        <Footer/>        
    </>
    )
}

export default MbranchManageManageLog