import * as axios from 'axios';
import {
    Link, useLocation
  } from "react-router-dom";
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import ComonHeader from '../include/ComonHeaderDistributor'


import '../../../assest/css/datepicker.min.css';
import '../../../assest/css/reset.css';
import '../../../assest/css/layout.css';


function DistributorHeader() {
    
    const navigate = useNavigate();
    const [user_name, set_user_name] = useState("");
    const [user_phone, set_user_phone] = useState("");
    const [user_level, set_user_level] = useState("");
    
    useEffect(() => {

        const loginId = sessionStorage.getItem("loginId")
        
        if(loginId === null){
            navigate('/Distributor/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }
        
        axios.post('/api/distributor/userInfo', bodyPage).then(function(res){
            //console.log(res)
            if(res.status === 401 || res.data.length === 0){
                alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
                navigate('/Distributor/');
                return;
            }

            res.data.map((v, index) => {    
                set_user_name(v.user_name);
                set_user_phone(v.user_phone); 
                set_user_level(v.user_level);  

            });
        }).catch(error => {
        
            //if(error.response.status === 401){
                alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
                navigate('/Distributor/');
                return;
            //}

        });
        
    }, []);
    


    return (
        <>
            <ComonHeader/>
        </>
    )
}



export default DistributorHeader