import React, {useState} from 'react'
import * as axios from 'axios';
import {useNavigate} from "react-router-dom";
import '../../../assest/css/adm_login.css';
import '../../../assest/css/reset.css';


function SettingForm({user_level, user_site}) {

    const navigate = useNavigate();

    const [user_info, set_user_info] = useState({
        user_id:'',
        user_name:'',
        user_pw:'',
        user_pw2:'',
        last_login:null,

    });  
    
    const on_pw = (event) => {               
        set_user_info({...user_info, user_pw:event.currentTarget.value})
    } 
    const on_pw2 = (event) => {               
        set_user_info({...user_info, user_pw2:event.currentTarget.value})
    } 

    const onSubmitHandler = (event) => {
        event.preventDefault();

        if(user_info.user_pw !== user_info.user_pw2){
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        let body = {
            user_id:sessionStorage.getItem('loginId'),
            user_pw:user_info.user_pw,            
        }

        axios.post('/api/admin/SettingAdd', body)
        .then(function(response){
            if(response.data === "AddOk"){
                alert("정상적으로 등록되었습니다.");
                window.location.reload();
            }else{
                alert("시스템 에러");                
            }
        },[])
    }
    
    const Fn_User_Info=()=>{

        let bodyPage = {
            loginId: sessionStorage.getItem('loginId')
        }
        
        axios.post('/api/manage/userInfo', bodyPage).then(function(res){                              
            res.data.map((v, index) => {                
                set_user_info({...user_info
                    , user_id:v.user_id
                    , user_name:v.user_name
                    , last_login:v.last_login});
                    
                return ''
            });                       
        })         
    }
    
    React.useEffect(() => {              
        Fn_User_Info();      
    }, [])

     
    // 로그인 날짜
    function converToLocalTime(serverDate) {
        var date = new Date(Date.parse(serverDate));
        const TIME_ZONE = 3240 * 10000;
        return new Date(+date + TIME_ZONE).toISOString().replace('T', ' ').replace(/\..*/, '').substring(0, 16);
    }

    return (
        <>

            
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                                     
                    <h3 className="page_ttl">정보변경</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>ID   </th>
                                        <td>{user_info.user_id}</td>
                                        <th>관리자명</th>
                                        <td>{user_info.user_name} </td>
                                    </tr>
                                    <tr>
                                        <th>신규 비밀번호 입력</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10" >
                                                    <input type="password" name="user_pw" id="user_pw" maxLength={15} onChange={on_pw} required />
                                                        
                                                </div>
                                                <span>(15자 이내)</span>
                                            </div>
                                        </td>
                                        <th>신규 비밀번호 재입력</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10">
                                                    <input type="password" name="user_pw2" id="user_pw2" maxLength={15} onChange={on_pw2} required />
                                                </div>
                                                <span>(15자 이내)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Last update </th>
                                        <td colSpan={3}>{(user_info.last_login !== null) && (converToLocalTime(user_info.last_login)) }</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="top_table_btn">
                        <button className=" blue btn_st_01">등록</button>                       
                    </div>

                </form>

            </div>                   
        </>
    )
}

export default SettingForm