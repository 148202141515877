import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';


import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';

import Header from "../../../include/StoreHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";


function StoreManagementNoticeView() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }



    const [subject, set_subject] = useState("");
    const [contents, set_contents] = useState("");

    //작성일
    const [regdate, set_regdate] = useState('');

    useEffect(() => {

        console.log(sIdx);

        let bodyPage = {
            idx: sIdx
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/manage/NoticeInfo', bodyPage).then(function(res){
            console.log(res.data.map);
            res.data.map((v, index) => {
                set_subject(v.subject);
                set_contents(v.contents);
                set_regdate(v.regdate.substr(0,10));        
            })
            
        }) 


        return () => {
          
        };
    }, []);



    
    return (
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="notice">
                <h3 className="page_ttl">공지사항</h3>

                <div className="board_view">
                    <div className="bd_top">
                        <h3 className="ttl">{subject}</h3>
                    </div>
                    <div className="bd_info">
                        <div className="fl_sb">
                            <div className="left">
                                <p>작성일 : <span>{regdate}</span></p>
                            </div>
                        </div>
                    </div>
                    

                    <div className="bd_body" dangerouslySetInnerHTML={ {__html: contents} }>
                    </div>

                
                </div>
                

                <div className="top_table_btn">
                    <button className=" line_btn btn_st_01" onClick={  () => navigate(-1) } >목록으로</button>
                </div>

            </div>
            
        </div>

        <Footer/>
        </>
    )
}

export default StoreManagementNoticeView