import * as axios from "axios";
import { Link } from "react-router-dom";

//import * as axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import ApexCharts from "apexcharts";

import "../../../../../assest/css/adm_login.css";
import "../../../../../assest/css/reset.css";
import "../../../../../assest/css/layout.css";
import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import DashboardChart from "../../../../charts/DashboardChart";

function SiteAdminMainTerminal() {
  const chartRef = useRef(null);

  // 금일 매출액
  const [todayPrice, set_todayPrice] = useState(0);

  // 금일 입금액
  const [todayIncome, set_todayIncome] = useState(0);

  // 금일 차감액
  const [todaySpend, set_todaySpend] = useState(0);

  // 전일 매출액
  const [yesterdayPrice, set_yesterdayPrice] = useState(0);

  // 전일 입금액
  const [yesterdayIncome, set_yesterdayIncome] = useState(0);

  // 전일 차감액
  const [yesterdaySpend, set_yesterdaySpend] = useState(0);

  // 전주 매출액
  const [weekPrice, set_weekPrice] = useState(0);

  // 전주 입금액
  const [weekIncome, set_weekIncome] = useState(0);

  // 전주 차감액
  const [weekSpend, set_weekSpend] = useState(0);

  // 전월 매출액
  const [monthPrice, set_monthPrice] = useState(0);

  // 전월 입금액
  const [monthIncome, set_monthIncome] = useState(0);

  // 전월 차감액
  const [monthSpend, set_monthSpend] = useState(0);

  // 결제완료건수
  const [pay_cnt7, set_pay_cnt7] = useState(0);

  // 결제금액
  const [pay_hap7, set_pay_hap7] = useState(0);

  // 정산내역 - 금액
  const [pay_end_hap, set_pay_end_hap] = useState(0);

  // 지사수
  const [mBranchCnt, set_mBranchCnt] = useState(0);

  // 총판수
  const [distributor, set_distributor] = useState(0);

  // 대리점 수
  const [agency, set_agency] = useState(0);

  // 가맹점 수
  const [store, set_storet] = useState(0);

  // PG 수수료
  const [pg_fee, set_pg_fee] = useState(0);

  // 가맹점 수수료
  const [st_fee, set_st_fee] = useState(0);

  // 지사 수수료
  const [br_fee, set_br_fee] = useState(0);

  // 총판 수수료
  const [di_fee, set_di_fee] = useState(0);

  // 대리점 수수료
  const [ag_fee, set_ag_fee] = useState(0);

  const [notice_res, set_notice_res] = useState({
    code: "notice",
    user_level: "1",
  });

  const [notice_res2, set_notice_res2] = useState({
    code: "notice",
    user_level: "1",
    user_id: "",
  });

  const [noticeContent, setNoticeContent] = useState([]);

  // 차트에 넣을 기간
  let chart_caption = "";
  let today = new Date();
  var oneYearAgo = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );
  var oneYearAgoFormatted =
    oneYearAgo.getFullYear() +
    "." +
    (oneYearAgo.getMonth() + 1).toString().padStart(2, "0");
  var nowFormatted =
    today.getFullYear() +
    "." +
    (today.getMonth() + 1).toString().padStart(2, "0");
  chart_caption = oneYearAgoFormatted + "~" + nowFormatted;

  // 차트 날짜 부분 구하기
  let chart_x = [];

  // 차트에 들어갈 월별 매출 배열
  let chart_price = [];

  // 차트에 들어갈 월별 건수 배열
  let chart_cnt = [];

  const [faqContent, setFaqContent] = useState([]);
  const [qnaContent, setQnaContent] = useState([]);

  useEffect(() => {
    axios.post("/api/manage/NoticeMainTop", notice_res).then(function (res) {
      setNoticeContent(res.data);
    });

    axios.post("/api/manage/FaqMainTop", notice_res).then(function (res) {
      setFaqContent(res.data);
    });

    axios.post("/api/manage/QnaMainTop", notice_res2).then(function (res) {
      setQnaContent(res.data);
    });
  }, []);

  // 금일 매출액
  function getTodayPrice() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/today_price", bodyQuery)
      .then(function (res) {
        set_todayPrice(res.data.total_price);
      });
  }

  // 금일 입금액
  function getTodayIncome() {
    let bodyQuery = {};
    axios
      .post("/api/terminal//management/todayIncome", bodyQuery)
      .then(function (res) {
        set_todayIncome(res.data.total_price);
      });
  }

  // 금일 차감액
  function getTodaySpend() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/todaySpend", bodyQuery)
      .then(function (res) {
        set_todaySpend(res.data.total_price);
      });
  }

  // 전일 매출액
  function getYesterdayPrice() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/yesterday_price", bodyQuery)
      .then(function (res) {
        set_yesterdayPrice(res.data.total_price);
      });
  }

  // 전일 입금액
  function getYesterdayIncome() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/yesterdayIncome", bodyQuery)
      .then(function (res) {
        set_yesterdayIncome(res.data.total_price);
      });
  }

  // 전일 차감액
  function getYesterdaySpend() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/yesterdaySpend", bodyQuery)
      .then(function (res) {
        set_yesterdaySpend(res.data.total_price);
      });
  }

  // 전주 매출액
  function getWeekPrice() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/week_price", bodyQuery)
      .then(function (res) {
        set_weekPrice(res.data.total_price);
      });
  }

  // 전주 입금액
  function getWeekIncome() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/weekIncome", bodyQuery)
      .then(function (res) {
        set_weekIncome(res.data.total_price);
      });
  }

  // 전주 차감액
  function getWeekSpend() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/weekSpend", bodyQuery)
      .then(function (res) {
        set_weekSpend(res.data.total_price);
      });
  }

  // 전월 매출액
  function getMonthPrice() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/month_price", bodyQuery)
      .then(function (res) {
        set_monthPrice(res.data.total_price);
      });
  }

  // 전월 입금액
  function getMonthIncome() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/monthIncome", bodyQuery)
      .then(function (res) {
        set_monthIncome(res.data.total_price);
      });
  }

  // 전월 차감액
  function getMonthSpend() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/monthSpend", bodyQuery)
      .then(function (res) {
        set_monthSpend(res.data.total_price);
      });
  }

  // 결제완료건수
  function get_pay_cnt7() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/pay_cnt7", bodyQuery)
      .then(function (res) {
        let tmp_cnt = res.data.tcnt;
        if (tmp_cnt == null) {
          tmp_cnt = 0;
        }
        set_pay_cnt7(tmp_cnt);
      });
  }

  // 결제금액
  function get_pay_hap7() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/pay_hap7", bodyQuery)
      .then(function (res) {
        let tmp_tprice = res.data.tprice;
        if (tmp_tprice == null) {
          tmp_tprice = 0;
        }
        set_pay_hap7(tmp_tprice);
      });
  }

  // 정산내역 - 금액
  function get_pay_end_hap() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/pay_end_hap", bodyQuery)
      .then(function (res) {
        let tmp_tprice = res.data.tprice;
        if (tmp_tprice == null) {
          tmp_tprice = 0;
        }

        set_pay_end_hap(tmp_tprice);
      });
  }

  // 지사수
  function getMbranchCnt() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/admin_cnt", bodyQuery)
      .then(function (res) {
        set_mBranchCnt(res.data.tcnt);
      });
  }

  // 총판수
  function getDistributorCnt() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/distributor_cnt", bodyQuery)
      .then(function (res) {
        set_distributor(res.data.tcnt);
      });
  }

  // 대리점 수
  function getAgencyCnt() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/agency_cnt", bodyQuery)
      .then(function (res) {
        set_agency(res.data.tcnt);
      });
  }

  // 가맹점 수
  function getStoreCnt() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/store_cnt", bodyQuery)
      .then(function (res) {
        set_storet(res.data.tcnt);
      });
  }

  // PG 수수료
  function getFee() {
    let bodyQuery = {};
    axios
      .post("/api/terminal/management/getFee", bodyQuery)
      .then(function (res) {
        let tmp_pg_fee = res.data.pg_fee;
        let tmp_St_fee = res.data.St_fee;
        let tmp_Br_fee = res.data.Br_fee;
        let tmp_Di_fee = res.data.Di_fee;
        let tmp_Ag_fee = res.data.Ag_fee;

        set_pg_fee(tmp_pg_fee == null ? 0 : tmp_pg_fee);
        set_st_fee(tmp_St_fee == null ? 0 : tmp_St_fee);
        set_br_fee(tmp_Br_fee == null ? 0 : tmp_Br_fee);
        set_di_fee(tmp_Di_fee == null ? 0 : tmp_Di_fee);
        set_ag_fee(tmp_Ag_fee == null ? 0 : tmp_Ag_fee);
      });
  }

  useEffect(() => {
    getTodayPrice();
    getTodayIncome();
    getTodaySpend();
    getYesterdayPrice();
    getYesterdayIncome();
    getYesterdaySpend();
    getWeekPrice();
    getWeekIncome();
    getWeekSpend();
    getMonthPrice();
    getMonthIncome();
    getMonthSpend();
    get_pay_cnt7();
    get_pay_hap7();
    get_pay_end_hap();
    getMbranchCnt();
    getDistributorCnt();
    getAgencyCnt();
    getStoreCnt();
    getFee();
  }, []);

  return (
    <>
      <div id="wrap">
        <Header />

        <div id="container" className="main">
          {/* payment_info_sec */}
          <div className="payment_info_sec main_cont">
            <div className="w_80">
              <ul className="payment_info_row">
                <li>
                  <strong className="label">
                    금일 매출액 <span>※현재기준</span>
                  </strong>
                  <p className="all_pay">
                    <b>
                      {("" + todayPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + todayIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + todaySpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <strong className="label">전 일 매출액</strong>
                  <p className="all_pay">
                    <b>
                      {("" + yesterdayPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + yesterdayIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + yesterdaySpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <strong className="label">전 주 매출액</strong>
                  <p className="all_pay">
                    <b>
                      {("" + weekPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + weekIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + weekSpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <strong className="label">전 월 매출액</strong>
                  <p className="all_pay">
                    <b>
                      {("" + monthPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                  <div className="pay_detail">
                    <dl>
                      <dt>입금액</dt>
                      <dd>
                        {("" + monthIncome)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                    <dl>
                      <dt>차감액</dt>
                      <dd>
                        {("" + monthSpend)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w_20">
              <div className="color_cont  top">
                <div className="left">
                  <p>결제완료건수</p>
                  <span>최근 1주일 이내</span>
                </div>
                <div className="right">
                  <b>
                    {("" + pay_cnt7)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </b>
                  건
                </div>
              </div>
              <div className="color_cont  bot">
                <div className="left">
                  <p>결제금액</p>
                  <span>최근 1주일 이내</span>
                </div>
                <div className="right">
                  <b>
                    {("" + pay_hap7)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </b>
                  원
                </div>
              </div>
            </div>
          </div>
          {/*  // payment_info_sec // */}

          {/* calculate_sec */}
          <div className="calculate_sec main_cont">
            <div className="w_80">
              <div className="management_info">
                <strong className="ico_ttl">
                  <i></i>업체관리({mBranchCnt + distributor + agency + store})
                </strong>
                <ul className="management_list">
                  <li>
                    <Link to="/SiteAdmin/_management/list" className="link_go">
                      지사수
                      <span>{mBranchCnt}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/SiteAdmin/_management/DistributorList"
                      className="link_go"
                    >
                      총판 수<span>{distributor}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/SiteAdmin/_management/AgencyList"
                      className="link_go"
                    >
                      대리점 수<span>{agency}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/SiteAdmin/_management/StoreTerminalList"
                      className="link_go"
                    >
                      가맹점 수<span>{store}</span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="graph_cont">
                {/*
                            <canvas id="graph_item" style={{width:'100%',background:'blue', height: '412px' }}></canvas>
                            */}

                <div>
                  {/* <div id="chart" ref={chartRef} /> */}
                  <DashboardChart
                    ref={chartRef}
                    apiEndpoint="/api/terminal/management/chart_month_price"
                    chartCaption="터미널정산"
                    oneYearAgoFormatted={oneYearAgoFormatted}
                    nowFormatted={nowFormatted}
                  />
                </div>
              </div>
            </div>
            <div className="w_20">
              <div className="cal_item">
                <div className="top">
                  <p className="sub_ttl">
                    정산내역 <span>최근 1주일 이내</span>
                  </p>
                  <p className="all_pay">
                    <b>
                      {("" + pay_end_hap)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                    원
                  </p>
                </div>
                <ul className="bot_list">
                  <li className="cont_01">
                    <p>
                      <i></i>PG 수수료
                    </p>
                    <em>
                      {("" + pg_fee)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </em>
                  </li>
                  <li className="cont_02">
                    <p>
                      <i></i>가맹점
                    </p>
                    <em>
                      {("" + st_fee)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </em>
                  </li>
                  <li className="cont_03">
                    <p>
                      <i></i>지사
                    </p>
                    <em>
                      {("" + br_fee)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </em>
                  </li>
                  <li className="cont_04">
                    <p>
                      <i></i>총판
                    </p>
                    <em>
                      {("" + di_fee)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </em>
                  </li>
                  <li className="cont_05">
                    <p>
                      <i></i>대리점
                    </p>
                    <em>
                      {("" + ag_fee)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </em>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* // calculate_sec //  */}

          {/* list_cont_sec */}
          <div className="bod_list_cont main_cont">
            <ul className="bod_list_wrap">
              <li>
                <div className="ttl">
                  <strong>공지사항</strong>
                  <Link to="/SiteAdmin/_manage/NoticeList">
                    더보기 <i>&#xE001;</i>
                  </Link>
                </div>
                <ul className="bod_list">
                  {noticeContent.map((v, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/SiteAdmin/_manage/noticeView?idx=${v.idx}`}>
                          <span className="sb_ttl">{v.subject}</span>
                          <em className="date">{v.regdate.substr(0, 10)}</em>
                        </Link>
                      </li>
                    );
                  })}
                  {noticeContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                </ul>
              </li>
              <li>
                <div className="ttl">
                  <strong>자주하는 질문</strong>
                  <Link to="/SiteAdmin/_manage/FaqList">
                    더보기 <i>&#xE001;</i>
                  </Link>
                </div>
                <ul className="bod_list">
                  {faqContent.map((v, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/SiteAdmin/_manage/FaqView?idx=${v.idx}`}>
                          <span className="sb_ttl">{v.subject}</span>
                          <em className="date">{v.regdate.substr(0, 10)}</em>
                        </Link>
                      </li>
                    );
                  })}
                  {faqContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                </ul>
              </li>
              <li>
                <div className="ttl">
                  <strong>1대1 문의</strong>
                  <Link to="/SiteAdmin/_manage/QnaList">
                    더보기 <i>&#xE001;</i>
                  </Link>
                </div>
                <ul className="bod_list">
                  {qnaContent.map((v, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/SiteAdmin/_manage/QnaView?idx=${v.idx}`}>
                          <span className="sb_ttl">{v.subject}</span>
                          <em className="date">{v.regdate.substr(0, 10)}</em>
                        </Link>
                      </li>
                    );
                  })}
                  {qnaContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                </ul>
              </li>
            </ul>
          </div>
          {/*  // list_cont_sec // */}

          {/* <FooterInfo/> */}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default SiteAdminMainTerminal;
