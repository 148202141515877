import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import jquery from 'jquery';
import $ from 'jquery';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun} from "../../../include/ConfigSet";


function SiteAdminPayTransactionHistoryCancelPart() {
    

    const navigate = useNavigate();
    const location = useLocation();

    const qs = queryString.parse(window.location.search);
    const idx = qs.idx ? qs.idx : '';

    
    if(idx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }
 

    const [created_datetime, set_created_datetime] = useState("");
    const [pg_group, set_pg_group] = useState("");
    const [St_branch_name, set_St_branch_name] = useState("");
    const [cardno, set_cardno] = useState("");
    const [authno, set_authno] = useState("");
    const [amount, set_amount] = useState("");
    const [quota, set_quota] = useState("");
    const [cpid, set_cpid] = useState("");
    const [status, set_status] = useState("");
    const [daoutrx, set_daoutrx] = useState("");
    const [calculate_date_re, set_calculate_date_re] = useState("");
    const [orderno, set_orderno] = useState("");
    /*
    const [cancel_amount, set_cancel_amount] = useState("");

    const on_cancel_amount_handler = (event) => {
        set_cancel_amount(event.currentTarget.value.replace(/[^0-9]/g, ''))
    }
    */


    $(document).ready(function(){
        $(".cancelBtn").click(function(){
            if (window.confirm("정말 취소하시겠습니까?")) {
                fn_cancel( $("#cancel_amount").val() );
            }
        });
    });
     
    function cancel_out(status){
        if(status === "E"){
            return (
                <>
                    <button type="button" className=" btn_st_01 blue cancelBtn"  >부분취소 실행</button>
                </>
            )
        }

    }


    function fn_cancel(cancel_money){
        let body = {
            idx: idx,
            trxid: daoutrx,
            cancel_amount: cancel_money

        }

        axios.post('/payment/payjoa_part_cancel.php', body,{ withCredentials: true }).then(function(res2){
            
            if(res2.data.RESULTCODE === "0000"){
                alert("취소되었습니다.");
                navigate('/SiteAdmin/_pay/transactionHistoryList');
            }else{
                if(res2.data.RESULTCODE !== null){
                    
                    alert("취소 실패! 사유 : " + res2.data.ERRORMESSAGE + "\n코드 : " + res2.data.RESULTCODE);
                }else{
                    
                    alert("취소에 실패했습니다.");
                    
                }
            }
        
        })

   

    }

    useEffect(() => {


        let bodyPage = {
            idx: idx
        }
        console.log("bodyPage : " + bodyPage);

        axios.post('/api/management/pay/transaction_history_view', bodyPage).then(function(res){
            console.log(res.data.map);
            res.data.map((v, index) => {
                set_created_datetime(v.created_datetime);
                set_pg_group(v.pg_group);
                set_St_branch_name(v.St_branch_name);
                set_cardno(v.cardno);
                set_authno(v.authno);
                set_amount(v.amount);
                set_quota(v.quota);
                set_cpid(v.cpid);
                set_status(v.status);
                set_daoutrx(v.daoutrx);
                set_calculate_date_re(v.calculate_date_re);
                set_orderno(v.orderno);

            })
            
        }) 

        return () => {
          
        };
    }, []);
  


    
    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="management_list">  
                
                    <h3 className="page_ttl">결제내역</h3>
                    <div className="form_item">
                        

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="150px" />
                                    <col width="*" />
                                    <col width="150px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>거래일자</th>
                                        <td>{created_datetime}</td>
                                        <th>PG</th>
                                        <td>{PgName(pg_group)}</td>
                                    </tr>
                                    <tr>
                                        <th>구분</th>
                                        <td>{GetPayGubun(status)}</td>
                                        <th>결제구분</th>
                                        <td>신용</td>
                                    </tr>
                                    <tr>
                                        <th>가맹점</th>
                                        <td>{St_branch_name}</td>
                                        <th>터미널코드</th>
                                        <td>{cpid}</td>
                                    </tr>
                                    <tr>
                                        <th>카드번호</th>
                                        <td>{cardno.substr(0, cardno.length-7) + "****" + cardno.substr(cardno.length-3, 3)}</td>
                                        <th>승인번호</th>
                                        <td>{authno}</td>
                                    </tr>

                                    <tr>
                                        <th>금액</th>
                                        <td>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            
                                            
                                        </td>
                                        <th>할부</th>
                                        <td>{(quota=="00"?'일시불':quota)}</td>
                                    </tr>

                                    <tr>
                                        <th>정산상태</th>
                                        <td>{GetPayStatus(status)}</td>
                                        <th>주문번호 / TID</th>
                                        <td>{orderno} / {daoutrx}</td>
                                    </tr>

                                    <tr>
                                        <th>PG정산예정</th>
                                        <td>{calculate_date_re}</td>
                                        <th>가맹점정산예정</th>
                                        <td>{calculate_date_re}</td>
                                    </tr>

                                    <tr>
                                        <th>취소할 금액</th>
                                        <td colSpan="3">
                                            <div className='flex'>
                                                <div className="in_box" style={{'width':'200px','marginRight':'10px'}}>
                                                    <input type="text" name="cancel_amount" id="cancel_amount"  required />
                                                </div>
                                                <span>원</span>
                                            </div>
                                        </td>
                                    </tr>
                                    

                                </tbody>
                            </table>

                        </div>

                    </div>


                    

                    <div className="top_table_btn">
                        {/*<button type="submit" className="black_btn btn_st_01">등록하기</button> */}
                        <button type="button" className="line_btn btn_st_01" onClick={  () => navigate(-1) }>이전으로</button>
                        
                        
                        {
                            cancel_out(status)
                        }                        
                    </div>
                
            </div>
            
        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminPayTransactionHistoryCancelPart