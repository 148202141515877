import * as axios from 'axios';
import React from 'react'
import '../../../../../assest/css/adm_login.css';
import '../../../../../assest/css/reset.css';
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import StoreForm from "../../../../page/store/Form";



function SiteAdminManagementStoreTerminalForm() {


    return (
        <>
        <div id="wrap">
            <Header/>

            <StoreForm pay_type={"Terminal"}/>
                        
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManagementStoreTerminalForm