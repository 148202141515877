import { useState, useEffect } from "react";
import { axiosPostWithCallback } from "../util/functionHelper";

export const useFinancialData = (loginId, suffix) => {
  // 금일 매출액
  const [todayPrice, set_todayPrice] = useState(0);
  // 금일 입금액
  const [todayIncome, set_todayIncome] = useState(0);
  // 금일 차감액
  const [todaySpend, set_todaySpend] = useState(0);
  // 전일 매출액
  const [yesterdayPrice, set_yesterdayPrice] = useState(0);
  // 전일 입금액
  const [yesterdayIncome, set_yesterdayIncome] = useState(0);
  // 전일 차감액
  const [yesterdaySpend, set_yesterdaySpend] = useState(0);
  // 전주 매출액
  const [weekPrice, set_weekPrice] = useState(0);
  // 전주 입금액
  const [weekIncome, set_weekIncome] = useState(0);
  // 전주 차감액
  const [weekSpend, set_weekSpend] = useState(0);
  // 전월 매출액
  const [monthPrice, set_monthPrice] = useState(0);
  // 전월 입금액
  const [monthIncome, set_monthIncome] = useState(0);
  // 전월 차감액
  const [monthSpend, set_monthSpend] = useState(0);
  // 결제완료건수
  const [pay_cnt7, set_pay_cnt7] = useState(0);
  // 결제금액
  const [pay_hap7, set_pay_hap7] = useState(0);

  const fetchFinancialData = () => {
    const bodyQuery = { user_id: loginId };

    axiosPostWithCallback(`/api/${suffix}/today_price`, bodyQuery, (data) =>
      set_todayPrice(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/todayIncome`, bodyQuery, (data) =>
      set_todayIncome(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/todaySpend`, bodyQuery, (data) =>
      set_todaySpend(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/yesterday_price`, bodyQuery, (data) =>
      set_yesterdayPrice(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/yesterdayIncome`, bodyQuery, (data) =>
      set_yesterdayIncome(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/yesterdaySpend`, bodyQuery, (data) =>
      set_yesterdaySpend(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/week_price`, bodyQuery, (data) =>
      set_weekPrice(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/weekIncome`, bodyQuery, (data) =>
      set_weekIncome(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/weekSpend`, bodyQuery, (data) =>
      set_weekSpend(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/month_price`, bodyQuery, (data) =>
      set_monthPrice(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/monthIncome`, bodyQuery, (data) =>
      set_monthIncome(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/monthSpend`, bodyQuery, (data) =>
      set_monthSpend(data.total_price)
    );
    axiosPostWithCallback(`/api/${suffix}/pay_cnt7`, bodyQuery, (data) =>
      set_pay_cnt7(data.tcnt || 0)
    );
    axiosPostWithCallback(`/api/${suffix}/pay_hap7`, bodyQuery, (data) => {
      let tmp_tprice = data.tprice || 0;
      set_pay_hap7(tmp_tprice);
    });
  };

  useEffect(() => {
    fetchFinancialData();
  }, []);

  return {
    todayPrice,
    todayIncome,
    todaySpend,
    yesterdayPrice,
    yesterdayIncome,
    yesterdaySpend,
    weekPrice,
    weekIncome,
    weekSpend,
    monthPrice,
    monthIncome,
    monthSpend,
    pay_cnt7,
    pay_hap7,
  };
};
