import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import '../../../../../assest/css/reset.css';
import '../../../../../assest/css/layout.css';

import Header from "../../../include/StoreHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';

import NoticeList from "../../../../page/notice/List";

function StoreManagementNoticeList() {

   
/*    
    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('subject');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    // 상위 지사리스트
    const [managerList, setManagerList] = useState([]);

    // 지사 idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    const on_set_parent_idx_handler = (event) => {
        set_parent_idx(event.currentTarget.value)
    }
    


    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;



    const Board = ({
        idx,
        nums,
        subject,
        regdate
    }) => {
        
        
        regdate = regdate.substr(0,10) +" " + regdate.substr(11,8);

        return (
            <tr>
                <td>{nums}</td>
                <td>
                    <Link to={`/Store/_management/noticeView?idx=`+idx}>{subject}</Link>
                </td>
                <td>{regdate}</td>
            </tr>
        );
    };



    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        navigate('/Store/_management/noticeList?searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        
    }

    // 전체 수량 구하는 
    function getTotalCnt(){
        //console.log("getTotalCnt Start");
        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates,
            code: 'notice'
        }
        //console.log("bodyQuery : " + bodyQuery);

        axios.post('/api/manage/NoticeTotalList', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }



    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

        if(sParent_idx) {
            set_parent_idx(sParent_idx);
        }

        getTotalCnt();
        

        

    },[sWord, sCate, sPg, sParent_idx, page]);

  
    useEffect(()=>{
      
        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            code: 'notice'
        }
        //console.log("bodyPage : " + bodyPage);
        
        axios.post('/api/manage/NoticeList', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)
        }) 
        

        
    },[sWord, sCate, sParent_idx, sPg]);





    return(
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="notice">
                <h3 className="page_ttl">공지사항</h3>

                <form onSubmit={onSubmitSearch} >
                <div className="top_filter">

                    <div className="sel_box">
                        <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                            <option value="subject">제목</option>      
                        </select>
                    </div>


                    <div className="in_box search_box">
                        <input type="text" name="searchWord" id="searchWord" value={searchWord} onChange={onSearchWord} placeholder="검색해주세요"/>
                        <button type="submit" className="btn_st_02 search">검색</button>
                    </div>


                </div>
                </form>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>
                    </div>
                    <div className="table_box">
                        <table>
                            <colgroup>
                                <col width="8%" />
                                <col width="*" />
                                <col width="15%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>제목</th>
                                    <th>등록일자</th>
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                subject={v.subject}
                                                regdate={v.regdate}
                                            />
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    {
                        viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }

                </div>

            </div>
        </div>

        <Footer/>
        </>

    )
*/


    return(
        <>
        
        <div id="wrap">
            <Header/>            
                <NoticeList user_level={'5'} user_site={'Store'} />
        </div>
        <Footer/>         
        </>
    )
        
}
export default StoreManagementNoticeList