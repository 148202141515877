import * as axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assest/css/adm_login.css";
import "../../../assest/css/reset.css";
import img_logo from "../../../assest/img/intro/logo.png";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../_action/user_action";
import { useNavigate } from "react-router-dom";
import AuthPopup from "../../page/authPopup/AuthPopup";
import { useCookies } from "react-cookie";
import moment from "moment";
import { fetchWithCallback } from "../../../util/functionHelper";

function SiteAdmin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [CookieId, setCookieId, removeCookieId] = useCookies([
    "save_id_agency",
  ]);
  const [saveid_chk, setSaveid_chk] = useState(
    CookieId.save_id_agency === undefined ? false : true
  );

  const [LoginId, setLoginId] = useState(
    CookieId.save_id_agency === undefined ? "" : CookieId.save_id_agency
  );
  const [Password, setPassword] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [loginPopup, setLoginPopup] = useState(false);
  const [userIp, setUserIp] = useState("");

  const onIdHandler = (event) => {
    setLoginId(event.currentTarget.value);
  };

  const onPasswordHandler = (event) => {
    setPassword(event.currentTarget.value);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let body = {
      loginid: LoginId,
      password: Password,
    };
    axios.post("/api/agency/loginCheck", body).then(function (res) {
      if (typeof res.data === "string") {
        alert(res.data);
        return;
      } else {
        dispatch(loginUser(body)).then((response) => {
          setUserInfo(res.data);
          setLoginPopup(true);
        });
      }
    }, []);
  };

  //최종 로그인 처리 프로세스 api
  const onLoginAuth = () => {
    let body = {
      loginid: LoginId,
      password: Password,
      userIp: userIp,
    };

    axios.post("/api/agency/loginAuth", body).then(function (res) {
      if (typeof res.data !== "string") {
        if (saveid_chk === true) {
          const pExpires = moment().add(7, "d").toDate(); //7일후
          setCookieId("save_id_agency", res.data.user_id, {
            expires: pExpires,
          });
        } else {
          removeCookieId(["save_id_agency"]);
        }

        sessionStorage.setItem("loginId", res.data.user_id);
        sessionStorage.setItem("loginIdx", res.data.idx);
        navigate("/Agency/_main/main");
      } else {
        alert(res.data);
      }
    }, []);
  };

  const Fn_Pass_Login = () => {
    onLoginAuth();
  };

  const Fn_PopupClose = () => {
    setLoginPopup(false);
  };

  const Fn_Save_Id = () => {
    if (saveid_chk === false) {
      setSaveid_chk(true);
    } else {
      setSaveid_chk(false);
    }
  };

  //아이피 불러오기
  const Fn_IP = async () => {
    fetchWithCallback("https://geolocation-db.com/json/", (locationIp) => {
      setUserIp(locationIp.IPv4);
    });
  };

  // const Fn_IP = async()=>{
  //     const ipData = await fetch('https://geolocation-db.com/json/');
  //     const locationIp = await ipData.json();
  //     setUserIp(locationIp.IPv4);
  // }

  useEffect(() => {
    Fn_IP();
  }, []);

  return (
    <>
      <div className="intro bg01">
        <div className="login_box">
          <form onSubmit={onSubmitHandler}>
            <div className="login_ttl">
              <h1 className="logo">
                <img src={img_logo} alt="" />
              </h1>
              <em>[대리점]유월페이에 오신 것을 환영합니다.</em>
            </div>
            <div className="input_box">
              <div className="input_wrap">
                <input
                  type="text"
                  id="loginId"
                  name="loginId"
                  required
                  value={LoginId}
                  placeholder="아이디를 입력해주세요."
                  onChange={onIdHandler}
                />
              </div>
              <div className="input_wrap">
                <input
                  type="password"
                  id="passwd"
                  name="passwd"
                  required
                  value={Password}
                  placeholder="비밀번호를 입력해주세요."
                  onChange={onPasswordHandler}
                />
              </div>
            </div>
            <div className="option_box">
              <div className="check_box">
                <input
                  type="checkbox"
                  id="save_id"
                  name="save_id"
                  checked={saveid_chk}
                  value="Y"
                  onClick={Fn_Save_Id}
                />
                <label htmlFor="save_id">ID저장</label>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="submit" className="login_btn">
                로그인
              </button>

              <Link
                to="#"
                style={{ paddingTop: "20px" }}
                onClick={Fn_Pass_Login}
              >
                인증없이 로그인
              </Link>
            </div>

            <div className="add_info">
              <span>FAX : 02.3439.0773 / Email : uwal@uwal.co.kr</span>
              <span>고객센터 : 02.3667.0774</span>
            </div>
            <p className="copyright">designed by uwal communication</p>
          </form>
        </div>
      </div>

      {loginPopup}
      {loginPopup === true && (
        <AuthPopup
          pLoginId={LoginId}
          pPassword={Password}
          pLoginPopup={loginPopup}
          pUserInfo={userInfo}
          Props_FnPopupClose={Fn_PopupClose}
          Props_FnLoginAuth={onLoginAuth}
        />
      )}
    </>
  );
}

export default SiteAdmin;
