import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../../include/Paging";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";


function SiteAdminPGForm() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';
        
    const [page_state, set_page_state] = useState(true);    
    const [idx, set_idx] = useState(sIdx);

    const [sname, set_sname] = useState("");
    const [pg, set_pg] = useState("");
    const [cerify, set_cerify] = useState("");
    const [store, set_store] = useState("");
    const [income, set_income] = useState("");
    const [fee, set_fee] = useState("");
    const [mode, set_mode] = useState("Y");
    

    const on_sname_handler = (event) => {
        set_sname(event.currentTarget.value)
    }
    const on_pg_handler = (event) => {
        set_pg(event.currentTarget.value)
    }
    const on_cerify_handler = (event) => {
        set_cerify(event.currentTarget.value)
    }    
    const on_store_handler = (event) => {
        set_store(event.currentTarget.value)
    }  
    const on_income_handler = (event) => {
        set_income(event.currentTarget.value)
    }  
    const on_fee_handler = (event) => {       
        set_fee(event.currentTarget.value)
    }            
    const on_mode_handler = (event) => {       
        set_mode(event.currentTarget.value)
    }       


    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: idx,
            sname: sname,
            pg: pg,
            cerify: cerify,           
            store: store,
            income: income,
            fee: fee,
            mode: (mode === '' ? 'Y' : mode)
        }

      
        axios.post('/api/manage/pg/pg_pay_add', body)
        .then(function(response){

            if(response.data === "AddOk"){
                if(idx !==''){
                    alert('정상적으로 수정되었습니다.')
                }
                navigate('/SiteAdmin/_manage/_pg/list');

            }else{
                alert("시스템 에러");
                
            }

        },[])
    }

    //상세정보
    const Fn_View=(sIdx)=>{

        let bodyPage = {
            idx: sIdx
        }
        
        axios.post('/api/manage/pg/pg_pay_view', bodyPage).then(function(res){                        
            res.data.map((v, index) => {
                set_sname(v.sname);
                set_pg(v.pg);
                set_store(v.store);
                set_income(v.income);
                set_fee(v.fee);
                set_mode(v.mode);              
                return ''
            }); 
            
            if(res.data.length === 0){                
                set_page_state(false)                
            }
        }) 


        
    }
    
    React.useEffect(() => {      
        if(sIdx !== ''){
            Fn_View(sIdx);
        }
    }, [sIdx])


    //페이지 정상 접근인지 체크
    React.useEffect(() => {
        if(page_state === false){
            alert("잘못된 접근입니다.");
            navigate(-1);
        }
    }, [page_state])

    

    return (
        <>
        <div id="wrap">
            <Header/>        
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                 
                    <input type="hidden" name="idx" id="idx" value={idx} />
                    <h3 className="page_ttl">수기 PG 관리 설정</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>

                                    <tr>
                                        <th>관리명칭 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" id="sname" name="sname" value={sname} onChange={on_sname_handler} required style={{width: '250px', marginRight:'5px'}}  />
                                            </div>
                                        </td>   
                                        <th>pg사  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                            <input type="text" id="pg" name="pg" value={pg} onChange={on_pg_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>                                        
                                    </tr>
                                   
                                    <tr>
                                        <th>pg상점 코드  <span className="red">＊</span></th>
                                        <td >
                                            <div className="in_box">
                                            <input type="text" id="store" name="store" value={store} onChange={on_store_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>
                                        <th>입금주기  <span className="red">＊</span></th>
                                        <td >
                                            <div className="in_box">
                                            <input type='number' id="income" name="income" value={income} onChange={on_income_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>                                        
                                    </tr>
                                 
                                    <tr>
                                        <th>수수료율  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                            <input type='number' id="fee" name="fee" value={fee} onChange={on_fee_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>
                                        <th>사용여부  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">                                           
                                            <select name='mode' defaultValue={mode} onChange={on_mode_handler} value={mode} style={{width: '150px', marginRight:'5px'}} >
                                                <option value={'Y'} >사용</option>
                                                <option value={'N'}>미사용</option>
                                            </select>
                                            </div>
                                        </td>                                        
                                    </tr>
                                  
                                
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="top_table_btn">
               
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button className=" blue btn_st_01">등록</button>
                    </div>

                </form>

            </div>
            
        </div>

        <Footer/>
        </>
    )
}
export default SiteAdminPGForm